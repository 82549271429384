import { DateTimeFormatter, LocalDateTime, ZonedDateTime } from "@js-joda/core";
import { LengthUnits, WeightUnits } from "../models/CargoDetails";
import { Flight, Item } from "../models/Booking";
import { AvStackFlightSchedule } from "../services/AvStackDataProvider";

export const common = {
  yesOrNo: (isTrue: boolean) => {
    return isTrue ? "Yes" : "No";
  },
};

export const getRoleNameFromRole = (role: string) => {
  return role === "apiAdmin"
    ? "Admin"
    : role === "apiUser"
      ? "User"
      : role === "demoAPIUser"
        ? "Demo User"
        : role === "delhiveryApiUser"
          ? "Delhivery API User"
          : role === "delhiveryAccountsUser"
            ? "Delhivery Accounts User"
            : role === "frontendUser"
              ? "Frontend User"
              : "Unknown";
};

export const rupeeSymbol = "&#8377;";

export const weightConversion = (
  selectedUnit: string,
  value: number
): WeightUnits => {
  if (selectedUnit === "KG_CM") {
    return {
      kg: value,
      lb: parseFloat((value * 2.20462).toFixed(2)),
    };
  } else {
    return {
      kg: parseFloat((value * 0.45359).toFixed(2)),
      lb: value,
    };
  }
};
export const lengthConversion = (
  selectedUnit: string,
  value: number
): LengthUnits => {
  if (selectedUnit === "KG_CM") {
    return {
      cm: value,
      inch: parseFloat((value * 0.393701).toFixed(2)),
    };
  } else {
    return {
      cm: parseFloat((value * 2.54).toFixed(2)),
      inch: value,
    };
  }
};

export const formatDate = (date: LocalDateTime) => {
  const formatter = DateTimeFormatter.ofPattern("dd-MM-yyyy");
  const time = LocalDateTime.parse(date + "");
  return time.format(formatter);
};

export const formatTime = (date: LocalDateTime) => {
  const formatter = DateTimeFormatter.ofPattern("HH:MM");
  const time = LocalDateTime.parse(date + "");
  return time.format(formatter);
};

export const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes - hours * 60;

  return hours > 0 ? hours + " hour " + mins + " min" : minutes + " min";
};

export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const convertItemsToUpload = (items: any[]): Item[] => {
  return items.map((item) => {
    return {
      length: item.length.cm,
      width: item.width.cm,
      height: item.height.cm,
      pieces: item.itemCount,
    };
  });
};

export const convertFlightsToUploadFormat = (
  flight: AvStackFlightSchedule
): Flight[] => {
  return [
    {
      flightNumber: flight.flightNum,
      airlineCode: flight.airlineIata,
      airlineName: flight.airlineName,
      ArrivalTime: new Date(
        ZonedDateTime.parse(flight.arrDate.toString()).toString()
      ),
      DepartureTime: new Date(
        ZonedDateTime.parse(flight.depDate.toString()).toString()
      ),
      originIata: flight.depIata,
      destinationIata: flight.arrIata,
    },
  ];
};

export const formatDateAviationStack = (date: string) => {
  return date.split("T")[0].split("-").reverse().join("-");
};

export const formatTimeAviationStack = (time: string) => {
  return time.split("T")[1].slice(0, 5);
};

export const getRouteFromRole = (role: string[]) => {
  return role.includes("apiAdmin")
    ? "/admin"
    : role.includes("apiUser")
      ? "/user"
      : role.includes("demoAPIUser")
        ? "/demo"
        : "/login";
};

export const ROLES = {
  APIUSER: "apiUser",
  ADMIN: "apiAdmin",
  DEMO: "demoAPIUser",
  DELHIVERYAPIUSER: "delhiveryApiUser",
  DELHIVERYACCOUNTSUSER: "delhiveryAccountsUser",
  FRONTENDUSER: "frontendUser",
};

export const theme = {
  primary: "#1a73e8",
  secondary: "#fbbc05",
  success: "#34a853",
  danger: "#ea4335",
  warning: "#fbbc05",
  info: "#4285f4",
  light: "#f8f9fa",
  dark: "#343a40",
  white: "#ffffff",
  black: "#000000",
  gray: "#6c757d",
  indigo: "#6610f2",
  purple: "#6f42c1",
  pink: "#e83e8c",
  red: "#dc3545",
  orange: "#fd7e14",
};
