import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import {
  ScrapingETDETADetail,
  ScrapingEventHistory,
  ScrapingFlightDetail,
  ScrapingTable,
  ScrapingTracking,
} from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal, Table } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import AWBUsage from "./AWBUsage";
import { NavLink } from "react-router-dom";

const ScrapingTrackings = () => {
  const {
    data: scrapingTracking,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("scraping", WebhookUiService.getScrapingTracking, {
    refetchOnWindowFocus: false,
  });
  //   console.log(scraping);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedFlightDetails, setSelectedFlightDetails] = useState<
    ScrapingFlightDetail[]
  >([]);
  const [selectedEtdEtaDetails, setSelectedEtdEtaDetails] = useState<
    ScrapingETDETADetail[]
  >([]);
  const [selectedEventHistories, setSelectedEventHistories] = useState<
    ScrapingEventHistory[]
  >([]);

  const handleFlightClick = (flightDetails: ScrapingFlightDetail[]) => {
    setSelectedFlightDetails(flightDetails);
  };
  const handleEtdEtaClick = (Etdeta: ScrapingETDETADetail[]) => {
    console.log("button clicked");
    console.log(Etdeta);
    setSelectedEtdEtaDetails(Etdeta);
  };

  const handleEventClick = (eventHistories: ScrapingEventHistory[]) => {
    setSelectedEventHistories(eventHistories);
  };

  const FlightDetailsModal = ({
    flightDetails,
    onClose,
  }: {
    flightDetails: ScrapingFlightDetail[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Flight Number</th>
                <th>Flight Date</th>
                <th>Origin</th>
                <th>Destination</th>
              </tr>
            </thead>
            <tbody>
              {flightDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.flightNumber}</td>
                  <td>{detail.flightDate}</td>
                  <td>{detail.origin}</td>
                  <td>{detail.destination}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const FlightEtdetaModal = ({
    Etdeta,
    onClose,
  }: {
    Etdeta: ScrapingETDETADetail[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Flight Number</th>
                <th>Station</th>
                <th>Destination</th>
                <th>ETD </th>
                <th>ETA</th>
              </tr>
            </thead>
            <tbody>
              {Etdeta.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.flightNumber}</td>
                  <td>{detail.station}</td>
                  <td>{detail.destination}</td>
                  <td>{detail.etd}</td>
                  <td>{detail.eta}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const EventHistoriesModal = ({
    eventHistories,
    onClose,
  }: {
    eventHistories: ScrapingEventHistory[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Histories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Milestone</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Station</th>
                <th>Pieces</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {eventHistories.map((history, index) => (
                <tr key={index}>
                  <td>{history.milestone}</td>
                  <td>{history.origin}</td>
                  <td>{history.destination}</td>
                  <td>{history.station}</td>
                  <td>{history.pieces}</td>
                  <td>{history.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const handleExportToExcel = (rows: MRT_Row<ScrapingTracking>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Scraping_Data");

    XLSX.writeFile(workbook, "Scraping_Data.xlsx");
  };

  const columns = useMemo<MRT_ColumnDef<ScrapingTracking>[]>(
    () => [
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",
      },

      {
        header: "Tracking Status",
        filterFn: "includesString",
        accessorFn: (row) => row?.trackingStatus || "",
      },
      {
        header: "Origin",
        filterFn: "includesString",
        accessorFn: (row) => row?.origin || "",
      },
      {
        header: "Destination",
        filterFn: "includesString",
        accessorFn: (row) => row?.destination || "",
      },
      {
        header: "Weight",
        filterFn: "includesString",
        accessorFn: (row) => row?.weight || "",
      },
      {
        header: "Pieces",
        filterFn: "includesString",
        accessorFn: (row) => row?.pieces || "",
      },
      {
        header: "Flight Details",
        accessorFn: (row) => {
          if (
            row.scrapingFlightDetail.length &&
            row.scrapingFlightDetail.length > 0
          ) {
            return (
              <Button
                onClick={() => handleFlightClick(row.scrapingFlightDetail)}
              >
                View
              </Button>
            );
          } else {
            return <Button disabled>View</Button>;
          }
        },
      },
      {
        header: "Flight ETD ETA",
        accessorFn: (row) => {
          if (row.scrapingETDETADetail && row.scrapingETDETADetail.length > 0) {
            return (
              <Button
                onClick={() => handleEtdEtaClick(row.scrapingETDETADetail)}
              >
                View
              </Button>
            );
          } else {
            return <Button disabled>View</Button>;
          }
        },
      },
      {
        header: "Events",
        accessorFn: (row) => {
          if (
            row.scrapingEventHistories &&
            row.scrapingEventHistories.length > 0
          ) {
            return (
              <Button
                onClick={() => handleEventClick(row.scrapingEventHistories)}
              >
                View
              </Button>
            );
          } else {
            return <Button disabled>View</Button>;
          }
        },
      },

      {
        header: "Entry Time",
        accessorKey: "createdAt",
        accessorFn: (row) =>
          row?.createdAt ? new Date(row.createdAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable<ScrapingTracking>({
    columns,
    data: scrapingTracking ?? [],
    enableRowNumbers: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
        <h6 className="mt-2">
          No of Entry: {table.getFilteredRowModel().rows.length}{" "}
        </h6>
      </Box>
    ),
  });

  return (
    <>
      {selectedFlightDetails?.length > 0 && (
        <FlightDetailsModal
          flightDetails={selectedFlightDetails}
          onClose={() => setSelectedFlightDetails([])}
        />
      )}

      {selectedEtdEtaDetails?.length > 0 && (
        <FlightEtdetaModal
          Etdeta={selectedEtdEtaDetails}
          onClose={() => setSelectedEtdEtaDetails([])}
        />
      )}

      {selectedEventHistories?.length > 0 && (
        <EventHistoriesModal
          eventHistories={selectedEventHistories}
          onClose={() => setSelectedEventHistories([])}
        />
      )}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </>
  );
};
export default ScrapingTrackings;
