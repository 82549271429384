import React from "react";
import { useAuthContext } from "../../../hooks/useAuth";
import ClientDashboard from "./Dashboard";
import ClientLogin from "./Login";
import { TrackingDashboard } from "./Demo";
import AdminDashboard from "./AdminDashboard";
import { useNavigate } from "react-router-dom";

function RouteSelector() {
  const { role } = useAuthContext();
  const navigate = useNavigate();

  if (!role) return <ClientLogin />;
  return role.includes("apiAdmin") ? (
    <AdminDashboard />
  ) : role.includes("apiUser") || role.includes("delhiveryAccountsUser") ? (
    <ClientDashboard />
  ) : role.includes("demoAPIUser") ? (
    <ClientDashboard />
  ) : (
    <ClientLogin />
  );
}

export default RouteSelector;
