import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import envConfig from "../../../utils/envConfig";
import { toast } from "react-toastify";

interface Setting {
  id: number;
  key: string;
  value: string;
  description: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

const API_BASE_URL = envConfig.url.API_URL;
const HARDCODED_EMAIL = "intemoff.test+admin@gmail.com";

const SettingsPage: React.FC = () => {
  const [settings, setSettings] = useState<Setting[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showNewField, setShowNewField] = useState<boolean>(false);
  const [newKey, setNewKey] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [passwordVisible, setPasswordVisible] = useState<{
    [key: number]: boolean;
  }>({});
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    axios
      .get<Setting[]>(`${API_BASE_URL}configure/all`)
      .then((response) => {
        setSettings(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
        setLoading(false);
      });
  }, []);

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newSettings = [...settings];

    if (name === "value") {
      newSettings[index] = {
        ...newSettings[index],
        value: value,
      };
    }

    setSettings(newSettings);
  };

  const handleNewKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewKey(e.target.value);
  };

  const handleNewValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleAddNewClick = () => {
    setShowNewField(true);
  };

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
  };

  const handleSaveClick = () => {
    setShowPasswordModal(true);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    axios
      .post(`${API_BASE_URL}auth/signin`, {
        email: HARDCODED_EMAIL,
        password,
      })
      .then((response) => {
        if (response.status === 201) {
          let updatedSettings = [...settings];
          if (newKey && newValue) {
            const newSetting = {
              id: Date.now(),
              key: newKey,
              value: newValue,
              description: "",
              isActive: true,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            };
            updatedSettings = [...updatedSettings, newSetting];
          }

          axios
            .put(`${API_BASE_URL}configure/update/all`, {
              entries: updatedSettings,
            })
            .then((response) => {
              toast.success("Settings updated successfully");
              setSettings(updatedSettings);
              setNewKey("");
              setNewValue("");
              setShowNewField(false);
              setEditingIndex(null);
            })
            .catch((error) => {
              console.error("Error updating settings:", error);
              toast.error("Error updating settings");
            });
        } else {
          toast.error("Login failed. Cannot update settings.");
        }
        setShowPasswordModal(false);
        setPassword("");
      })
      .catch((error) => {
        console.error("Error during login:", error);
        toast.error("Login failed. Cannot update settings.");
        setShowPasswordModal(false);
        setPassword("");
      });
  };

  const togglePasswordVisibility = (index: number) => {
    setPasswordVisible({
      ...passwordVisible,
      [index]: !passwordVisible[index],
    });
  };

  return (
    <Container>
      {loading && (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && (
        <>
          <h1>Settings</h1>
          <Form>
            <Row>
              {settings.map((setting, index) => (
                <Col md={6} key={setting.key} className="mb-3">
                  <Form.Group controlId={`form${setting.key}`}>
                    <Form.Label>{setting.key}</Form.Label>
                    {/* <p className="font-italic">{setting.description}</p> */}
                    <InputGroup>
                      <Form.Control
                        type={
                          setting.key.toLowerCase().includes("password") &&
                          !passwordVisible[index]
                            ? "password"
                            : "text"
                        }
                        name="value"
                        value={setting.value}
                        onChange={(e) =>
                          handleChange(
                            index,
                            e as ChangeEvent<HTMLInputElement>
                          )
                        }
                        readOnly={editingIndex !== index}
                      />
                      {setting.key.toLowerCase().includes("password") && (
                        <Button
                          variant="outline-secondary"
                          onClick={() => togglePasswordVisibility(index)}
                        >
                          <i
                            className={`bi bi-eye${
                              passwordVisible[index] ? "-slash" : ""
                            }`}
                          ></i>
                        </Button>
                      )}
                      {editingIndex === index ? (
                        <Button variant="primary" onClick={handleSaveClick}>
                          Save
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={() => handleEditClick(index)}
                        >
                          Edit
                        </Button>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
              ))}
            </Row>
            {showNewField && (
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formNewKey">
                    <Form.Label>New Key</Form.Label>
                    <Form.Control
                      type="text"
                      value={newKey}
                      onChange={handleNewKeyChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formNewValue">
                    <Form.Label>New Value</Form.Label>
                    <Form.Control
                      type="text"
                      value={newValue}
                      onChange={handleNewValueChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Button
              variant="primary"
              type="button"
              onClick={handleSaveClick}
              className="ml-2"
            >
              Submit
            </Button>
            {"   "}
            {!showNewField && (
              <Button variant="secondary" onClick={handleAddNewClick}>
                Add New
              </Button>
            )}
          </Form>
        </>
      )}

      {/* Password Modal */}
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPasswordModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePasswordSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SettingsPage;
