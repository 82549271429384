import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { theme } from "../../utils/common";

type Props = {
  topText: string | number | React.ReactNode;
  bottomText: string | number | React.ReactNode;
};

function StatsCard({ topText, bottomText }: Props) {
  return (
    <Col className="px-4 py-3 rounded-3 shadow">
      <Row>
        <h3 style={{ color: theme.primary, marginBottom: "20px" }}>
          {topText}
        </h3>
      </Row>
      <Row>
        <h6 style={{ color: theme.gray }}>{bottomText}</h6>
      </Row>
    </Col>
  );
}

export default StatsCard;
