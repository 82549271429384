import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button as BootstrapButton,
  Col,
  Container,
  Form,
  Modal,
  NavLink,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { TrackingSummary } from "../../../models/TrackingSummary";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";
import { SearchAwb } from "../tracking/SearchAwb";
import { WebhookTrackingDetails } from "../tracking/WebhookTrackingDetails";
import ClientNavbar from "../../foundation/Navbar";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_Row,
} from "material-react-table";
import { LocalDateTime } from "@js-joda/core";
import { SearchAwbFE } from "../tracking/SearchAwbFE";
import * as XLSX from "xlsx";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
export const UserDemo: FC<{ socket?: Socket<any, any> }> = (props) => {
  type ShowDetailsState = {
    show: boolean;
    trackingId: string;
    awb: TrackingSummary | null;
  };

  const defaultDetailsState = {
    show: false,
    trackingId: "",
    awb: null,
  };

  const [trackings, setTrackings] = useState<TrackingSummary[]>([]);
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(false);
  const [showDetailsState, setShowDetailsState] =
    useState<ShowDetailsState>(defaultDetailsState);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [incorrectModal, setIncorrectModal] = useState(false);
  const [catchModal, setCatchModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);

  const [isRefetching, setIsRefetching] = useState(false);
  const fetchData = (): void => {
    setFetchInProgress(true);
    lupaService.getLinkedAwbWebhook()?.then((r) => {
      const data = r.data;
      // console.log(data);
      setTrackings(data.map((tracking: any) => tracking));
      setFetchInProgress(false);
    });
  };

  const customToast = (message: string) => {
    const splitMessage = message.split(".");
    const firstLine = splitMessage[0].trim();
    const secondLine = splitMessage[1] ? splitMessage[1].trim() : "";
    const thirdLine = splitMessage.slice(2).join(".").trim();
    toast.error(
      <div style={{ textAlign: "center" }}>
        <div>{firstLine}</div>
        <div>{secondLine}</div>
        <div> {thirdLine}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const addAwbToTracking = async (awbNumber: string) => {
    // toastTypes.info("Fetching AWB details", true);
    try {
      const { data } = await lupaService.addAwbToTracking({ awbNumber });

      toastTypes.success("AWB added to tracking successfully");
      fetchData();

      return true;
    } catch (error: any) {
      if (
        error.response.data.message ===
        "Airline not yet live on Intemo platform"
      ) {
        customToast("Airline Not Live on.Intemo Platform");
      } else if (
        error.response.data.message ===
        "Access Denied. Tracking limit exceeded. Please contact admin."
      ) {
        customToast(
          "Access Denied. Tracking limit exceeded. Please contact admin."
        );
      } else if (
        error.response.data.message ===
        "AWB number must adhere to the format (e.g.,) 123-45678901. No Alphabets allowed."
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else if (
        error.response.data.message ===
        "Please enter a 11 digit AWB number (E.g.)123-45678901"
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else {
        toast.error(error.response.data.message);
      }

      console.log(error.response.data.message);
      return false;
    }
  };

  const handleExportToExcel = () => {
    const filteredRows = table.getRowModel().rows.map((row) => ({
      "AWB Number": row.original.awbNumber,
      Status: row.original.trackingStatus,
      Origin: row.original.origin,
      Destination: row.original.destination,
      Flight: row.original.flightsEtdEta
        .map((flight) => flight.flightNumber)
        .join(", "),
      Pieces: row.original.pieces.toString(),
      Weight: row.original.weight.toString(),
      "Date of Entry": new Date(
        row.original.timestamp.toString()
      ).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredRows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AWBList");

    XLSX.writeFile(workbook, "AWBList.xlsx");
  };

  const handleExportToExcelPage = () => {
    const filteredRows = table.getPrePaginationRowModel().rows.map((row) => ({
      "AWB Number": row.original.awbNumber,
      Status: row.original.trackingStatus,
      Origin: row.original.origin,
      Destination: row.original.destination,
      Flight: row.original.flightsEtdEta
        .map((flight) => flight.flightNumber)
        .join(", "),
      Pieces: row.original.pieces.toString(),
      Weight: row.original.weight.toString(),
      "Date of Entry": new Date(
        row.original.timestamp.toString()
      ).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredRows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AWBList");

    XLSX.writeFile(workbook, "AWBList.xlsx");
  };

  const handleExportToExcelSelect = () => {
    const filteredRows = table.getSelectedRowModel().rows.map((row) => ({
      "AWB Number": row.original.awbNumber,
      Status: row.original.trackingStatus,
      Origin: row.original.origin,
      Destination: row.original.destination,
      Flight: row.original.flightsEtdEta
        .map((flight) => flight.flightNumber)
        .join(", "),
      Pieces: row.original.pieces.toString(),
      Weight: row.original.weight.toString(),
      "Date of Entry": new Date(
        row.original.timestamp.toString()
      ).toLocaleDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredRows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AWBList");

    XLSX.writeFile(workbook, "AWBList.xlsx");
  };

  const onShowDetails = (trackingId: string, awb: TrackingSummary) => {
    setShowDetailsState({ show: true, trackingId: trackingId, awb: awb });
  };

  const handleRefresh = () => {
    fetchData();
  };

  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };
  const handleUploadClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  let excelawbNumbers;
  const apikey = localStorage.getItem("userApiLupaJwtToken") as string;

  const handleUpload = async () => {
    if (file) {
      const response = await lupaService.uploadFile(file, apikey);
      const responseData = await response.json();

      if (Array.isArray(responseData.data) && responseData.data.length > 0) {
        excelawbNumbers = responseData.data;
        setSuccessModal(true);
        try {
          await lupaService.addExcelToTracking(
            apikey,
            responseData.name,
            excelawbNumbers
          );
        } catch (error) {
          console.log(error);
        }
      } else if (responseData.data === "AWBNumber exceeded limit") {
        setErrorModal(true);
      } else if (responseData.data === "Incorrect data format") {
        setIncorrectModal(true);
      } else {
        setCatchModal(true);
      }
    }
  };

  const uploadHistory = () => {
    setUploadModal(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "timestamp", desc: true },
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo<MRT_ColumnDef<TrackingSummary>[]>(
    () => [
      {
        header: "AWB",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",
        Cell: ({ row }) => (
          <a
            href="#"
            onClick={() => onShowDetails(row.original.awbNumber, row.original)}
          >
            {row.original?.awbNumber || ""}
          </a>
        ),
      },

      {
        header: "Status",
        filterFn: "includesString",
        accessorFn: (row) => row?.trackingStatus || "",
      },
      {
        header: "Origin",
        accessorFn: (row) => row?.origin || "",
      },
      {
        header: "Destination",
        accessorFn: (row) => row?.destination || "",
      },
      {
        header: "Flight",
        accessorFn: (row) =>
          row?.flightsEtdEta
            ? row.flightsEtdEta.map((flight) => flight.flightNumber).join(", ")
            : "",
      },
      {
        header: "Pcs",
        accessorFn: (row) => row?.pieces || "",
      },
      {
        header: "Weight (kg)",
        accessorFn: (row) => row?.weight || "",
      },
      {
        accessorKey: "timestamp",
        header: "Date of Entry",
        size: 150,
        accessorFn: (row) =>
          row?.timestamp ? new Date(row.timestamp.toString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleDateString() : "";
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: trackings ?? [],
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      // sorting: [
      //   {
      //     accessorKey: "timestamp",
      //     desc: true,
      //   },
      // ],
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },

    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => handleExportToExcelPage()}
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel()}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcelSelect()}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
      </Box>
    ),
  });

  const detailsBlock = (
    <Modal
      size="lg"
      centered
      show={showDetailsState.show}
      onHide={() => setShowDetailsState(defaultDetailsState)}
    >
      <Modal.Header closeButton={true} className="mx-2 px-4">
        <h5 className="section-title">
          Tracking Details - {showDetailsState.awb?.awbNumber}
        </h5>
      </Modal.Header>
      <Modal.Body className="px-0">
        <WebhookTrackingDetails
          trackingId={showDetailsState.awb?.awbNumber || "000-00000000"}
          awbDetails={showDetailsState.awb}
        />
      </Modal.Body>
    </Modal>
  );
  const spinnerBlock = (
    <Container
      fluid={true}
      className="position-absolute top-50 start-50 translate-middle mx-2 col-sm-6"
    >
      <Row className="d-flex justify-content-center">
        <Col className="col-1">
          <Spinner animation="border" className="text-muted"></Spinner>
        </Col>
        <Col className="col-4">
          <h5 className="text-muted">Fetching summary...</h5>
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container fluid className="m-0 p-0">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <Row>
          <SearchAwbFE addAwbToTracking={addAwbToTracking} />
        </Row>
        <Row className="mt-2">
          <Col className="d-flex justify-content-end">
            <BootstrapButton
              variant="primary"
              onClick={handleRefresh}
              className="me-2"
            >
              {" "}
              Refresh Table
            </BootstrapButton>

            <BootstrapButton
              variant="primary"
              onClick={handleUploadClick}
              className="me-2"
            >
              {" "}
              Upload
            </BootstrapButton>

            <BootstrapButton
              variant="primary"
              className="me-2"
              as={Link as any}
              to="/upload-history"
            >
              Upload History
            </BootstrapButton>
          </Col>
        </Row>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File(Excel)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileChange}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <BootstrapButton variant="secondary" onClick={handleModalClose}>
            Close
          </BootstrapButton> */}
            <BootstrapButton variant="primary" onClick={handleUpload}>
              Upload
            </BootstrapButton>
          </Modal.Footer>
        </Modal>
        <Modal
          show={successModal}
          onHide={() => {
            setSuccessModal(false);
            setShowModal(false);
          }}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#28a745", color: "white" }}
          >
            <Modal.Title>Success!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "#28a745" }}>Upload successfull.</p>
          </Modal.Body>
        </Modal>

        {/* Error Modal */}
        <Modal
          show={errorModal}
          onHide={() => {
            setErrorModal(false);
            setShowModal(false);
          }}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#dc3545", color: "white" }}
          >
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "#dc3545" }}>Max AWB Upload limit exceeded</p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={incorrectModal}
          onHide={() => {
            setIncorrectModal(false);
            setShowModal(false);
          }}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#dc3545", color: "white" }}
          >
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "#dc3545" }}>Incorrect Data Format!</p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* Catch Modal */}
        <Modal
          show={catchModal}
          onHide={() => {
            setCatchModal(false);
            setShowModal(false);
          }}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#dc3545", color: "white" }}
          >
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "#dc3545" }}>Error occured !!</p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Row
          className="mt-2 mx-2 position-relative"
          style={fetchInProgress ? { minHeight: "300px" } : {}}
        >
          {!fetchInProgress && (
            <>
              <MaterialReactTable table={table} />
            </>
          )}
          {fetchInProgress && spinnerBlock}
        </Row>

        {detailsBlock}
      </LocalizationProvider>
    </Container>
  );
};
