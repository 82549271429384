import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebhookUiService } from "../../../services/webhook-ui";
import UserApiNavbar from "../../foundation/Navbar";

interface RawData {
  userId: number;
  awbNumber: string;
  awbBodyRaw: string;
  user: {
    firstName: string;
    lastName: string;
  };
  updatedAt: Date;
}

const RawDataDisplay: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [rawData, setRawData] = useState<RawData[]>([]);

  useEffect(() => {
    if (id) {
      fetchRawData(id);
    }
  }, [id]);

  const fetchRawData = async (id: string) => {
    try {
      const data = await WebhookUiService.getAwbRawData(id);
      console.log(data);
      setRawData(data);
    } catch (error) {
      console.error("Error fetching raw data:", error);
    }
  };
  const parseAwbBodyRaw = (rawString: string) => {
    try {
      return JSON.parse(rawString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  return (
    <div>
      <UserApiNavbar />
      {/* <h5>Raw Data for AWB Number: {id}</h5> */}
      <div>
        {/* Display raw data content here */}
        {rawData.map((item: RawData, index: number) => (
          <div key={index}>
            {/* <h5>User ID: {rawData[0]?.userId}</h5> */}
            <h6>
              User: {item.user?.firstName} {item.user?.lastName}
            </h6>
            <p>Timestamp: {new Date(item.updatedAt).toLocaleString()}</p>
            <pre>
              <code>
                {JSON.stringify(parseAwbBodyRaw(item.awbBodyRaw), null, 2)}
              </code>
            </pre>
            {index !== rawData.length - 1 && <hr />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RawDataDisplay;
