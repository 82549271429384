import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import { getRoleNameFromRole } from "../../../utils/common";
import ClientNavbar from "../../foundation/Navbar";
import CreateUser from "../../modals/CreateUser/CreateUser";
import UpdateUser from "../../modals/UpdateUser/UpdateUser";
import { UserListdetail } from "../../../models/User";
import { deleteUser } from "../../../services/user";
import { toastTypes } from "../../../utils/toastTypes";
import ConfirmActionPopup from "../../modals/Confirm";
import UserListTable from "./UserListTable";
import { useQuery } from "react-query";
import { Input } from "@mui/material";

function UserList() {
  const { data: users, refetch } = useQuery("users", lupaService.getUser);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showInactive, setShowInactive] = useState(false);

  return (
    <>
      <ClientNavbar />
      <Container className="my-5 py-5">
        <Row>
          <Col>
            <h5 className="my-2">User List</h5>
          </Col>
          <Col className="float-end ms-2 d-flex justify-content-end gap-2">
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="Show Inactive Users"
              className="py-2"
              onChange={(e) => {
                setShowInactive(e.target.checked);
              }}
            />

            <Button variant="primary" onClick={handleShow}>
              Add User
            </Button>
          </Col>
        </Row>
        <UserListTable
          users={users?.data.data}
          getUsers={refetch}
          showInactive={showInactive}
        />
      </Container>
      <CreateUser show={show} handleClose={handleClose} refetch={refetch} />
    </>
  );
}
export default UserList;
