import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { Request } from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
} from "material-react-table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";

const RequestTable = () => {
  const {
    data: request,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("request", WebhookUiService.getEtdEtaRequest, {
    refetchOnWindowFocus: false,
  });
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const handleExportToExcel = (rows: MRT_Row<Request>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ETD_ETA_Request_Data");

    XLSX.writeFile(workbook, "ETD_ETA_Request_Data.xlsx");
  };

  const handleExportRows = (rows: MRT_Row<Request>[]) => {
    const doc = new jsPDF();

    const tableData: any[] = rows.map((row) => [
      row.original.awb_number,
      row.original.flight_num,
      row.original.origin,
      row.original.destination,
      row.original.date,
      row.original.scraping_status,
      row.original.scraping_count,
    ]);

    const tableHeaders: string[] = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      margin: { top: 40 },
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(26);
        doc.setTextColor(40);
        // doc.text(`AWBs Count: ${rows.length}`, data.settings.margin.left, 22);

        // Footer
        var str =
          "Page " + (doc as any).internal.getCurrentPageInfo().pageNumber;
        console.log((doc as any).internal.getCurrentPageInfo());
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.save("ETD_ETA_Request_Data.pdf");
  };

  const columns = useMemo<MRT_ColumnDef<Request>[]>(
    () => [
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awb_number || "",
      },

      {
        header: "Flight Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.flight_num || "",
      },
      {
        header: "Origin",
        accessorFn: (row) => row?.origin || "",
      },
      {
        header: "Destination",
        accessorFn: (row) => row?.destination || "",
      },

      {
        header: "Date",
        accessorFn: (row) => row?.date || "",
      },
      {
        header: "Scraping status",
        accessorFn: (row) => row?.scraping_status || "",
      },
      {
        header: "Scraping count",
        accessorFn: (row) => {
          if (
            row?.scraping_count !== undefined &&
            row?.scraping_count !== null &&
            row?.scraping_count !== 0
          ) {
            return row.scraping_count.toString();
          } else if (row?.scraping_count === 0) {
            return "0";
          } else {
            return "";
          }
        },
      },
      {
        header: "Added By",
        accessorFn: (row) => row?.addedby || "",
      },
      {
        header: "CreatedAt",
        accessorFn: (row) => row?.createdAt || "",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: request ?? [],
    enableRowSelection: true,
    // getRowId: (row) => row.id,
    initialState: { showColumnFilters: true, density: "compact" },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};
export default RequestTable;
