import React, { FC, useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { lupaService } from "../../../services/lupa";

const DataSourceSwapLog: FC = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await lupaService.changeDataSourceSwapLogs();
        setTableData(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "Airline Code",
        accessor: "airlineCode",
        accessorFn: (row) => row.airlineCode || "",
      },
      {
        header: "Previous Data Source",
        accessor: "previousDataSource",
        accessorFn: (row) => row.previousDataSource || "",
      },
      {
        header: "New Data Source",
        accessor: "newDataSource",
        accessorFn: (row) => row.newDataSource || "",
      },
      {
        header: "Old Date Format Code",
        accessor: "oldDateFormatCode",
        accessorFn: (row) => row.oldDateFormatCode || "",
      },
      {
        header: "New Date Format Code",
        accessor: "newDateFormatCode",
        accessorFn: (row) => row.newDateFormatCode || "",
      },
      {
        header: "Swapped At",
        accessor: "swappedAt",
        accessorFn: (row) =>
          row.swappedAt ? new Date(row.swappedAt).toLocaleString() : "",
      },
      {
        header: "Created At",
        accessor: "createdAt",
        accessorFn: (row) =>
          row.createdAt ? new Date(row.createdAt).toLocaleString() : "",
      },
      {
        header: "Updated At",
        accessor: "updatedAt",
        accessorFn: (row) =>
          row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "",
      },
      {
        header: "Logs",
        accessor: "logs",
        Cell: ({ row }) => (
          <Button
            variant="primary"
            onClick={() => handleRowClick(row.original)}
          >
            View Details
          </Button>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row: any) => {
    setSelectedRowData(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      showColumnFilters: true,
    },
    getRowId: (row) => row.id,
  });

  return (
    <>
      <ClientNavbar />
      {isLoading ? (
        <Spinner animation="border" />
      ) : (
        <MaterialReactTable table={table} />
      )}

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowData && (
            <>
              <h6>Airline Code: {selectedRowData.airlineCode}</h6>
              <pre>
                Previous Data Source:{" "}
                <code>{selectedRowData.previousDataSource}</code>
              </pre>
              <pre>
                New Data Source: <code>{selectedRowData.newDataSource}</code>
              </pre>
              <pre>
                Old Date Format Code:{" "}
                <code>{selectedRowData.oldDateFormatCode}</code>
              </pre>
              <pre>
                New Date Format Code:{" "}
                <code>{selectedRowData.newDateFormatCode}</code>
              </pre>
              <pre>
                Swapped At:{" "}
                <code>
                  {new Date(selectedRowData.swappedAt).toLocaleString()}
                </code>
              </pre>
              <pre>
                Created At:{" "}
                <code>
                  {new Date(selectedRowData.createdAt).toLocaleString()}
                </code>
              </pre>
              <pre>
                Updated At:{" "}
                <code>
                  {new Date(selectedRowData.updatedAt).toLocaleString()}
                </code>
              </pre>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataSourceSwapLog;
