import React from "react";
import { Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { getInvoiceById } from "../../../services/customer";
import moment from "moment";

type Props = {
  receiptId: string | null;
  show: boolean;
  onHide: () => void;
};

function ViewInvoice({ receiptId, show, onHide }: Props) {
  const invoiceId = receiptId;
  const { data, isLoading, isError, error } = useQuery(
    ["invoice", invoiceId],
    () => getInvoiceById(invoiceId!)
  );

  console.log({ data });
  const invoice = data?.data;
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Row className="m-0 p-0">
              <Col className="rounded border m-2">
                <h4>Invoice No.</h4>
                <h5>{invoice?.invoiceId}</h5>
              </Col>
              <Col className="rounded border m-2">
                <h4>Invoice Date</h4>
                <h5>{moment(invoice?.createdAt).format("DD-MM-YYYY")}</h5>
              </Col>
              <Col className="rounded border m-2">
                <h4>Billing Period</h4>
                <h5>
                  {moment(invoice?.from).format("DD-MM-YYYY")} -{" "}
                  {moment(invoice?.to).format("DD-MM-YYYY")}
                </h5>
              </Col>
              <Col>
                <h4>Due Date</h4>
                <h5>
                  {invoice?.dueDate
                    ? moment(invoice?.dueDate).format("DD-MM-YYYY")
                    : "Not Set"}
                </h5>
              </Col>
              <Col className="rounded border m-2">
                <h4> Amount</h4>
                <h5>Rs.{invoice?.amount}</h5>
              </Col>
            </Row>
            {invoice?.receipt && invoice?.receipt.length > 0 ? (
              <Table striped bordered hover className="m-2 p-0">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Payment Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice?.receipt?.map((receipt) => (
                    <tr key={receipt.id}>
                      <td>{receipt.receiptId}</td>
                      <td>{moment(receipt.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        {moment(receipt.paymentDate).format("DD-MM-YYYY")}
                      </td>
                      <td>{receipt.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h3>No Receipts Found</h3>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ViewInvoice;
