import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClientNavbar from "../../foundation/Navbar";
import { useNavigate } from "react-router-dom";

interface CardProps {
  title: string;
  route: string;
  description: string;
}

const GridComponent: React.FC = () => {
  const navigate = useNavigate();
  const [activeCard, setActiveCard] = useState<string | null>(null);

  const cards: CardProps[] = [
    {
      title: "Overall Change Log",
      route: "/log",
      description: "View overall changes and updates across the application.",
    },
    {
      title: "DataSource Change Log",
      route: "/data-source-changelog",
      description: "Check changes related to data sources and integrations.",
    },
    {
      title: " Dynamic Source Change",
      route: "/data-source-swaplog",
      description: "Source updates adapt during server failures.",
    },
  ];

  const handleClick = (route: string) => {
    setActiveCard(route);
    navigate(route);
  };

  return (
    <>
      <ClientNavbar />
      <Container>
        <Row>
          {cards.map((card, index) => (
            <Col key={index} md={3}>
              <Card
                style={{
                  width: "18rem",
                  margin: "10px",
                  cursor: "pointer",
                  transform:
                    activeCard === card.route ? "scale(1.05)" : "scale(1)",
                  transition: "transform 0.3s ease",
                }}
                onClick={() => handleClick(card.route)}
              >
                <Card.Body>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default GridComponent;
