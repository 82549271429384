import React from "react";
import ClientNavbar from "../foundation/Navbar";
import BodyLoadingSpinner from "./BodyLoadingSpinner";

type Props = {
  children: React.ReactNode;
  loading?: boolean;
};

function Base({ children, loading = false }: Props) {
  return (
    <div>
      <ClientNavbar />
      {loading ? <BodyLoadingSpinner /> : children}
    </div>
  );
}

export default Base;
