import { DateTimeFormatter, LocalDate, LocalDateTime } from "@js-joda/core";
import { OrganizationType } from "../models/Organization";
import { User, UserRole } from "../models/User";
import envConfig from "../utils/envConfig";
import axios from "axios";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
export const BASE_URL_TEST = process.env.REACT_APP_BASE_URL;
//export const BASE_URL = 'https://api.intemo.tech/'
export const BASE_URL = envConfig.url.API_URL;
const LUPA_BASE_URL = envConfig.url.API_URL;
const MICRO_URL = envConfig.url.MICRO_URL;

export interface ApplicationTheme {
  name: string;
  variant: string;
  bgColor: string;
  activePillsColor: string;
}

export const apiEndpoints = {
  BASE_URL,
  MICRO_URL,
  SHIPPERS: BASE_URL + "shippers",
  SHIPPER_CATEGORY: BASE_URL + "shipper-category",
  CONSGINEE: BASE_URL + "consignee",
  AIRPORT: BASE_URL + "airport",
  FLIGHT_SCHEUDLE: BASE_URL + "flight-schedule",
  AUTH_LOGIN: BASE_URL + "auth/signin",
  AUTH_LOGOUT: BASE_URL + "auth/signout",
  SHIPPER_USER: BASE_URL + "user",
  CREATE_USER: BASE_URL + "account/create-user",
  VERFIY_TOKEN: BASE_URL + "account",
  VERIFY_PASSWORD_TOKEN: BASE_URL + "user/verify-token",
  GENERATE_TOKEN: BASE_URL + "account/create",
  UPDATE_PASSWORD: BASE_URL + "user/reset-password",
  BOOKING: BASE_URL + "booking",
  AVIATION_STACK: BASE_URL + "aviation-stack",
  CHANGE_PASSWORD: BASE_URL + "user/change-password",
  FORGOT_PASSWORD: BASE_URL + "user/forgot-password",
  UPDATE_USER: BASE_URL + "apiUser/updateUser",
  DELETE_USER: BASE_URL + "apiUser/deactivate",
  DELETE_AWB_DETAIL: BASE_URL + "apiUser/getAWBDetails",
  DELETE_AND_ADD_AWB_ON_SCRAP:
    BASE_URL + "apiUser/deleteAWBTracking/deleteAdd/scrap",
  DELETE_AND_ADD_AWB_ON_SCRAP_BULK:
    BASE_URL + "apiUser/deleteAWBTracking/deleteAdd/scrap/bulk",
};

export class Utils {
  public static rsUnicode = "&#8377;";

  public static formatDate(date: LocalDate): string {
    const fmt: DateTimeFormatter = DateTimeFormatter.ofPattern("dd-MM-yyyy");
    return fmt.format(date);
  }

  public static formatDateTime(dateTime: LocalDateTime): string {
    const fmt: DateTimeFormatter = DateTimeFormatter.ofPattern(
      "dd-MM-yyyy HH:mm:ss"
    );
    return fmt.format(dateTime);
  }

  public static formatAmount(num?: Number): string {
    return num !== undefined
      ? num.toLocaleString("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "";
  }

  public static getThemeByUser(user: User): ApplicationTheme {
    const orgType: OrganizationType = user.org.orgType;

    if (orgType === OrganizationType.FF) {
      return {
        name: "theme1",
        variant: "light",
        bgColor: "#bac4de",
        activePillsColor: "",
      };
    } else {
      if ((user.org.orgId / 100) % 2 === 1) {
        return {
          name: "theme2",
          variant: "secondary",
          bgColor: "#a6a6a6",
          activePillsColor: "",
        };
      } else {
        return {
          name: "theme3",
          variant: "info",
          bgColor: "#b8d2ad",
          activePillsColor: "#FCE017",
        };
      }
    }
  }

  public static getRoleDesicrption(role: UserRole): string {
    switch (role) {
      case UserRole.ORDER_FULFILLMENT:
        return "Order FulFillment";
      case UserRole.ORDER_MANAGEMENT:
        return "Order Managemenet";
      case UserRole.FF_ADMIN:
        return "Freight Forwarder Admin";
      default:
        return "Shipper User";
    }
  }
}

export const http = axios.create({
  baseURL: LUPA_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: false,
});

export const handleExportRows = (
  tableHeaders: string[],
  tableData: any[],
  fileName: string
) => {
  const doc = new jsPDF();

  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
  });

  doc.save(fileName);
};

export const httpAuth = axios.create({
  baseURL: LUPA_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});
