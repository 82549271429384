import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { AWBDetails, UserTrackingFlights, UserTrackingHistories } from "../../../models/TrackingSummary";
import { useParams } from "react-router-dom"; // Import useParams to access route parameters
import WebhookUiService from "../../../services/webhook-ui";
import EditAWBForm from "./EditAWBForm";
import ClientNavbar from "../../foundation/Navbar";

interface EditFormProps {
    awbData: AWBDetails | null;
}

const EditAWBFormPage: React.FC = () => {
    const { awbNumber } = useParams(); // Get awbNumber from route params
    const [awbData, setAwbData] = useState<AWBDetails[] | null>(null);
    const fetchData = async () => {
        try {
            const data = await WebhookUiService.getAWBBasedonId(awbNumber || "");
            setAwbData(data);
        } catch (error) {
            console.error("Error fetching AWB data:", error);
            setAwbData(null); // Set awbData to null in case of an error
        }
    };

    // Fetch awbData based on awbNumber

    useEffect(() => {
        fetchData();
    }, [awbNumber]);



    return (
        <div>
            <ClientNavbar />
            <h5>Edit AWB: {awbNumber}</h5>
            {awbData && <EditAWBForm awbData={awbData} />}
        </div>
    );

};

export default EditAWBFormPage
