import { ReactNode, useEffect, useMemo, useState } from "react";
import ClientNavbar from "../../foundation/Navbar";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  Badge,
} from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import { hardDeleteAndAddAWB } from "../../../services/awbDeleteAndAddScrap";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_Row,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import { getAWBList } from "../../../services/customer";
import { AWBList } from "../../../models/Common";
import { hardDeleteAndAddAWBBulk } from "../../../services/awbDeleteAndAddScrapBulk";
import { Link } from "react-router-dom";

interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}

const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <>
      {loading ? (
        <div className="position-relative">
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-white opacity-25"
            style={{ backdropFilter: "blur(10px)" }}
          />
          <Spinner
            animation="border"
            role="status"
            className="position-fixed top-50 start-50"
            style={{ zIndex: 9999 }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="spinner-overlay">{children}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const DeleteAdd = () => {
  const [Awb, setAwb] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidAwb, setIsValidAwb] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [result, setResult] = useState<any>(null);

  const submitForm = () => {
    setIsValidAwb(false);
    const awbRegex = /^\d{3}-\d{8}$/;

    if (!awbRegex.test(Awb)) {
      setIsValidAwb(false);
      return;
    }

    setIsValidAwb(true);
    setShowConfirmation(true);
  };

  const [selectedAwbs, setSelectedAwbs] = useState<any[]>([]);
  const [isBulkOperation, setIsBulkOperation] = useState(false);

  const LogsModal = ({
    logData,
    onClose,
  }: {
    logData: any[];
    onClose: () => void;
  }) => {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logs for selected AWB details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>AWB Number</th>
                <th>Success</th>
                <th>Message</th>
                <th>API Status</th>
              </tr>
            </thead>
            <tbody>
              {logData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail?.trackingRecord.awbNumber}</td>
                  <td>{detail.isSuccess ? "Success" : "Fail"}</td>
                  <td>
                    {detail.message ===
                    "AWB deleted successfully and added to trackings."
                      ? "Done"
                      : detail.message}
                  </td>
                  <td>{detail?.statusCode}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const { data, isLoading, isError, refetch } = useQuery(
    ["customer"],
    () => getAWBList(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleBulkDeleteAdd = async (rows: MRT_Row<AWBList>[]) => {
    if (Awb !== "") {
      alert(
        "Single delete/add operation is in progress. Please complete it before performing bulk operations."
      );
      return;
    }

    const awbNumbers = rows.map((row) => row.original.awbNumber);

    if (awbNumbers.length > 15) {
      alert("Cannot process more than 15 AWB numbers at once.");
      return;
    }

    const filteredAwbNumbers = awbNumbers.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    setResult({
      awbNumbers: filteredAwbNumbers,
    });

    setShowConfirmation(true);
    setIsBulkOperation(true);
  };

  const confirmDeleteAdd = async () => {
    try {
      let response;

      setShowConfirmation(false);
      setLoading(true);

      if (isBulkOperation) {
        response = await hardDeleteAndAddAWBBulk(result.awbNumbers);
      } else if (Awb !== "") {
        const singleResponse = await hardDeleteAndAddAWB(Awb);
        if (singleResponse.data.isSuccess) {
          toast.success(singleResponse.data.message);
        } else {
          toast.error(singleResponse.data.message);
        }
      }

      if (response) {
        setSelectedAwbs(response.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong");
    } finally {
      setShowConfirmation(false);
      setLoading(false);
      setIsBulkOperation(false);
      setAwb("");
    }
  };

  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  const emailOptions = useMemo(() => {
    const options = new Set<string>();
    if (data) {
      data.data.forEach((row) =>
        row.user.forEach((user) => options.add(user.email))
      );
    }
    return Array.from(options).map((option) => ({
      text: option,
      value: option,
    }));
  }, [data]);
  const getBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "success";
      case "in transit":
        return "info";
      case "pending":
        return "warning";
      case "canceled":
        return "danger";
      default:
        return "secondary";
    }
  };
  const columns = useMemo<MRT_ColumnDef<AWBList>[]>(
    () => [
      {
        accessorKey: "awbNumber",
        header: "AWB No.",
        accessorFn: (row) => row.awbNumber || "",
      },
      {
        header: "Name",
        filterFn: "includesString",
        accessorFn: (row) =>
          row.user.map((user) => user.firstName + " " + user.lastName),
        Cell: ({ cell }) =>
          cell
            .getValue<string[]>()
            .map((name, index) => <div key={index}>{name}</div>),
      },
      {
        header: "Email",
        accessorFn: (row) => row.user.map((user) => user.email),
        filterVariant: "select",
        filterFn: "includesString",
        filterSelectOptions: emailOptions,
        Cell: ({ cell }) =>
          cell
            .getValue<string[]>()
            .map((email, index) => <div key={index}>{email}</div>),
      },
      {
        accessorKey: "trackingStatus",
        header: "Tracking Status",
        accessorFn: (row) =>
          row.trackingStatus ? (
            <Badge bg={getBadgeColor(row.trackingStatus)}>
              {row.trackingStatus}
            </Badge>
          ) : (
            ""
          ),
      },
      {
        accessorKey: "createdDateTime",
        header: "Date of Entry",
        size: 150,
        accessorFn: (row) =>
          row.createdDateTime
            ? new Date(row.createdDateTime).toLocaleString()
            : "",
        filterVariant: "date-range",
      },
    ],
    [emailOptions]
  );

  const table = useMaterialReactTable<AWBList>({
    columns,
    data: data?.data ?? [],
    enableRowNumbers: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: true,
      density: "compact",
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <h6 className="mt-2">
          No of AWBs: {table.getFilteredRowModel().rows.length}
        </h6>
        <Button
          disabled={
            (!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()) ||
            Awb !== ""
          }
          onClick={() => {
            handleBulkDeleteAdd(table.getSelectedRowModel().rows);
          }}
        >
          Bulk Delete And Add
        </Button>
        <Button
          variant="primary"
          className="me-2"
          as={Link as any}
          to="/scrap-delete-add-log"
        >
          Logs
        </Button>
      </Box>
    ),
  });

  return (
    <>
      {selectedAwbs.length > 0 && (
        <LogsModal logData={selectedAwbs} onClose={() => setSelectedAwbs([])} />
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <Loader loading={loading}>
          <Container className="py-5">
            <Form>
              <Row className="my-2" style={{ alignItems: "flex-end" }}>
                <Col md={4}>
                  <Form.Label htmlFor="inputBox">Enter AWB:</Form.Label>
                  <Form.Control
                    type="text"
                    id="inputBox"
                    name="inputBox"
                    onChange={(e) => setAwb(e.target.value)}
                    isInvalid={!isValidAwb}
                    disabled={isBulkOperation}
                  />
                  {!isValidAwb && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid AWB number (e.g., 176-12345678).
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={submitForm}
                    disabled={loading || isBulkOperation}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
          {loading ? (
            <div className="opacity-25">
              <MaterialReactTable table={table} />
            </div>
          ) : (
            <MaterialReactTable table={table} />
          )}
        </Loader>
      </LocalizationProvider>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete and Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to proceed with delete and add operation?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmDeleteAdd}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAdd;
