import { Field, Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Card, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import userService from "../../../services/user";
import {
  passwordResetInitialData,
  passwordResetValidationSchema,
} from "../../../utils/formValidation";
import { toast } from "react-toastify";
import envConfig from "../../../utils/envConfig";
import AuthDetailsCard from "../../foundation/AuthDetailsCard";

function GeneratePassword() {
  const FRONTEND_BASE_URL = envConfig.url.APP_URL;

  const { token } = useParams();
  const navigate = useNavigate();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let emailQuery = params.get("email");
  const [defaultValue, setDefaultValue] = useState({
    ...passwordResetInitialData,
    email: emailQuery!,
  });
  const [email, setEmail] = useState(emailQuery!);
  if (!token || !emailQuery)
    window.location.href = FRONTEND_BASE_URL + "/login";

  //window.location.replace('https://api.intemo.tech/login')

  //navigate("/login");

  useEffect(() => {
    userService.verfiyPasswordToken(token!, email!).then((response) => {
      const data = response;
      console.log(typeof data);
      if (!data) window.location.href = FRONTEND_BASE_URL + "/login";

      //navigate("/login")
      // window.location.replace('https://api.intemo.tech/login')

      setEmail(email);
      setDefaultValue((prev) => ({ ...prev, email: email }));
    });
  }, [token]);

  const formik = useFormik({
    initialValues: defaultValue,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      userService
        .updatePassword({ ...values, token: token! })
        .then(() => {
          setSubmitting(false);
          toast.success("Password updated successfully");
          setTimeout(
            () => (window.location.href = FRONTEND_BASE_URL + "/login"),

            //  navigate("/login")
            //  window.location.replace('https://api.intemo.tech/login')

            1000
          );
        })
        .catch((e) => {
          console.log(e.response);
          alert("Error");
        });
    },
    validationSchema: passwordResetValidationSchema,
  });
  return (
    <AuthDetailsCard title={"Create Password"}>
      <FormikProvider value={formik}>
        <Form className="my-auto">
          <div className="d-flex"></div>
          <div className="d-flex">
            <div className="form-group my-2 w-100 ms-2">
              <label htmlFor="email">Email Address</label>
              <Field
                name="email"
                disabled
                // value={email}
                className={
                  formik.touched.email && formik.errors.email
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="email"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="m-2 w-100">
            <label htmlFor="password">Create Password</label>
            <Field
              name="password"
              type="password"
              className={
                formik.touched.password && formik.errors.password
                  ? "form-control is-invalid"
                  : "form-control"
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="invalid-feedback">{formik.errors.password}</div>
            ) : null}
          </div>

          <div className="my-2 mx-2 w-100">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Field
              name="confirmPassword"
              type="password"
              className={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? "form-control is-invalid"
                  : "form-control"
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="invalid-feedback">
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>

          <div className="form-group mt-4 ms-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Please wait..." : "Change Password"}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </AuthDetailsCard>
  );
}

export default GeneratePassword;
