import React, { useMemo, useState } from "react";
import { UserListdetail } from "../../../models/User";
import { Badge, Button, Spinner, Table } from "react-bootstrap";
import { getRoleNameFromRole } from "../../../utils/common";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import UpdateUser from "../../modals/UpdateUser/UpdateUser";
import ConfirmActionPopup from "../../modals/Confirm";
import { deleteUser } from "../../../services/user";
import { toastTypes } from "../../../utils/toastTypes";

type Props = {
  users: UserListdetail[] | undefined;
  getUsers: () => void;
  showInactive: boolean;
};

function UserListTable({ users, showInactive, getUsers }: Props): JSX.Element {
  const [showUpdate, setShowUpdate] = useState<{
    show: boolean;
    user: UserListdetail | undefined;
  }>({
    show: false,
    user: undefined,
  });

  const [showDelete, setShowDelete] = useState<{
    show: boolean;
    id: number | undefined;
  }>({
    show: false,
    id: undefined,
  });

  const handleDeleteSubmit = (id: number) => {
    console.log(id);
    deleteUser(id)
      .then((data) => {
        if (data.isSuccess) {
          toastTypes.success("User deleted successfully");
          getUsers();
          setShowDelete({ show: false, id: undefined });
        } else {
          toastTypes.error(data.message);
        }
      })
      .catch((error) => {
        toastTypes.error(error.message);
      });
  };

  const filteredUsers = showInactive
    ? users
    : users?.filter((user) => user.isActive);

  if (!users)
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  console.log(users);
  return (
    <Table className="table shadow rounded table-hover mt-2">
      <TableHeader />
      <tbody className="rounded">
        {filteredUsers?.map((item, index) => (
          <TableRow
            item={item}
            index={index}
            key={index}
            setShowUpdate={setShowUpdate}
            setShowDelete={setShowDelete}
          />
        ))}
      </tbody>
      {showUpdate.show && (
        <UpdateUser
          show={showUpdate.show}
          handleClose={() => setShowUpdate({ show: false, user: undefined })}
          user={showUpdate.user}
          refetch={getUsers}
        />
      )}
      {showDelete.show && (
        <ConfirmActionPopup
          show={showDelete.show}
          onHide={() => setShowDelete({ show: false, id: undefined })}
          onSubmit={() => handleDeleteSubmit(showDelete.id!)}
          header="Delete User"
          confirmText="Are you sure you want to delete this user?"
        />
      )}
    </Table>
  );
}

export default UserListTable;
