import axios, { AxiosResponse } from "axios";
import { CreateUserUpload, UpdateUserAdmin } from "../models/User";
import { errorHandler } from "../utils/error";
import { apiEndpoints } from "./Utils";

const userService = {
  createUser: async (user: CreateUserUpload): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        apiEndpoints.CREATE_USER,
        user
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create shipper");
    }
  },
  verfiyToken: async (token: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        apiEndpoints.VERFIY_TOKEN + "/" + token
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create shipper");
    }
  },
  verfiyPasswordToken: async (token: string, email: string): Promise<any> => {
    try {
      const codedEmail = encodeURIComponent(email);
      const response: AxiosResponse<any> = await axios.get(
        apiEndpoints.VERIFY_PASSWORD_TOKEN +
          `?email=${codedEmail}&token=${token}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create shipper");
    }
  },
  updatePassword: async (user: {
    token: string;
    email: string;
    password: string;
  }): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        apiEndpoints.UPDATE_PASSWORD,
        user
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create shipper");
    }
  },
  changePassword: async (user: {
    email: string;
    oldPassword: string;
    newPassword: string;
    token: string;
  }): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        apiEndpoints.CHANGE_PASSWORD,
        user
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to create shipper");
    }
  },
  sendMailToUser: async (user: {
    shipperId: number;
    email: string;
    orgId: number;
  }): Promise<any> => {
    try {
      return axios.post(apiEndpoints.GENERATE_TOKEN, user);
      // return response.data;
    } catch (error) {
      console.log({ errorsdsds: error });
      errorHandler(error);
    }
  },
  forgotPassword: async (user: { email: string }): Promise<any> => {
    try {
      return axios.post(apiEndpoints.FORGOT_PASSWORD, user);
    } catch (error) {
      console.log({ error });
      errorHandler(error);
    }
  },
};

export const updateUser = async (
  id: number,
  user: UpdateUserAdmin
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put(
      apiEndpoints.UPDATE_USER + "/" + id,
      user,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update user");
  }
};

export const deleteUser = async (id: number): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.delete(
      apiEndpoints.DELETE_USER + "/" + id,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to update user");
  }
};

export default userService;
