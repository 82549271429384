import { toast } from "react-toastify";

export function errorHandler(error : any) {
    if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        toast.error(`${error?.response?.data.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            
            });
        return error.response.data;

    }
    else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        return error.request;
    }
    else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        toast.error(`${error?.response?.data.message}`, {
            position: "top-center",
            autoClose: 10,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            });
        return error.message;
    }
}