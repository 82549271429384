import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
  ListGroup,
  TooltipProps,
} from "react-bootstrap";
import ClientNavbar from "../../../foundation/Navbar";
import envConfig from "../../../../utils/envConfig";
import { toast } from "react-toastify";
import { Button as Bootstrapbutton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { JSX } from "react/jsx-runtime";

const LUPA_BASE_URL = envConfig.url.API_URL;

interface WebhookData {
  email: string;
  id: number;
  awbNumber: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  isDeleted: boolean;
}

interface Mail {
  id: number;
  email: string;
}

const TestWebhook: React.FC = () => {
  const [data, setData] = useState<WebhookData[]>([]);
  const [logData, setLogData] = useState<WebhookData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [awb, setAwb] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [webhookdata, setwebhookData] = useState<any[]>([]);
  const [listMail, setListMail] = useState<Mail[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>(""); // Email entered or selected
  const [filteredEmails, setFilteredEmails] = useState<Mail[]>([]); // Filtered emails based on input
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false); // Controls suggestion visibility
  const navigate = useNavigate();
  const handleOpenModal = async (awbNumber: string) => {
    handleViewWebhook(awbNumber);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
    <Tooltip id="button-tooltip" {...props}>
     Add database available numbers only, no duplicates allowed.
    </Tooltip>
  );
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get<WebhookData[]>(
          `${LUPA_BASE_URL}test-webhook`
        );
        const listMailResponse = await axios.get<Mail[]>(
          `${LUPA_BASE_URL}test-webhook/list/users`
        );
        setLogData(response.data)
        setListMail(listMailResponse.data);
      } catch (err) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewWebhook = useCallback(async (awbNumber: string) => {
    try {
      const apiKey = localStorage.getItem("userApiLupaJwtToken");
      if (!apiKey) {
        throw new Error("API key not found in local storage");
      }
      const response = await axios.get(
        `${LUPA_BASE_URL}test-webhook/last/${awbNumber}?apiKey=${apiKey}`
      );
      setwebhookData(response.data);
    } catch (err) {
      alert("Error fetching webhook data");
    }
  }, []);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSelectedEmail(inputValue);

    // Filter emails if input has 3 or more characters
    if (inputValue.length >= 3) {
      const filtered = listMail.filter((mail) =>
        mail.email.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredEmails(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false); // Hide suggestions if input is less than 3 characters
    }
  };

  const handleEmailSelect = (email: string) => {
    console.log("email",email)
    setSelectedEmail(email); // Set selected email to input field
    setShowSuggestions(false); // Hide suggestions after selection
  };

  const submitForm = async () => {
    if (!selectedEmail) {
      toast.error("Please select or enter an email.");
      return;
    }
    if (data.length === 0) {
      toast.error("Please enter an AWB number.");
      return;
    }

    setLoading(true);
    try {
      const apiKey = localStorage.getItem("userApiLupaJwtToken");
      if (!apiKey) {
        throw new Error("API key not found in local storage");
      }

      const requestBody = {
        emailId: selectedEmail,
        awbNumbers: data.map((item) => item.awbNumber),
      };

      console.log("Submitting the following request body:", requestBody);

      const response = await fetch(
        `${LUPA_BASE_URL}test-webhook?apiKey=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 201) {
        toast.success("Success: AWB added successfully");
      } else {
        const errorMessage = await response.text();
        toast.error(`Error: ${errorMessage}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error: Unable to process the request");
    } finally {
      setLoading(false);
    }
  };

  const isPresent = async (awbNumber:any) =>{
    try {
      const apiKey = localStorage.getItem("userApiLupaJwtToken");
      if (!apiKey) {
        throw new Error("API key not found in local storage");
      }
      const response = await axios.get(
        `${LUPA_BASE_URL}test-webhook/check/${awbNumber}`
      );
      // const awbNumber = "1234567890"; // Example AWB number
      // const found = response.data.some((item) => item.awbNumber === awbNumber);
      console.log("response", response)
      console.log("response", response.data.isPresent)
      return response.data.isPresent;
    
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  }
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <ClientNavbar />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Webhook Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <pre>
              <code>{JSON.stringify(webhookdata, null, 2)}</code>
            </pre>
          </div>
        </Modal.Body>
      </Modal>

      <Container className="py-5">
      <Row className="my-2" style={{ alignItems: "flex-end" }}>
      <Col md={4}>
        <Form.Group controlId="emailInput">
          <Form.Label>Select or Enter Email:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            value={selectedEmail}
            onChange={handleEmailInputChange}
            onBlur={() => setTimeout(() => setShowSuggestions(false), 100)} // Delay to allow clicks
            onFocus={() => {
              if (selectedEmail.length >= 3) setShowSuggestions(true);
            }}
          />
          {showSuggestions && (
            <ListGroup className="position-absolute" style={{ zIndex: 1000 }}>
              {filteredEmails.map((mail) => (
                <ListGroup.Item
                  key={mail.id}
                  action
                  onClick={() => handleEmailSelect(mail.email)}
                >
                  {mail.email}
                </ListGroup.Item>
              ))}
              {filteredEmails.length === 0 && (
                <ListGroup.Item>No matches found</ListGroup.Item>
              )}
            </ListGroup>
          )}
        </Form.Group>
        </Col>
          </Row>
        
        <Form>
          <Row className="my-2" style={{ alignItems: "flex-end" }}>
          <Col md={4}>
            <Form.Label htmlFor="inputBox">
              Add New AWB
              <OverlayTrigger placement="right" overlay={renderTooltip}>
                <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  (info)
                </span>
              </OverlayTrigger>
              :
            </Form.Label>
            <Form.Control
              type="text"
              id="inputBox"
              name="inputBox"
              value={awb}
              onChange={(e) => setAwb(e.target.value)}
            />
          </Col>
            <Col>
            <Button
                variant="primary"
                onClick={async () => {
                  if (awb.trim()) {
                    const isFound = await isPresent(awb); // Check if AWB exists in the database
                    console.log("isFound", isFound);

                    // Check if AWB is present in the database
                    if (!isFound) {
                      toast.error("AWB Not Present in Database");
                      return;
                    }

                    // Check for duplicate AWB in the local state
                    const isDuplicate = data.some(item => item.awbNumber === awb);
                    if (isDuplicate) {
                      toast.error("Duplicate AWB: This AWB number has already been added.");
                      return;
                    }
                    
                    // Check if maximum number of AWBs has been reached
                    if (data.length >= 5) {
                      toast.error("Maximum number of AWBs reached (5).");
                      return;
                    }
                    // Add the new AWB to the state if it passes checks
                    setData((prevData) => [
                      ...prevData,
                      {
                        id: prevData.length + 1,
                        awbNumber: awb,
                        email: "",
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                        isActive: true,
                        isDeleted: false,
                      },
                    ]);

                    setAwb(""); // Clear the input field
                  } else {
                    toast.error("Please enter an AWB number."); // Notify if the input is empty
                  }
                }}
              >
                Add
              </Button>

            </Col>
          </Row>
          <Row>
            <Col>
              {data.map((item) => (
                <span key={item.id} className="badge bg-primary m-1">
                  {item.awbNumber}
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => {
                      setData((prevData) =>
                        prevData.filter((dataItem) => dataItem.id !== item.id)
                      );
                    }}
                  >
                    &times;
                  </Button>
                </span>
              ))}
            </Col>
          </Row>
        </Form>
        <Row className="my-2" style={{ alignItems: "flex-end" }}>
        <Col md={4}></Col>
        {/* <Col md={4}></Col> */}
        <Col md={4} className="ms-auto">
        </Col>
      </Row>
      </Container>
      <Container className="overflow-auto mt-4">
      <div className=" d-flex justify-content-center" >
      <Button style={{width: "max-content"}} variant="primary" onClick={submitForm}>
        Send Mail
      </Button>
      </div>
        <div className=" d-flex justify-content-end" >       
      <Bootstrapbutton
            variant="secondary"
            onClick={() => navigate("/webhook-data/test-webhook/log")}
          >
            logs
          </Bootstrapbutton>
          </div>
          <br/>
          <br/>
        <Card>
          <Table striped hover responsive>
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">S.No</th>
                <th scope="col" className="ps-1">AWB Number</th>
                <th scope="col" className="ps-1">Lastest Webhook</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.awbNumber}</td>
                  <td>
                    <button onClick={() => handleOpenModal(item.awbNumber)}>
                      Lastest Webhook
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
      
  
    </div>
  );
};

export default TestWebhook;
