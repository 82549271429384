import moment from "moment";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { CreateInvoice } from "../../../models/Customer";
import Input from "../../common/Input";
import { toast } from "react-toastify";

type Props = {
  show: boolean;
  onSubmit: (args: any) => void;
  onHide: () => void;
};

const label = {
  from: "From Date",
  to: "To Date",
  dueDate: "Due Date",
  awbCount: "AWB Count",
  amount: "Amount (in Rupees)",
};

function AddInvoice({ show, onSubmit, onHide }: Props) {
  const [state, setState] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    dueDate: moment().add(1, "month").format("YYYY-MM-DD"),
    awbCount: null,
    amount: null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]:
        e.target.name === "from" ||
        e.target.name === "to" ||
        e.target.name === "dueDate"
          ? e.target.value
          : e.target.name === "awbCount" || e.target.name === "amount"
          ? +e.target.value === 0
            ? null
            : +e.target.value
          : e.target.value,
    });
    console.log({ state });
  };

  const onSubmitClick = () => {
    console.log({ state });
    if (
      !state ||
      !state.from ||
      !state.to ||
      !state.dueDate ||
      !state.awbCount ||
      !state.amount ||
      isNaN(state.awbCount) ||
      isNaN(state.amount) ||
      new Date(state.from) > new Date(state.to)
    ) {
      toast.error("Invalid Invoice Data");
      return;
    }

    if (state.amount && state.awbCount) {
      console.log({ state });
      onSubmit(state);
      setState({
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        dueDate: moment().add(1, "month").format("YYYY-MM-DD"),
        awbCount: null,
        amount: null,
      });
    }
  };

  const generateProps = (name: string, type = "text") => ({
    label: (label as any)[name],
    type: type,
    value: (state as any)[name],
    name: name,
    onChange: handleChange,
    placeHolder: name === "date" ? "YYYY-MM-DD" : name.toLocaleUpperCase,
  });

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <Row>
            <Input
              {...generateProps("from", "date")}
              max={moment().format("YYYY-MM-DD")}
            />
            <Input
              {...generateProps("to", "date")}
              max={moment().format("YYYY-MM-DD")}
            />
          </Row>
          <Row>
            <Input {...generateProps("dueDate", "date")} />
          </Row>
          <Row>
            <Input {...generateProps("awbCount", "number")} />
          </Row>
          <Row>
            <Input {...generateProps("amount", "number")} />
          </Row>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={onSubmitClick}>
          Add Invoice
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddInvoice;
