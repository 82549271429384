import React from "react";
type Props = {
  children: React.ReactNode;
  justifyContent?: string;
};

function RowAlightCenter({ children, justifyContent = "center" }: Props) {
  const style = {
    display: "flex",
    justifyContent,
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  return <div style={style}>{children}</div>;
}

export default RowAlightCenter;
