import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { Request } from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
} from "material-react-table";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import { getUnsupportedAirlines } from "../../../services/customer";
import { unsuportedAirlines } from "../../../models/Common";
import ClientNavbar from "../../foundation/Navbar";

const UnsupportedAirlines: React.FC = () => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["customer"],
    () => getUnsupportedAirlines(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const handleExportToExcel = (rows: MRT_Row<unsuportedAirlines>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "unsupportedAirline");

    XLSX.writeFile(workbook, "unsupportedAirline.xlsx");
  };

  const handleExportRows = (rows: MRT_Row<unsuportedAirlines>[]) => {
    const doc = new jsPDF();

    const tableData: any[] = rows.map((row) => [
      row.original.awbNumber,
      row.original.airlineCode,
      row.original.email,
      row.original.firstName,
      row.original.lastName,
      row.original.billNo,
      row.original.id,
    ]);

    const tableHeaders: string[] = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      margin: { top: 40 },
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(26);
        doc.setTextColor(40);
        // doc.text(`AWBs Count: ${rows.length}`, data.settings.margin.left, 22);

        // Footer
        var str =
          "Page " + (doc as any).internal.getCurrentPageInfo().pageNumber;
        console.log((doc as any).internal.getCurrentPageInfo());
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.save("unsupportedAirline.pdf");
  };

  const columns = useMemo<MRT_ColumnDef<unsuportedAirlines>[]>(
    () => [
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",
      },

      {
        header: "Airline Code",
        filterFn: "includesString",
        accessorFn: (row) => row?.airlineCode || "",
      },
      {
        header: "Email",
        accessorFn: (row) => row?.email || "",
      },

      {
        header: "Name",
        accessorFn: (row) => row?.firstName + " " + row?.lastName || "",
      },
      {
        header: "CreatedAt",
        accessorFn: (row) => row?.createdAt || "",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableRowSelection: true,
    // getRowId: (row) => row.id,
    initialState: { showColumnFilters: true, density: "compact" },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
      </Box>
    ),
  });

  return (
    <>
      <ClientNavbar />
      <MaterialReactTable table={table} />;
    </>
  );
};
export default UnsupportedAirlines;
