import React from "react";
import { Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import { Receipt } from "../../../models/Customer";

type Props = {
  receipt: Receipt | null;
  show: boolean;
  onHide: () => void;
};

function ViewReceipt({ receipt, show, onHide }: Props) {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row className="m-0 p-0">
            <Col className="rounded border m-2">
              <Col>
                <h4>Receipt No.</h4>
              </Col>
              <Col>
                <h5>{receipt?.receiptId}</h5>
              </Col>
            </Col>
            <Col className="rounded border m-2">
              <Col>
                <h4>Receipt Date</h4>
              </Col>
              <Col>
                <h5>{moment(receipt?.createdAt).format("DD-MM-YYYY")}</h5>
              </Col>
            </Col>
            <Col className="rounded border m-2">
              <h4>Receipt Amount</h4>
              <h5>{receipt?.amount}</h5>
            </Col>
          </Row>
          {receipt?.invoice && receipt?.invoice.length > 0 ? (
            <Table striped bordered hover className="m-2 p-0">
              <thead>
                <tr>
                  <th className="px-4">Invoice No.</th>
                  <th>Invoice Date</th>
                  <th>Invoice Amount</th>
                  <th>Invoice Period</th>
                  <th>Invoice DueDate</th>
                </tr>
              </thead>
              <tbody>
                {receipt?.invoice?.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{invoice.invoiceId}</td>
                    <td>{moment(invoice.createdAt).format("DD-MM-YYYY")}</td>
                    <td>{invoice.amount}</td>
                    <td>
                      {moment(invoice.from).format("DD-MM-YYYY")} -{" "}
                      {moment(invoice.to).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {invoice.dueDate
                        ? moment(invoice.dueDate).format("DD-MM-YYYY")
                        : "Not Set"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h3>No Invoices Linked</h3>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}

export default ViewReceipt;
