import React, { Children } from "react";

type Props = {
  children: React.ReactNode;
};

function BodyContainer({ children }: Props): JSX.Element {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "85vh",
      width: "100vw",
    },
    text: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
  };

  return <div style={styles.container}>{children}</div>;
}

export default BodyContainer;
