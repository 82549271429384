import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import ClientNavbar from "../../../foundation/Navbar";
import envConfig from "../../../../utils/envConfig";
import { toast } from "react-toastify";

const LUPA_BASE_URL = envConfig.url.API_URL;

interface WebhookData {
  serviceType: string;
  id: number;
  webhookJson: string;
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  isDeleted: boolean;
}

const ViewWebhook: React.FC = () => {
  const [data, setData] = useState<WebhookData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [awb, setAwb] = useState<string>("");
  const [isValidAwb, setIsValidAwb] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const isBulkOperation = false;
  const [showModal, setShowModal] = useState(false);
  const [webhookdata, setwebhookData] = useState<string>("");

  const handleOpenModal = async (awbNumber: string) => {
    // await handleViewWebhook(item.awbNumber);
    setwebhookData(awbNumber);
    // handleViewWebhook(json);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get<WebhookData[]>(
          `${LUPA_BASE_URL}webhookdata`
        );
        setData(response.data);
      } catch (err) {
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //   const handleViewWebhook = useCallback(async (awbNumber: string) => {
  //     try {

  //       setwebhookData(response.data);
  //     } catch (err) {
  //       alert("Error fetching webhook data");
  //     }
  //   }, []);

  //   const validateAwb = (awb: string): boolean => {
  //     const awbRegex = /^\d{3}-\d{8}$/;
  //     return awbRegex.test(awb);
  //   };

  const submitForm = async () => {
    setLoading(true);
    try {
      //   if (!validateAwb(awb)) {
      //     setIsValidAwb(false);
      //     return;
      //   }
      //   setIsValidAwb(true);
      const apiKey = localStorage.getItem("userApiLupaJwtToken");
      if (!apiKey) {
        throw new Error("API key not found in local storage");
      }
      const response = await fetch(
        `${LUPA_BASE_URL}test-webhook?apiKey=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ awbNumber: awb }),
        }
      );

      const data = await response.json();
      if (data?.awbNumber) {
        toast.success("AWB added successfully");
      }
      if (data?.message) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if ((error as any).statuscode === 400) {
        toast.error("Awb number already exists");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <ClientNavbar />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Webhook Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {data.map((item, index) => ( */}
          <div>
           <pre>
              <code>{webhookdata}</code>
           </pre>
          </div>
          {/* ))} */}
        </Modal.Body>
        {/* {isLoading && <Spinner animation="border" />} */}
      </Modal>

      <Container className="py-5"></Container>
      <Container className="overflow-auto mt-4" style={{ maxHeight: "400px" }}>
        <Card>
          <Table striped hover responsive>
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  S.No
                </th>
                <th scope="col" className="ps-1">
                  Service
                </th>

                <th scope="col" className="ps-1">
                  Created At
                </th>
                <th scope="col" className="ps-1">
                  Updated At
                </th>
                <th scope="col" className="ps-1">
                  Webhook Data view
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.serviceType}</td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>{new Date(item.updatedAt).toLocaleString()}</td>
                  <td>
                    <button onClick={() => handleOpenModal(item.webhookJson)}>
                      Webhook
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </div>
  );
};

export default ViewWebhook;
