import React from "react";
import BodyContainer from "../foundation/BodyContainer";
import { Spinner } from "react-bootstrap";

function BodyLoadingSpinner() {
  return (
    <BodyContainer>
      <Spinner animation="border" variant="primary" />
    </BodyContainer>
  );
}

export default BodyLoadingSpinner;
