import React, { useState } from 'react';
import { Modal, Button, Spinner, Form } from 'react-bootstrap';
import { WebhookUiService } from '../../../services/webhook-ui';
import * as XLSX from 'xlsx'

interface DownloadDataModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const DownloadDataModal: React.FC<DownloadDataModalProps> = ({ isOpen, onClose }) => {
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isValidFrom, setIsValidFrom] = useState(true);
    const [isValidTo, setIsValidTo] = useState(true);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        if (!isValidFrom || !isValidTo) {
            setIsLoading(false);
            return;
        }

        // Call backend API with the selected time range
        try {
            const rawData = await WebhookUiService.getAwbRawBetweenTime(from, to);

            // Convert data to CSV format
            const csvData = `${Object.keys(rawData[0]).join(',')}\n${rawData.map((obj: any) => Object.values(obj).join(',')).join('\n')}`;

            // Trigger file download
            downloadFile(csvData, 'webhook_data.csv');
        } catch (error) {
            setError('Failed to fetch data');
        } finally {
            setIsLoading(false);
            // Reset the fields
            onClose();
            setFrom('');
            setTo('');
        }
    };

    const downloadFile = (data: string, filename: string) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };


    const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const isValid = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(input) || input === '';
        setIsValidFrom(isValid);
        setFrom(input);
    };

    const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const isValid = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(input) || input === '';
        setIsValidTo(isValid);
        setTo(input);
    };

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Time Range</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label htmlFor="from">From</Form.Label>
                        <Form.Control
                            id="from"
                            type="text"
                            placeholder="YYYY-MM-DD HH:MM"
                            value={from}
                            onChange={handleFromChange}
                            isInvalid={!isValidFrom}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid date and time in the format YYYY-MM-DD HH:MM.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="to">To</Form.Label>
                        <Form.Control
                            id="to"
                            type="text"
                            placeholder="YYYY-MM-DD HH:MM"
                            value={to}
                            onChange={handleToChange}
                            isInvalid={!isValidTo}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid date and time in the format YYYY-MM-DD HH:MM.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Button variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default DownloadDataModal;
