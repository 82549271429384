import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import envConfig from "../../../utils/envConfig";
import ClientNavbar from "../../foundation/Navbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsPage from "./settings";

const SCRAP_BASE_URL = envConfig.url.SCRAP_URL;

interface FormData {
  HrsToKeepWrongAWBInDB: string;
  DaysToStopScrapingAfterLastFlightDate: string;
  DaysToStopScrapingAfterNoChange: string;
}

const ScrapingSettings: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    HrsToKeepWrongAWBInDB: "",
    DaysToStopScrapingAfterLastFlightDate: "",
    DaysToStopScrapingAfterNoChange: "",
  });
  const [initialData, setInitialData] = useState<FormData>({
    HrsToKeepWrongAWBInDB: "",
    DaysToStopScrapingAfterLastFlightDate: "",
    DaysToStopScrapingAfterNoChange: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${SCRAP_BASE_URL}api/Config/GetConfigurations`
        );
        const configData = response.data;

        const updatedFormData = configData.reduce((acc: any, item: any) => {
          acc[item.field] = item.value;
          return acc;
        }, {});

        setFormData(updatedFormData);
        setInitialData(updatedFormData);
      } catch (error) {
        console.error("Error fetching configuration data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      toast.error("Please fill out the form correctly.");
      return;
    }

    setLoading(true);

    const changedFields = Object.keys(formData).filter(
      (key) =>
        formData[key as keyof FormData] !== initialData[key as keyof FormData]
    );

    try {
      await Promise.all(
        changedFields.map(async (key) => {
          await axios.get(
            `${SCRAP_BASE_URL}api/Config/SetConfig?config=${key}&value=${
              formData[key as keyof FormData]
            }`
          );
        })
      );
      console.log("Form Data Submitted and Updated:", formData);
      toast.success("Settings updated successfully");
    } catch (error) {
      console.error("Error updating configuration data:", error);
      toast.error("Error updating configuration data");
    }

    setLoading(false);
  };

  return (
    <>
      <ClientNavbar />

      <div className="container mt-5">
        <h2 className="mb-4">Scraping Settings</h2>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <form onSubmit={handleSubmit} noValidate>
              <div className="mb-3">
                <label htmlFor="HrsToKeepWrongAWBInDB" className="form-label">
                  Incorrect AWB Time Limit:
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="HrsToKeepWrongAWBInDB"
                    name="HrsToKeepWrongAWBInDB"
                    value={formData.HrsToKeepWrongAWBInDB}
                    onChange={handleChange}
                    required
                    min="1"
                    step="1"
                  />
                  <span className="input-group-text">hours</span>
                  <div className="invalid-feedback">
                    Please enter a valid number of hours.
                  </div>
                </div>
              </div>
              <h4>Tracking Time Limit:</h4>

              <div className="mb-3">
                <label
                  htmlFor="DaysToStopScrapingAfterLastFlightDate"
                  className="form-label"
                >
                  1.Last Flight Date:
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="DaysToStopScrapingAfterLastFlightDate"
                    name="DaysToStopScrapingAfterLastFlightDate"
                    value={formData.DaysToStopScrapingAfterLastFlightDate}
                    onChange={handleChange}
                    required
                    min="1"
                    step="1"
                  />
                  <span className="input-group-text">days</span>
                  <div className="invalid-feedback">
                    Please enter a valid number of days.
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="DaysToStopScrapingAfterNoChange"
                  className="form-label"
                >
                  2.No Change (Airline page):
                </label>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="DaysToStopScrapingAfterNoChange"
                    name="DaysToStopScrapingAfterNoChange"
                    value={formData.DaysToStopScrapingAfterNoChange}
                    onChange={handleChange}
                    required
                    min="1"
                    step="1"
                  />
                  <span className="input-group-text">days</span>
                  <div className="invalid-feedback">
                    Please enter a valid number of days.
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
      <div className="container mt-5">
        <h1>Intemo Settings</h1>
        <SettingsPage />
      </div>
    </>
  );
};

export default ScrapingSettings;
