import { FC, useEffect, useState } from "react";
import { lupaService } from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import { AxiosResponse } from "axios";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import { LocalDateTime } from "@js-joda/core";
import { StringLiteral } from "typescript";

interface ManualWebhookLogTable {
    awbNumber: string;
    status: string;
    user: User;
    createdAt: LocalDateTime;
    formattedTime: string;
}
interface User {
    id: number;
    email: string;
}

const ManualWebhookLog: FC = () => {


    const [uploadHistoryData, setUploadHistoryData] = useState<ManualWebhookLogTable[]>([]);
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        fetchUploadHistory(); // Fetch upload history data when the component mounts
    }, []);

    const fetchUploadHistory = async () => {
        try {
            const response: AxiosResponse<ManualWebhookLogTable[]> = await lupaService.getManualWebhookLog();
            if (response.data && Array.isArray(response.data)) {
                setUploadHistoryData(response.data);
                console.log(response.data)
            }
        } catch (error) {
            console.error("Error fetching upload history:", error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };



    return (
        <>
            <ClientNavbar />

            <Container className="overflow-auto mt-4" >
                <Card>

                    <Table striped hover responsive>
                        <thead className="text-start table-header">
                            <tr>
                                <th scope="col" className="ps-1">
                                    S.No
                                </th>
                                <th scope="col" className="ps-1">
                                    User
                                </th>
                                <th scope="col" className="ps-1">
                                    AWB Number
                                </th>
                                <th scope="col" className="ps-1">
                                    Status
                                </th>
                                <th scope="col" className="ps-1">
                                    Webhook Sent Time
                                </th>




                            </tr>
                        </thead>
                        <tbody>
                            {uploadHistoryData.map((item, index) => (
                                <tr key={index}> {/* Add a unique key for each row */}
                                    <td>{index + 1}</td>
                                    <td>{item.user.email}</td>
                                    <td>{item.awbNumber}</td>
                                    <td>{item.status}</td>
                                    <td>{item.formattedTime}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Container>
        </>

    )

}

export default ManualWebhookLog;
