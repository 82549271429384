import { FC } from "react";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import { TrackingSummary } from "../../../models/TrackingSummary";

export interface TrackingDetailsProps {
  trackingId: string;
  awbDetails: TrackingSummary | null;
}

export const TrackingDetails: FC<TrackingDetailsProps> = (props) => {
  console.log({ props });
  const detailsBlock = (
    <Container className="mx-0">
      <Row className="col-12 mx-1 my-1">
        <Col className="col-6">
          <Col className="col-4">
            <h6>AWB</h6>
          </Col>
          <Col className="col-8">
            <span>{props.awbDetails?.awbNumber}</span>
          </Col>
        </Col>
        <Col className="col-6">
          <Col className="col-4">
            <h6>Weight (Kg)</h6>
          </Col>
          <Col className="col-8">
            <span>{props.awbDetails?.weight}</span>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Col className="col-6">
          <Col className="col-4">
            <h6>Flight</h6>
          </Col>
          <Col className="col-8">
            <span>
              {props.awbDetails?.origin} - {props.awbDetails?.destination}
            </span>
          </Col>
        </Col>
        <Col className="col-6">
          <Col className="col-4">
            <h6>No of Pieces</h6>
          </Col>
          <Col className="col-8">
            <span>{props.awbDetails?.pieces}</span>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Col className="col-6">
          <Col className="col-4">
            <h6>Last Activity</h6>
          </Col>
          <Col className="col-8">
            <Badge
              bg={
                props.awbDetails?.trackingStatus
                  .toLowerCase()
                  .includes("delivered")
                  ? "success"
                  : "secondary"
              }
              pill
            >
              {props.awbDetails?.trackingStatus}
            </Badge>
          </Col>
        </Col>
      </Row>
    </Container>
  );

  const historyBlock = (
    <Container className="mx-0">
      <Row className="col-12 ms-1 mx-1 my-1">
        <Col className="col-6"></Col>
        <Col className="col-6 pe-0">
          <Col className="col-4"></Col>
          <Col className="col-8 text-end text-muted ms-auto">
            <small>
              {props.awbDetails?.events?.length} Tracking Activity Found
            </small>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Container className="overflow-auto" style={{ height: 250 }}>
          <Table className="table table-fixed mt-2 border border-2">
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  Origin
                </th>
                <th scope="col" className="ps-1">
                  Destination
                </th>
                <th scope="col" className="ps-1">
                  Milestone
                </th>
                <th scope="col" className="ps-1">
                  Pcs
                </th>
                <th scope="col" className="ps-1">
                  Flight
                </th>
                <th scope="col" className="ps-1">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {props.awbDetails?.events?.map((h) => {
                return (
                  <tr>
                    <td className="ps-1">{h.origin}</td>
                    <td className="ps-1">{h.destination}</td>
                    <td className="ps-1">{h.milestone}</td>
                    <td className="ps-1">{h.pieces}</td>
                    <td className="ps-1">{h.flightNumber}</td>
                    <td className="ps-1">{h.date}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Row>
    </Container>
  );

  const dialog = (
    <Container className="mx-0">
      {detailsBlock}
      {historyBlock}
    </Container>
  );

  return dialog;
};
