import axios from "axios";
import { apiEndpoints } from "./Utils";
// import { setupInterceptorsTo } from '../utils/api-interceptor';

// setupInterceptorsTo(axios)
axios.defaults.withCredentials = true;

const authService = {
  login: (payload: { email: string; password: string }): Promise<any> => {
    try {
      return axios.post(apiEndpoints.AUTH_LOGIN, payload, {
        withCredentials: true,
      });
    } catch (error) {
      throw new Error("Failed to login in");
    }
  },
  logout: (): Promise<any> => {
    try {
      return axios.get(apiEndpoints.AUTH_LOGOUT);
    } catch (error) {
      throw new Error("Failed to login in");
    }
  },
};

export default authService;
