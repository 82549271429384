import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";

import UserApiNavbar from "../../foundation/Navbar";
import { useEffect, useState } from "react";
import userService from "../../../services/user";

function AdminProfile() {
  var apiKey = localStorage.getItem("userApiLupaJwtToken");
  const email: any = localStorage.getItem("userApiemail");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [verify, setVerify] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);
  const [keygenerated, setKeyGenerated] = useState("");

  function handleOldPassword(value: any) {
    setOldPassword(value);
  }
  function handleNewPassword(value: any) {
    setNewPassword(value);
  }
  function handleRePassword(value: any) {
    setRePassword(value);
  }

  async function handlePasswordUpdate() {
    if (oldPassword && newPassword && rePassword) {
      let token: any = apiKey;
      await userService
        .changePassword({ email, oldPassword, newPassword, token })
        ?.then((response) => {
          if (response.isSuccess) {
            setNewPassword("");
            setOldPassword("");
            setRePassword("");
            return toastTypes.success(response.message);
          } else {
            return toastTypes.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (newPassword !== rePassword) {
      return toastTypes.error(
        "Please Check New password And Confirm Password Are Same"
      );
    } else if (newPassword === oldPassword) {
      return toastTypes.error("Old And new Password Are same");
    } else {
      return toastTypes.error("Please fill the required field");
    }
  }

  return (
    <>
      <UserApiNavbar />
      <Container>
        <Card className="my-3">
          <Card.Header>Profile</Card.Header>
          <Card.Body>
            <Row className="justify-content-evenly">
              <Col>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput4"
                  >
                    <Form.Label>Email</Form.Label>
                    <p>{email}</p>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Change Password</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput6"
                    >
                      <Form.Label>Old Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showOldPassword ? "text" : "password"}
                          aria-describedby="basic-addon1"
                          placeholder="Old Password"
                          value={oldPassword}
                          onChange={(event) =>
                            handleOldPassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon1"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          {showOldPassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput7"
                    >
                      <Form.Label>New Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showNewPassword ? "text" : "password"}
                          aria-describedby="basic-addon2"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(event) =>
                            handleNewPassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon2"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput8"
                    >
                      <Form.Label>Confirm Password </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showRePassword ? "text" : "password"}
                          aria-describedby="basic-addon3"
                          placeholder="Confirm Password"
                          value={rePassword}
                          onChange={(event) =>
                            handleRePassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon3"
                          onClick={() => setShowRePassword(!showRePassword)}
                        >
                          {showRePassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Button
                className="text-right"
                variant="success"
                onClick={handlePasswordUpdate}
              >
                Update
              </Button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
}
export default AdminProfile;
