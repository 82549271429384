import axios from "axios";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";

function ApiEval() {
  const [departure, setDeparture] = useState<string>("");
  const [arrival, setArrival] = useState<string>("");

  const [avStackFrom, setAvStackFrom] = useState<string>("");
  const [avStackTo, setAvStackTo] = useState<string>("");
  const [avStackResult, setAvStackResult] = useState<any>([]);
  const [apiType, setApiType] = useState<string>("");

  const [flightNumber, setFlightNumber] = useState<string>("");
  const [carrierCode, setCarrierCode] = useState<string>("");

  const options = [
    { value: "schedule", label: "Schedule" },
    { value: "flight_info", label: "Flight Info" },
  ];
  async function searchAvStack() {
    const http = axios.create({
      baseURL: "http://localhost:3000/oag-api",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    var conCatDate;
    var sim;
    if (apiType === "schedule") {
      conCatDate = avStackFrom + "/" + avStackTo;
      sim = arrival;
    } else {
      conCatDate = avStackFrom;
      sim = carrierCode;
    }

    const url: string =
      "?type=" +
      apiType +
      "&date=" +
      conCatDate +
      "&dep_iata=" +
      departure +
      "&arr_iata=" +
      sim;

    const r = await http.get<any>(url);
    setAvStackResult(r.data.data);
  }
  function onTypeChange(type: any) {
    //console.log(type.value)
    setApiType(type.value);
  }
  return (
    <Container>
      <Row>
        <Col className="float-start" />

        <Col className="float-end">
          <Select
            options={options}
            onChange={(e) => {
              onTypeChange(e);
            }}
          />
        </Col>
      </Row>

      <Row>
        {apiType === "schedule" && (
          <Form>
            <Form.Group className="row my-2">
              <Form.Group className="col-2">
                <Form.Label className="h6">Departure</Form.Label>
                <Form.Control
                  onChange={(e) => setDeparture(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-2">
                <Form.Label className="h6">Arrival</Form.Label>
                <Form.Control
                  onChange={(e) => setArrival(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-3">
                <Form.Label className="h6">From</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setAvStackFrom(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-3">
                <Form.Label className="h6">To</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setAvStackTo(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-2">
                <Form.Label></Form.Label>
                <Button
                  className="float-end mt-4"
                  variant="primary"
                  onClick={searchAvStack}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
        )}
        {apiType === "flight_info" && (
          <Form>
            <Form.Group className="row my-2">
              {/* <Form.Group className="col-3">
                        <Form.Label className="h6">Carrier Code</Form.Label>
                        <Form.Control onChange={(e) => setCarrierCode(e.target.value)}></Form.Control>
                    </Form.Group>   */}
              <Form.Group className="col-2">
                <Form.Label className="h6">Flight No</Form.Label>
                <Form.Control
                  onChange={(e) => setFlightNumber(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="col-2">
                <Form.Label className="h6">Carrier Code</Form.Label>
                <Form.Control
                  onChange={(e) => setCarrierCode(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-3">
                <Form.Label className="h6">Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setAvStackFrom(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="col-2">
                <Form.Label></Form.Label>
                <Button
                  className="float-end mt-4"
                  variant="primary"
                  onClick={searchAvStack}
                >
                  Submit
                </Button>
              </Form.Group>
            </Form.Group>
          </Form>
        )}
      </Row>
      <Row className="px-1">
        {apiType === "schedule" ? (
          <Table className="table-hover border-1 mx-1">
            <thead>
              <tr>
                <th>#</th>
                <th>Airline</th>
                <th>Flight</th>
                {/* <th>Departure</th>
                        <th>Arrival</th>
                        <th>Status</th> */}
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {avStackResult.map((r: any, index: any) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{r.leg1.carrierCode.iata}</td>
                    <td>{r.leg1.flightNumber}</td>
                    <td>{r.departure.cityCode}</td>
                    <td>{r.arrival.cityCode}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Table className="table-hover border-1 mx-1">
            <thead>
              <tr>
                <th>#</th>
                <th>Airline</th>
                <th>Flight</th>
                <th>From</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {avStackResult.map((r: any, index: any) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{r.leg1.carrierCode.iata}</td>
                    <td>{r.leg1.flightNumber}</td>
                    <td>{r.departure.cityCode}</td>
                    <td>{r.arrival.cityCode}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Row>
    </Container>
  );
}
export default ApiEval;
