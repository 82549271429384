import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import useRole from "../../../hooks/useRole";
import { PendingTrackingsAvailableForTheMonth } from "../../../models/TrackingSummary";
import { lupaService } from "../../../services/lupa";
import UserBilling from "../UserBilling/UserBilling";
import AdminBilling from "./AdminBilling";

const defaultPendingTrackingState = {
  totalLimitForTheMonth: 0,
  trackingsLeft: 0,
  trackingsUsed: 0,
};

function ClientBilling() {
  const { isAdminUser } = useRole();
  const [pendingTracking, setPendingTracking] =
    useState<PendingTrackingsAvailableForTheMonth>(defaultPendingTrackingState);

  const checkPendingTracking = () => {
    lupaService.pendingTrackingsAvailableForTheMonth()?.then((r) => {
      const data = r.data;
      console.log(data.result);
      setPendingTracking(data.result);
    });
  };

  useEffect(() => {
    !isAdminUser && checkPendingTracking();
  }, []);

  const APIUserContent = () => (
    <Card className="my-3">
      <Card.Header>Current Usage</Card.Header>
      <Card.Body>
        <Row className="justify-content-evenly">
          <Col>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label>Used Request</Form.Label>
                <p className="text-danger">{pendingTracking.trackingsUsed}</p>
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label>Remaining Request</Form.Label>
                <p className="text-primary">{pendingTracking.trackingsLeft}</p>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Container fluid className="m-0 p-0">
        {!isAdminUser ? <UserBilling /> : <AdminBilling />}
      </Container>
    </>
  );
}
export default ClientBilling;
