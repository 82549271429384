import React from "react";
import Base from "../../common/Base";

function AdminDashboard() {
  return (
    <Base>
      <></>
    </Base>
  );
}

export default AdminDashboard;
