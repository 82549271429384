import React, { FC, useState } from 'react';
import RequestTable from './Request';
import ResponseTable from './Response';
import ClientNavbar from '../../foundation/Navbar';
import { Button } from 'react-bootstrap';

const ETDETADisplay: FC = () => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };
    const renderTable = () => {
        if (selectedOption === 'request') {

            return (
                <RequestTable />
            )
        } else if (selectedOption === 'response') {
            return (
                <ResponseTable />
            )
        }
        return null;
    };
    return (
        <div>
            <ClientNavbar />
            <h5>ETD ETA Table Data</h5>
            <div style={{ display: 'flex', gap: '20px' }}>
                <Button onClick={() => handleOptionClick('request')}>Request Table</Button>
                <Button onClick={() => handleOptionClick('response')}>Response Table</Button>
            </div>
            <div>{renderTable()}</div>
        </div>
    );
};

export default ETDETADisplay;
