import React from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  children?: React.ReactNode;
  error?: string;
  width?: string | number;
}

function Input({
  label,
  error,
  width,
  id,
  children,
  ...rest
}: Props): JSX.Element {
  return (
    <div className="form-group mt-3" style={width ? { width: width } : {}}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        className={`mt-1 form-control ${error ? "is-invalid" : ""}`}
        id={id}
        {...rest}
      />
      {error && <div className="invalid-feedback">{error}</div>}
      {children}
    </div>
  );
}

export default Input;
