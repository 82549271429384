import axios from "axios";
import { apiEndpoints } from "./Utils";
import { GenericResponse } from "../models/Common";

export interface Response<T> {
  data: T;
  isSuccess: boolean;
  message: string;
  statusCode: number;
}

export const hardDeleteAndAddAWBBulk = async (
  awbNumbers: string[]
): Promise<Response<any>> => {
  try {
    const response = await axios.post(
      apiEndpoints.DELETE_AND_ADD_AWB_ON_SCRAP_BULK,
      { awbNumbers }
    );
    
    return {
      data: response.data,
      isSuccess: true,
      message: "AWB bulk delete and add successful",
      statusCode: response.status
    };
  } catch (error) {
    throw new Error("Failed to delete and add AWB in bulk");
  }
};
