import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  Spinner,
  Modal,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { lupaService } from "../../../../services/lupa";
import { toast } from "react-toastify";
import envConfig from "../../../../utils/envConfig";
const LUPA_BASE_URL = envConfig.url.API_URL;
interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}

const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <>
      {loading ? (
        <div className="position-relative">
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-white opacity-50"
            style={{ backdropFilter: "blur(10px)" }}
          />
          <Spinner
            animation="border"
            role="status"
            className="position-fixed top-50 start-50"
            style={{ zIndex: 9999 }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="spinner-overlay">{children}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

function AirlineViewDataPref() {
  const navigate = useNavigate();
  const [airlineData, setAirlineData] = useState<any[]>([]);
  const [dataSource, setDataSources] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedAirline, setSelectedAirline] = useState<any>(null);
  const [updatedOrderPrefs, setUpdatedOrderPrefs] = useState({
    order1: "",
    order2: "",
    order3: "",
  });

  const fetchAirline = () => {
    lupaService.getAvailableAirlinesDs()?.then((res) => {
      setAirlineData(res.data);
    });
    lupaService.getAvailableDataSources()?.then((res) => {
      setDataSources(res.data);
    });
  };

  useEffect(() => {
    fetchAirline();
  }, []);

  const handleModalOpen = (airline: any) => {
    setSelectedAirline(airline);
    setUpdatedOrderPrefs({
      order1:
        airline.dataCompareDataSourcePrefs?.find(
          (pref: any) => pref.priority === 1
        )?.dataSource?.id || "",
      order2:
        airline.dataCompareDataSourcePrefs?.find(
          (pref: any) => pref.priority === 2
        )?.dataSource?.id || "",
      order3:
        airline.dataCompareDataSourcePrefs?.find(
          (pref: any) => pref.priority === 3
        )?.dataSource?.id || "",
    });
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(false);

  const handleInputChange = (e: any, order: string) => {
    setUpdatedOrderPrefs({
      ...updatedOrderPrefs,
      [order]: e.target.value,
    });
  };

  const saveOrderPreferences = async () => {
    const { order1, order2, order3 } = updatedOrderPrefs;
    const selectedOrders = [order1, order2, order3];

    // Filter out empty or whitespace entries
    const filteredOrders = selectedOrders.filter(
      (order) => String(order).trim() !== ""
    );

    // Check for duplicates
    const hasDuplicate = new Set(filteredOrders).size !== filteredOrders.length;

    if (hasDuplicate) {
      alert("Please select different data sources for each order.");
      return;
    }

    try {
      const response = await fetch(`${LUPA_BASE_URL}dataCompare/update/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          airlineCode: selectedAirline.airlineCode,
          order1: Number(order1),
          order2: Number(order2),
          order3: Number(order3),
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update preferences");
      }
      const data = await response.json();
      console.log(data);
      toast.success("Added Successfully");
      handleModalClose();
      fetchAirline(); // Refresh the airline data after update
    } catch (error) {
      toast.error("Failed to update preferences");
      console.error("Error updating preferences:", error);
    }
  };

  return (
    <>
      <Loader loading={loader}>
        <Container className="py-5">
          <Table striped bordered hover size="sm" className="shadow rounded">
            <thead className="rounded">
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Name</th>
                <th className="text-center">Airline Code</th>
                <th className="text-center">Order1</th>
                <th className="text-center">Order2</th>
                <th className="text-center">Order3</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {airlineData?.map((airline: any, index: number) => {
                const order1 = airline.dataCompareDataSourcePrefs?.find(
                  (pref: any) => pref.priority === 1
                );
                const order2 = airline.dataCompareDataSourcePrefs?.find(
                  (pref: any) => pref.priority === 2
                );
                const order3 = airline.dataCompareDataSourcePrefs?.find(
                  (pref: any) => pref.priority === 3
                );

                return (
                  <tr key={index}>
                    <td className="text-center" valign="middle">
                      {index + 1}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.name}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.airlineCode}
                    </td>
                    <td className="text-center" valign="middle">
                      {order1 ? order1.dataSource.name : "-"}
                    </td>
                    <td className="text-center" valign="middle">
                      {order2 ? order2.dataSource.name : "-"}
                    </td>
                    <td className="text-center" valign="middle">
                      {order3 ? order3.dataSource.name : "-"}
                    </td>
                    <td className="text-center" valign="middle">
                      <Button
                        size="sm"
                        onClick={() => handleModalOpen(airline)}
                      >
                          <i className="bi bi-pencil-square"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>

        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Order Preferences</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Order 1</Form.Label>
                <Form.Control
                  as="select"
                  value={updatedOrderPrefs.order1}
                  onChange={(e) => handleInputChange(e, "order1")}
                >
                  <option value="">Please select an option</option>
                  {dataSource.map((source) => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order 2</Form.Label>
                <Form.Control
                  as="select"
                  value={updatedOrderPrefs.order2}
                  onChange={(e) => handleInputChange(e, "order2")}
                >
                  <option value="">Please select an option</option>
                  {dataSource.map((source) => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Order 3</Form.Label>
                <Form.Control
                  as="select"
                  value={updatedOrderPrefs.order3}
                  onChange={(e) => handleInputChange(e, "order3")}
                >
                  <option value="">Please select an option</option>
                  {dataSource.map((source) => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={saveOrderPreferences}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Loader>
    </>
  );
}

export default AirlineViewDataPref;
