import * as Yup from "yup";

export const createUserValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company: Yup.string().required("Company is required"),
  role: Yup.string().required("Role is required"),
  monthlyVolume: Yup.number().required("Monthly Volume is required"),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  role: "",
  monthlyVolume: undefined,
};
