import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
  Badge,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuth";
import { lupaService } from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import {
  AirlineDefault,
  AirlineMilestone,
  AvailableAirline,
  StandardMilestoneDetails,
} from "./Airline.interface";
import TableRows from "./TableRows";
import { toastTypes } from "../../../utils/toastTypes";
import WebhookUiService from "../../../services/webhook-ui";
import Scraping from "../clientadmin/Scraping";
import { toast } from "react-toastify";

interface RowData {
  dataSource: string;
  sampleDateFormat: string;
  dateFormat: string;
  dateFormatCode: number;

  id: number;
  airlineCode: string | undefined | null;
  milestoneName: string;
  isStandard: boolean;
  standardMilestoneId: number | null;
  type: "DELETED" | "ADDED" | "UPDATED" | "NONE";
}
interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}
// Define types
interface DataSource {
  id: number;
  name: string;
  isActive: boolean;
  deleted: boolean;
}

interface DateFormat {
  id: number;
  dateFormatCode: string;
}

interface Preference {
  preference: number;
  dataSourceId: string;
  dateFormatTypesId: string;
}
const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <>
      {loading ? (
        <div className="position-relative">
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-white opacity-50"
            style={{ backdropFilter: "blur(10px)" }}
          />
          <Spinner
            animation="border"
            role="status"
            className="position-fixed top-50 start-50"
            style={{ zIndex: 9999 }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="spinner-overlay">{children}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
function Airline() {
  const navigate = useNavigate();
  const [airlineData, setAirlineData] = useState<AvailableAirline[]>([]);
  const [editAirlineData, setEditAirlineData] = useState<AvailableAirline[]>(
    []
  );
  const [airlineMilestone, setAirlineMilestone] = useState<AirlineMilestone[]>(
    []
  );
  const [airlineStandardMilestoneData, setAirlineStandardMilestoneData] =
    useState<StandardMilestoneDetails[]>([]);

  const [isScrapingExpanded, setIsScrapingExpanded] = useState(true);
  const [isParcelExpanded, setIsParcelExpanded] = useState(false);

  const toggleScraping = () => {
    setIsScrapingExpanded(!isScrapingExpanded);
  };

  const toggleParcel = () => {
    setIsParcelExpanded(!isParcelExpanded);
  };

  const [airlineDateFormat, setAirlineDateFormat] = useState([]);
  const [gocometMilestone, setGocometMilestone] = useState<any[]>([]);
  const [airlineDataSource, setairlineDataSource] = useState<any[]>([]);

  const [updateData, setupdateData] = useState<any[]>([]);
  const [modalType, setModalType] = useState<string>("");
  const [show, setShow] = useState(false);
  const { setLupaJwtToken, setUserApiLupaJwtToken } = useAuthContext();
  const [rowsData, setRowsData] = useState<RowData[]>([]);
  const [rowsData1, setRowsData1] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState<any[]>([]);
  const [error, setError] = useState<string>("");

  // const [isStandard, setIsStandard] = useState<boolean>(false);
  // const [standardMilestone, setStandardMilestone] = useState();
  const [airlineName, setAirlineName] = useState<string | undefined>();
  const [airlineCode, setAirlineCode] = useState<string | undefined | null>();
  const [dateFormat, setDateFormat] = useState<string | undefined>();
  const [addAirlineCode, setAddAirlineCode] = useState<
    string | null | undefined
  >();
  const [editAirlineCode, setEditAirlineCode] = useState<
    string | null | undefined
  >();
  const [defaultAirlineData, setDefaultAirlineData] = useState<AirlineDefault>({
    airlineCode: "",
    airlineType: "",
    airlineName: "",
    dataSource: "",
    dateFormatTypesId: "",
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [shownew, setShownew] = useState(false);

  const handleClose = () => {
    setShownew(false);
    setShow(false);
    setAirlineCode(undefined);
  };
  const handleShow = () => setShownew(true);

  const filteredAirlines = airlineData.filter((airline) =>
    Object.values(airline).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );
  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  const isUpdateButtonDisabled = () => {
    return (
      (airlineName === defaultAirlineData.airlineName &&
        airlineCode === defaultAirlineData.airlineCode) ||
      // dataSource === defaultAirlineData.dataSource &&
      // dateFormat === defaultAirlineData.dateFormatTypesId
      !airlineName ||
      !airlineCode ||
      // !dataSource ||
      // !dateFormat ||
      airlineCode?.length !== 3 ||
      isNaN(Number(airlineCode))
    );
  };
  const [dataSource, setDataSource] = useState<string | undefined>();

  function handleAirlineName(event: React.ChangeEvent<HTMLInputElement>) {
    setAirlineName(event.target.value);
  }
  function handleAirlineCode(event: React.ChangeEvent<HTMLInputElement>) {
    setAirlineCode(event.target.value);
  }
  const handleDateCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDateFormat(event.target.value);
  };
  async function handleDataSource(event: React.ChangeEvent<HTMLSelectElement>) {
    setDataSource(event.target.value);
    const res = await lupaService.getPendingAWB(airlineCode);
    if (
      // modalType === "editAirline" &&
      res.length > 0 &&
      event.target.value !== "inactive" &&
      dataSource !== event.target.value
    ) {
      setairlineDataSource(res);

      const updatedDataSource = res.map((item: { awbNumber: any }) => ({
        awbNumber: item.awbNumber,
        dataSource: event.target.value.toUpperCase(),
      }));

      console.log("updatedDataSource ==>", updatedDataSource);
      setupdateData(updatedDataSource);
      handleShow();
    }
  }
  const [dataSourcePreferences, setDataSourcePreferences] = useState<
    Preference[]
  >([{ preference: 1, dataSourceId: "", dateFormatTypesId: "" }]);

  useEffect(() => {
    const fetchedDataSources: DataSource[] = [];
    setDataSources(fetchedDataSources);
  }, []);
  useEffect(() => {
    const initialPreferences: Preference[] = [];

    // dataSources.forEach((source, index) => {
    //   initialPreferences.push({
    //     preference: index + 1,
    //     dataSourceId: source.id.toString(),
    //     dateFormatTypesId: "",
    //   });
    // });

    setDataSourcePreferences(initialPreferences);
  }, [dataSources]);
  const addPreferenceRow = () => {
    setDataSourcePreferences([
      ...dataSourcePreferences,
      {
        dataSourceId: "",
        dateFormatTypesId: "",
        preference: dataSourcePreferences.length + 1,
      },
    ]);
  };

  const deletePreferenceRow = (index: number) => {
    if (dataSourcePreferences.length > 1) {
      const updatedPreferences = dataSourcePreferences.filter(
        (_, i) => i !== index
      );
      // Update preferences to maintain correct order
      const reorderedPreferences = updatedPreferences.map((pref, idx) => ({
        ...pref,
        preference: idx + 1,
      }));
      setDataSourcePreferences(reorderedPreferences);
    }
  };
  const handlePreferenceChange = (
    index: number,
    field: keyof Preference,
    value: string | number
  ) => {
    const newPreferences = [...dataSourcePreferences];
    const updatedPreference: Preference = {
      ...newPreferences[index],
      [field]: value,
    };

    if (field === "dataSourceId") {
      const duplicate = newPreferences.some(
        (pref, i) => i !== index && pref.dataSourceId == value
      );
      if (duplicate) {
        setError("Data Source cannot be selected more than once.");
        return;
      }
    }
    if (field === "preference") {
      const duplicatePreference = newPreferences.some(
        (pref, i) => i !== index && pref.preference == value
      );
      if (duplicatePreference) {
        setError("Preference order must be unique.");
        return;
      }
    }

    newPreferences[index] = updatedPreference;
    setDataSourcePreferences(newPreferences);
    setShow(true);
    setError("");
  };

  async function updatePendingAWB() {
    setLoader(true);
    const res = await lupaService.changeDataSourcebulk(updateData);
    toast.success("Processed successfully");
    // handleUpdateAirline("editAirline");
    setLoader(false);

    handleClose();
  }

  const [data, setData] = useState<Record<"scraping" | "parcel", RowData[]>>({
    scraping: [],
    parcel: [],
  });

  useEffect(() => {
    const scrapingData = rowsData.filter(
      (row) => row.dataSource === "SCRAPING"
    );
    const parcelData = rowsData.filter((row) => row.dataSource === "PARCEL");
    setData({ scraping: scrapingData, parcel: parcelData });
  }, [rowsData]);

  const addTableRows = (type: string, dataSource: string) => {
    const rowsInput: RowData = {
      airlineCode: type === "add" ? addAirlineCode : editAirlineCode,
      id: crypto.getRandomValues(new Uint32Array(1))[0],
      milestoneName: "",
      isStandard: false,
      standardMilestoneId: null,
      type: "ADDED",
      dataSource: dataSource,
      sampleDateFormat: "",
      dateFormat: "",
      dateFormatCode: 0,
    };
    // setRowsData((prevRowsData) => [...prevRowsData, rowsInput]);
    setData((prevData) => {
      return rowsInput.dataSource === "SCRAPING"
        ? { ...prevData, scraping: [...prevData.scraping, rowsInput] }
        : { ...prevData, parcel: [...prevData.parcel, rowsInput] };
    });
  };

  const deleteTableRows = (index: number, dataSource: string) => {
    console.log("deleteTableRows", index, dataSource);
    const ds: "scraping" | "parcel" = dataSource.toLowerCase() as
      | "scraping"
      | "parcel";
    setData((prevData) => {
      const updatedData = { ...prevData };
      const rows = updatedData[ds]
        .map((row: RowData, i: number) => {
          if (i === index) {
            if (row.type === "ADDED") {
              return null;
            }
            return { ...row, type: "DELETED" };
          }
          return row;
        })
        .filter(Boolean) as RowData[];

      return { ...updatedData, [dataSource.toLowerCase()]: rows };
    });
  };

  const handleChange = (
    index: number,
    value: string | number | boolean,
    type: string,
    dataSource: string
  ) => {
    const ds: "scraping" | "parcel" = dataSource.toLowerCase() as
      | "scraping"
      | "parcel";

    setData((prevData) => {
      const updatedData = { ...prevData };
      const updatedRows = updatedData[ds].map((row, i) =>
        i === index
          ? {
              ...row,
              [type]: value,
              type: row.type === "NONE" ? "UPDATED" : row.type,
            }
          : row
      );

      return { ...updatedData, [ds]: updatedRows };
    });
  };

  const fetchAirline = () => {
    lupaService.getAvailableAirlines()?.then((res) => {
      setAirlineData(res.data.availableAirlines);
    });
  };

  function handleUpdateAirline(type: string) {
    if (!airlineName || !airlineCode)
      return toastTypes.error("Please fill all the fields");
    const functionName =
      type === "editAirline" ? "updateAirline" : "addAirline";
    lupaService[functionName]({
      name: airlineName,
      airlineCode: airlineCode,
      airlineType: "",
      dataSourcePreferences: dataSourcePreferences,
      // dateFormatTypesId: dateFormat,
    })
      ?.then((response: any) => {
        response
          .json()
          .then((data: any) => {
            if (response.status === 200 || response.status === 201) {
              if (data.message) {
                setShow(false);
                setAirlineCode(undefined);
                setDateFormat(undefined);
                setAirlineName(undefined);
                setDataSource(undefined);
                setDefaultAirlineData({
                  airlineCode: "",
                  airlineName: "",
                  airlineType: "",
                  dataSource: "",
                  dateFormatTypesId: "",
                });
                fetchAirline();
                return toastTypes.success(data.message);
              }
            }
            console.log(data);
            if (response.status === 400)
              return toastTypes.error("Airline Already Exists");
          })
          .catch((error: any) => {
            console.log({ error });
            toastTypes.error("An error occurred while creating the airline");
          });
      })
      .catch((error: any) => {
        console.log(error.result);
      });
  }
  type Milestone = {
    id: number;
    airlineCode: string;
    milestoneName: string;
    isStandard: boolean;
    dataSource: string;
    type: string;
    standardMilestoneId: number | null;
    sampleDateFormat?: string;
    dateFormat?: string;
    dateFormatCode?: number;
  };

  type OutputType = {
    mileStoneData: Milestone[];
  };

  function transformData(input: any): any {
    const { scraping, parcel } = input;

    const mileStoneData: Milestone[] = [...scraping, ...parcel];

    return mileStoneData;
  }
  function handleUpdateMilestone() {
    setRowsData(transformData(data));
    const updatedData = transformData(data);
    lupaService
      .updateAirlineMilestone(updatedData)
      ?.then((response: any) => {
        response.json().then((data: any) => {
          console.log(response.status);
          if (response.status === 201) {
            if (data.isSuccess) {
              setShow(false);
              fetchAirline();
              return toastTypes.success(data.message);
            }
          }
          if (response.status === 400) return toastTypes.error(data.message);
        });
      })
      .catch((error: any) => {
        console.log(error.result);
      });
  }

  async function handleModal(
    data: any,
    type: string,
    carrierCode?: string | null
  ) {
    setShow(true);
    setRowsData([]);
    setModalType(type);

    if (type === "editAirline" || type === "addAirline") {
      if (type === "addAirline") {
        setAirlineCode(undefined);
        setAirlineName(undefined);
        setDataSource("");
        setDefaultAirlineData({
          airlineCode: "",
          airlineName: "",
          airlineType: "",
          dataSource: "",
          dateFormatTypesId: "",
        });
      } else {
        setAirlineCode(data.airlineCode);
        setAirlineName(data.name);
        setDataSource(data.dataSource);
        setDateFormat(data.dateFormatTypesId);
        setEditAirlineData(data);
        setDefaultAirlineData({
          airlineCode: data.airlineCode,
          airlineType: data.airlineType,
          airlineName: data.name,
          dataSource: data.dataSource,
          dateFormatTypesId: data.dateFormatTypesId,
        });
        //
        const preferences: Preference[] = data.airlineDataSourcePrefs.map(
          (pref: {
            preference: any;
            dataSourceId: any;
            dataFormatTypesId: any;
          }) => ({
            preference: pref.preference,
            dataSourceId: pref.dataSourceId,
            dateFormatTypesId: pref.dataFormatTypesId,
          })
        );
        setDataSourcePreferences(preferences);
      }
    } else if (type === "editMilestone" || type === "viewMilestone") {
      setRowsData(
        data?.map((airlineMilestone: AirlineMilestone, index: Number) => {
          return {
            id: airlineMilestone.id,
            airlineCode: airlineMilestone.airlineCode,
            milestoneName: airlineMilestone.name,
            isStandard: airlineMilestone.isStandard,
            dataSource: airlineMilestone.dataSource,
            type: "NONE",
            // standardMilestone: airlineMilestone.standardMilestoneDetails?.name,
            standardMilestoneId: airlineMilestone.standardMilestoneDetails
              ? airlineMilestone.standardMilestoneDetails?.id
              : null,
          };
        })
      );
      console.log({ data });
      setAirlineMilestone(data);
      setEditAirlineCode(carrierCode);
    } else if (type === "viewdateFormat") {
      setRowsData(airlineDateFormat);
    } else if (type === "mapdateFormat") {
      setRowsData(airlineDateFormat);
      setRowsData1(data);
    } else {
      console.log(rowsData);
      setAddAirlineCode(carrierCode ?? null);
    }
  }

  const [showAwbModal, setShowAwbModal] = useState(false);
  const [awbNumber, setAwbNumber] = useState("");
  const [awbError, setAwbError] = useState("");
  const [liveData, setLiveData] = useState<any>();
  const [showLiveDataModal, setShowLiveDataModal] = useState(false);
  const [loader, setLoader] = useState(false);

  // Function to handle showing the AWB number modal
  const handleShowAwbModal = () => {
    setShowAwbModal(true);
    setAwbNumber("");
  };

  // Function to handle closing the AWB number modal
  const handleCloseAwbModal = () => {
    setShowAwbModal(false);
    setAwbNumber("");
    setAwbError("");
  };

  // Function to handle changing AWB number input
  const handleAwbNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{3}-\d{8}$/.test(input)) {
      setAwbNumber(input);
      setAwbError("");
    } else {
      setAwbNumber(input);
      setAwbError("Please enter a valid AWB number (e.g., 123-12345678)");
    }
  };
  const handleShowLiveDataModal = () => {
    setShowLiveDataModal(true);
  };

  // Function to handle fetching data based on AWB number
  const handleFetchData = async (id: string) => {
    if (/^\d{3}-\d{8}$/.test(id)) {
      try {
        setLoading(true);
        const response = await WebhookUiService.getLiveData(id);
        const responseData = response || "";
        setLiveData(responseData);
        // handleShowLiveDataModal();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        handleShowLiveDataModal(); // Set loading state to false after fetching data
      }
    }

    handleCloseAwbModal();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevents default form submission behavior
    if (/^\d{3}-\d{8}$/.test(awbNumber)) {
      handleFetchData(awbNumber);
    }
  };

  const handleCloseLiveDataModal = () => {
    setShowLiveDataModal(false);
    setAwbNumber("");
  };

  useEffect(() => {
    fetchAirline();
  }, []);

  return (
    <>
      <Loader loading={loader}>
        <ClientNavbar />
        <Container className="py-5">
          <Table striped bordered hover size="sm" className="shadow rounded">
            <thead className="rounded">
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Name</th>
                <th className="text-center">Airline Code</th>
              </tr>
            </thead>
            <tbody>
              {filteredAirlines?.map(
                (airline: AvailableAirline, index: number) => (
                  <tr key={index}>
                    <td className="text-center" valign="middle">
                      {index + 1}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.name}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.airlineCode}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>
      </Loader>
    </>
  );
}
export default Airline;
