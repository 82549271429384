import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { ScrapingTable } from "../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import AWBUsage from "./AWBUsage";
import { NavLink } from "react-router-dom";

const Scraping = () => {
  const {
    data: scraping,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("scraping", WebhookUiService.getScraping, {
    refetchOnWindowFocus: false,
  });
  console.log(scraping);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const webhookSentFilterFn: MRT_FilterFn<ScrapingTable> = (
    row,
    id,
    filterValue
  ) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const filterBoolean = filterValue === "true"; // Convert filter value to boolean
    return row.original.webhookSent === filterBoolean;
  };

  const handleExportToExcel = (rows: MRT_Row<ScrapingTable>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Scraping_Data");

    XLSX.writeFile(workbook, "Scraping_Data.xlsx");
  };

  const columns = useMemo<MRT_ColumnDef<ScrapingTable>[]>(
    () => [
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",

        Cell: ({ cell, row }) => (
          <NavLink
            className="text-primary cursor-pointer"
            to={`/scraping/${row.original.awbNumber}/${row.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.awbNumber}
          </NavLink>
        ),
      },

      {
        header: "Before Tracking Status",
        filterFn: "includesString",
        accessorFn: (row) => row?.beforeTrackingStatus || "",
      },
      {
        header: "After Tracking Status",
        filterFn: "includesString",
        accessorFn: (row) => row?.afterTrackingStatus || "",
      },
      {
        header: "Entry Time",
        accessorKey: "createdAt",
        accessorFn: (row) =>
          row?.createdAt ? new Date(row.createdAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "Occurance",
        accessorFn: (row) => row?.count || "",
      },
      {
        header: "Time Difference",
        accessorFn: (row) => row?.timeDifference || "",
      },
      {
        header: "Webhook Sent",
        accessorFn: (row) =>
          row.webhookSent === true ? (
            <i className="bi bi-check-circle-fill text-success"></i>
          ) : (
            <i className="bi bi-x-circle-fill text-danger"></i>
          ),

        filterFn: webhookSentFilterFn,
      },
      {
        header: "Webhook Status",
        accessorFn: (row) => row?.webhookStatus || "",
      },
      {
        header: "Webhook Time Difference",
        accessorFn: (row) => row?.webhookTimeDifference || "",
      },
      // {
      //   header: "Webhook Time",
      //   accessorKey: "webhookTime",
      //   accessorFn: (row) => (
      //     row?.webhookTime ? new Date(row.webhookTime.toLocaleString()) : ''),

      //   filterVariant: "date-range",
      //   Cell: ({ cell }) => {
      //     const dateValue = cell.getValue<string>();
      //     return dateValue ? new Date(dateValue).toLocaleString() : "";
      //   },
      // },
    ],
    []
  );

  const table = useMaterialReactTable<ScrapingTable>({
    columns,
    data: scraping ?? [],
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
        <h6 className="mt-2">
          No of AWBs: {table.getFilteredRowModel().rows.length}{" "}
        </h6>
      </Box>
    ),
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </>
  );
};
export default Scraping;
