import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Input from "../../common/Input";
import moment from "moment";
import { toast } from "react-toastify";
import { getInvoices } from "../../../services/customer";
import { useQuery } from "react-query";
import RowAlightCenter from "../../common/RowBetween";
import Select from "../../common/Select";

const label = {
  paymentDate: "Payment Date",
  amount: "Amount (in Rupees)",
  paymentMethod: "Payment Method",
  isFullPayment: "Full Payment",
};

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmit: (receiptIndex: string, invoiceIndex: string) => void;
  userId: number;
  receiptId: string | null;
};

function LinkInvoice({ show, onHide, onSubmit, userId, receiptId }: Props) {
  const { data, isLoading, error, refetch } = useQuery(
    ["invoices", userId],
    () => getInvoices({ userId })
  );

  useEffect(() => {
    if (!receiptId) onHide();
  }, [receiptId]);
  const handleSubmit = () => {
    if (!selectedInvoice) {
      toast.error("Please select an invoice");
      return;
    }
    onSubmit(receiptId!, selectedInvoice);
  };

  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedInvoice(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>Link Receipt to Invoice</Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <RowAlightCenter>
            <Spinner animation="border" />
          </RowAlightCenter>
        ) : (
          <>
            <Select label="Invoice" name="invoiceId" onChange={handleChange}>
              <option value="">Select Invoice</option>
              {data?.data?.map((invoice: any) => (
                <option value={invoice.id} key={invoice.invoiceId}>
                  {invoice.invoiceId}
                </option>
              ))}
            </Select>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Close
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={!selectedInvoice}
        >
          Link Invoice
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkInvoice;
