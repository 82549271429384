import React, { useEffect, useMemo, useState } from "react";
// import WebhookUiService from "../../../services/webhook-ui";
// import { ScrapingTable } from "../../../models/TrackingSummary";
import WebhookUiService from "../../../../services/webhook-ui";
import { ScrapingTable } from "../../../../models/TrackingSummary";
import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState,
  useMaterialReactTable,
  MRT_ColumnDef,
  MaterialReactTable,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import ClientNavbar from "../../../foundation/Navbar";
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
// import AWBUsage from "./AWBUsage";
import { NavLink } from "react-router-dom";
import AirlineViewDataPref from "./AirlineViewDataPref";

const Datacompare = () => {
  const {
    data: scraping,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("scraping", WebhookUiService.getDataCompare, {
    refetchOnWindowFocus: false,
  });
  console.log("===>", scraping);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "awbNumber",
      desc: false,
    },
    // { id: "createdAt", desc: true },
  ]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",

        Cell: ({ cell, row }) => (
          <NavLink
            className="text-primary cursor-pointer"
            to={`/data-compare/${row.original.awbNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.original.awbNumber}
          </NavLink>
        ),
      },

      {
        header: `Parcel Mismatch`,
        filterFn: "includesString",
        Cell: ({ cell, row }) => {
          if (row.index === 0) {
            return (
              <>
                <span className="badge bg-danger me-1">H</span>
                <span className="badge bg-warning me-1">E</span>
                <span className="badge bg-info me-1">F</span>
                <span className="badge bg-success me-1">P</span>
              </>
            );
          } else {
            const messages = [];
            if (
              row.original.message?.includes("Parcel Events") &&
              row.original.message?.includes("Parcel Flights")
            ) {
              const parcelEventsMatch = row.original.message.match(
                /Parcel Events = (\d+)/
              );
              const parcelFlightsMatch = row.original.message.match(
                /Parcel Flights =(\d+)/
              );

              const headerMatch = row.original.message.match(
                /Parcel Header mismatch count:\s*(\d+)/
              );
              if (headerMatch) {
                const totalMatch = parseInt(headerMatch[1]);
                messages.push(`${totalMatch}`);
              }

              if (parcelEventsMatch && parcelFlightsMatch) {
                const parcelEvents = parseInt(parcelEventsMatch[1]);
                const parcelFlights = parseInt(parcelFlightsMatch[1]);
                messages.push(`${parcelEvents}`);
                messages.push(`${parcelFlights}`);
              }

              const flightMatch = row.original.parcelMismatch?.match(
                /Flights key mismatch with Scraping by (\d+)/
              );
              const eventsMatch = row.original.parcelMismatch?.match(
                /Events key mismatch with Scraping by (\d+)/
              );

              if (flightMatch && eventsMatch) {
                const totalMatch =
                  parseInt(flightMatch[1]) + parseInt(eventsMatch[1]);
                messages.push(`${totalMatch}`);
              }
            }
            return (
              <>
                {messages.map((message, index) => {
                  let badgeClass = "bg-primary";
                  switch (index) {
                    case 0:
                      badgeClass = "bg-danger";
                      break;
                    case 1:
                      badgeClass = "bg-warning";
                      break;
                    case 2:
                      badgeClass = "bg-info";
                      break;
                    case 3:
                      badgeClass = "bg-success";
                      break;
                    default:
                      badgeClass = "bg-primary";
                  }
                  return (
                    <span key={index} className={`badge ${badgeClass} me-1`}>
                      {message}
                    </span>
                  );
                })}
              </>
            );
          }
        },
      },
      {
        header: `Gocomet Mismatch`,
        filterFn: "includesString",
        Cell: ({ cell, row }) => {
          if (row.index === 0) {
            return (
              <>
                <span className="badge bg-danger me-1">H</span>
                <span className="badge bg-warning me-1">E</span>
                <span className="badge bg-info me-1">F</span>
                <span className="badge bg-success me-1">P</span>
              </>
            );
          } else {
            const messages = [];
            if (
              row.original.message?.includes("Gocomet Events") &&
              row.original.message?.includes("Gocomet Flights")
            ) {
              const gocometEventsMatch = row.original.message.match(
                /Gocomet Events\s*=\s*(\d+)/
              );
              const gocometFlightsMatch = row.original.message.match(
                /Gocomet Flights\s*=\s*(\d+)/
              );

              const headerMatch = row.original.message.match(
                /Gocomet Header mismatch count:\s*(\d+)/
              );
              if (headerMatch) {
                const totalMatch = parseInt(headerMatch[1]);
                messages.push(`${totalMatch}`);
              }

              if (gocometEventsMatch && gocometFlightsMatch) {
                const gocometEvents = parseInt(gocometEventsMatch[1]);
                const gocometFlights = parseInt(gocometFlightsMatch[1]);
                messages.push(`${gocometEvents}`);
                messages.push(`${gocometFlights}`);
              }

              const flightMatch = row.original.gocometMismatch?.match(
                /Flights key mismatch with Scraping by\s*(\d+)/
              );
              const eventsMatch = row.original.gocometMismatch?.match(
                /Events key mismatch with Scraping by\s*(\d+)/
              );

              if (flightMatch && eventsMatch) {
                const totalMatch =
                  parseInt(flightMatch[1]) + parseInt(eventsMatch[1]);
                messages.push(`${totalMatch}`);
              }
            }
            return (
              <>
                {messages.map((message, index) => {
                  let badgeClass = "bg-primary";
                  switch (index) {
                    case 0:
                      badgeClass = "bg-danger";
                      break;
                    case 1:
                      badgeClass = "bg-warning";
                      break;
                    case 2:
                      badgeClass = "bg-info";
                      break;
                    case 3:
                      badgeClass = "bg-success";
                      break;
                    default:
                      badgeClass = "bg-primary";
                  }
                  return (
                    <span key={index} className={`badge ${badgeClass} me-1`}>
                      {message}
                    </span>
                  );
                })}
              </>
            );
          }
        },
      },
      {
        header: "ScrapingDate Update",
        id: "lastScrapingDate",
        accessorKey: "lastScrapingDate",
        accessorFn: (row) =>
          row?.lastScrapingDate
            ? new Date(row.lastScrapingDate.toLocaleString())
            : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "GocometDate Update",
        id: "lastGocometDate",
        accessorKey: "lastGocometDate",
        accessorFn: (row) =>
          row?.lastGocometDate
            ? new Date(row.lastGocometDate.toLocaleString())
            : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "ParcelDate Update",
        id: "lastParcelDate",
        accessorKey: "lastParcelDate",
        accessorFn: (row) =>
          row?.lastParcelDate
            ? new Date(row.lastParcelDate.toLocaleString())
            : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "Entry Time",
        id: "createdAt",
        accessorKey: "createdAt",
        accessorFn: (row) =>
          row?.createdAt ? new Date(row.createdAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      {
        header: "Update Time",
        id: "updatedAt",
        accessorKey: "updatedAt",
        accessorFn: (row) =>
          row?.updatedAt ? new Date(row.updatedAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable<any>({
    columns,
    data: scraping ?? [],
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      sorting: [
        {
          id: "awbNumber",
          desc: true,
        },
      ],
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button> */}
        <h6 className="mt-2">
          No of AWBs: {table.getFilteredRowModel().rows.length}{" "}
        </h6>
      </Box>
    ),
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <AirlineViewDataPref />
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </>
  );
};
export default Datacompare;
