import { createContext, useContext } from "react"
const defaultValue = {
    isAuth: false,
    role: null,
    user: null,
    lupaJwtToken: null,
    userApiLupaJwtToken: null,
    setIsAuth: () => { },
    setRole: () => { },
    login: () => { },
    setLupaJwtToken: () => { },
    setUserApiLupaJwtToken: () => { },
    logout: () => { },
}

export interface AuthContent {
    isAuth: boolean,
    role: null | string[],
    user: any,
    lupaJwtToken: string | null
    userApiLupaJwtToken:string | null
    setIsAuth: (args: boolean) => void,
    setRole: (args: string[] | null) => void
    login: (args : {email : string , password :string}) => void
    setLupaJwtToken: (args : string | null) => void
    setUserApiLupaJwtToken: (args : string | null) => void
    logout: () => void
}
export const AuthContext = createContext<AuthContent>(defaultValue)
export const useAuthContext = () => useContext(AuthContext)