export enum OrganizationType {
    FF,
    SHIPPER
}

export class Organization {

    public readonly orgId: number
    public readonly name: string;
    public readonly orgType: OrganizationType;
    public readonly address: string; 
    public readonly logo: string;
    public readonly email: string;

    protected constructor(orgId: number, name: string, orgType: OrganizationType, address: string, logo: string = "", email: string = ""){
        this.orgId = orgId;
        this.name = name;
        this.orgType = orgType;
        this.address = address;
        this.logo = logo;
        this.email = email;
    }
}