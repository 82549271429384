import React from "react";
import { theme } from "../../utils/common";

type Props = {
  status: "success" | "danger" | "warning";
  size?: string | number;
};

function StatusIcon({ size = 16, status }: Props): JSX.Element {
  const style = {
    minWidth: size,
    minHeight: size,
    maxHeight: size,
    maxWidth: size,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
    verticalAlign: "middle",
    padding: "0px",
    border: `2px solid ${theme.primary}`,
  };

  return <button className={`btn btn-${status}`} style={style}></button>;
}

export default StatusIcon;
