import * as Yup from "yup";

export const shipperCategoryValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(20, "Must be 20 characters or less")
    .required("Name is required"),
  margin: Yup.number()
    .required("Margin is required")
    .min(0, "Must be 0 or more"),
  discount: Yup.number()
    .required("Address Line 2 is required")
    .min(0, "Must be 0 or more")
    .max(100, "Must be 100 or less"),
  showOcdc: Yup.boolean().required("ODCD is required"),
  showPricing: Yup.boolean().required("ODCD is required"),
  showPerKgFinalRate: Yup.boolean().required(
    "Per KG Final Pricing is required"
  ),
  walletCreditLimit: Yup.number()
    .required("Per KG Final Pricing is required")
    .min(0, "Must be 0 or more"),
  creditTime: Yup.number()
    .required("Credit Time ")
    .min(0, "Must be 0 or more")
    .max(90, "Must be less than 90"),
});

export const shipperValidationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Must be 2 characters or more")
    .max(20, "Must be 20 characters or less")
    .required("Name is required"),
  firstLine: Yup.string()
    .required("Address Line 2 is required")
    .min(5, "Must be 5 characters or more")
    .max(30, "Must be less than 20 characters"),
  secondLine: Yup.string(),
  city: Yup.string()
    .required("City is required")
    .min(2, "Must be 5 characters or more")
    .max(30, "Must be less than 20 characters"),
  state: Yup.string()
    .required("State is required")
    .min(2, "Must be 2 characters or more")
    .max(30, "Must be less than 30 characters"),
  pincode: Yup.string()
    .required("Pincode is required")
    .min(2, "Must be 2 characters or more")
    .max(8, "Must be less than 8 characters"),
  // category_id: Yup.number()
  //   .required("Shipper Category is required")
  //   .min(1, "Select Shipper Category"),
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email must be valid"
    )
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is Required")
    .matches(/^[0-9]+$/, "Must be only Numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  countryCode: Yup.string()
    .required("Country Code is Required")
    .min(2, "Must be 2 digits or more")
    .max(10, "Must be 11 digits or more"),
});

export const shipperUserValidationSchema = Yup.object({
  firstName: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  baseLocation: Yup.string()
    .required("City is required")
    .min(2, "Must be 2 characters or more")
    .max(30, "Must be less than 30 characters"),
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email must be valid"
    )
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone Number is Required")
    .matches(/^[0-9]+$/, "Must be only Numbers")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits"),
  countryCode: Yup.string()
    .required("Country Code is Required")
    .min(2, "Must be 2 digits or more")
    .max(10, "Must be 11 digits or more"),
  password: Yup.string()
    .required("City is required")
    .min(2, "Must be 5 characters or more")
    .max(30, "Must be less than 20 characters"),
});

export const passwordResetValidationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Your password is too short.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});

export const passwordResetInitialData = {
  email: "",
  password: "",
  confirmPassword: "",
};
export const shipperInitialData = {
  email: "",
  name: "",
  firstLine: "",
  secondLine: "",
  state: "",
  city: "",
  pincode: "",
  phone: "",
  countryCode: "",
};

export const consigneeInitialData = {
  email: "",
  name: "",
  firstLine: "",
  secondLine: "",
  state: "",
  city: "",
  pincode: "",
  phone: "",
  countryCode: "",
};
export const shipperUserInitialData = {
  email: "",
  password: Date.now().toString(),
  firstName: "",
  lastName: "",
  phone: "",
  countryCode: "",
  baseLocation: "",
};

export const createShipperInitialData = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  countryCode: "",
  baseLocation: "",
};

export const shipperCategoryInitialData = {
  margin: 0,
  discount: 0,
  showPricing: false,
  showOcdc: false,
  showPerKgFinalRate: false,
  walletCreditLimit: 0,
  creditTime: 0,
  name: "",
};
