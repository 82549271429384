import React, { useEffect, useMemo, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { AWBDetails } from "../../../models/TrackingSummary";
import {
    MRT_ColumnFiltersState,
    MRT_SortingState,
    MRT_PaginationState,
    useMaterialReactTable,
    MRT_ColumnDef,
    MaterialReactTable,
    MRT_Row,
    MRT_FilterFn,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import AWBUsage from "./AWBUsage";
import { NavLink } from "react-router-dom";


const AWBDetailsView = () => {
    const {
        data: awbDetails,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery("awbDetails", WebhookUiService.getAWBDetails, {
        refetchOnWindowFocus: false,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        []
    );
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState<MRT_SortingState>([
        { id: "createdDateTime", desc: true },
    ]);

    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [showEditForm, setShowEditForm] = useState(false);
    const [editAwbData, setEditAwbData] = useState<AWBDetails[] | null>(null);

    const handleExportToExcel = (rows: MRT_Row<AWBDetails>[]) => {
        const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Scraping_Data");

        XLSX.writeFile(workbook, "Scraping_Data.xlsx");
    };
    const handleEditClick = async (awbNumber: string) => {
        try {
            const data = await WebhookUiService.getAWBBasedonId(awbNumber);
            // console.log(data);
            if (data) {
                setEditAwbData(data);
                setShowEditForm(true);
            }

        } catch (error) {
            console.log(error);
        }
    };


    const columns = useMemo<MRT_ColumnDef<AWBDetails>[]>(
        () => [
            {
                header: "AWB Number",
                filterFn: "includesString",
                accessorFn: (row) => row?.awbNumber || "",
            },

            {
                header: "Status",
                filterFn: "includesString",
                accessorFn: (row) => row?.trackingStatus || "",
            },
            {
                header: "Org",
                filterFn: "includesString",
                accessorFn: (row) => row?.origin || "",
            },
            {
                header: "Dest",
                filterFn: "includesString",
                accessorFn: (row) => row?.destination || "",
            },
            {
                header: "Wgt",
                filterFn: "includesString",
                accessorFn: (row) => row?.weight || "",
            },
            {
                header: "Pcs",
                filterFn: "includesString",
                accessorFn: (row) => row?.pieces || "",
            },

            {
                header: "Edit",
                Cell: ({ row }) => (
                    <NavLink to={`/awbedit/${row.original.awbNumber}`}>Edit</NavLink>
                ),
            },
            {
                header: "Entry Time",
                accessorKey: "createdDateTime",
                accessorFn: (row) =>
                    row?.createdDateTime ? new Date(row.createdDateTime.toLocaleString()) : "",

                filterVariant: "date-range",
                Cell: ({ cell }) => {
                    const dateValue = cell.getValue<string>();
                    return dateValue ? new Date(dateValue).toLocaleString() : "";
                },
            },
        ],
        []
    );

    const table = useMaterialReactTable<AWBDetails>({
        columns,
        data: awbDetails ?? [],
        enableRowSelection: true,
        initialState: {
            showColumnFilters: false,
            density: "compact",
        },
        manualFiltering: false,
        manualPagination: false,
        manualSorting: false,
        muiToolbarAlertBannerProps: isError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box
                sx={{
                    display: "flex",
                    gap: "16px",
                    padding: "8px",
                    flexWrap: "wrap",
                }}
            >
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                        handleExportToExcel(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                >
                    All Rows(Excel)
                </Button>
                <Button
                    disabled={table.getRowModel().rows.length === 0}
                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                    onClick={() => handleExportToExcel(table.getRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Page Rows(Excel)
                </Button>
                <Button
                    disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                >
                    Selected Rows(Excel)
                </Button>
                <h6 className="mt-2">
                    No of AWBs: {table.getFilteredRowModel().rows.length}{" "}
                </h6>
            </Box>
        ),
    });

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ClientNavbar />
                <MaterialReactTable table={table} />
            </LocalizationProvider>
        </>
    );
};
export default AWBDetailsView;
