import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Badge, Container } from "react-bootstrap";
import { AWBDetails, UserTrackingFlights, UserTrackingHistories } from "../../../models/TrackingSummary";
import axios from "axios";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";
import { useNavigate } from 'react-router-dom';

interface EditFormProps {
    awbData: AWBDetails[] | null;
}

const EditAWBForm: React.FC<EditFormProps> = ({ awbData }) => {
    const navigate = useNavigate();
    const [section, setSection] = useState<number>(1);
    const [editedHeader, setEditedHeader] = useState<Partial<AWBDetails> | null>(null);
    const [editedFlights, setEditedFlights] = useState<Partial<UserTrackingFlights>[]>([]);
    const [editedHistories, setEditedHistories] = useState<Partial<UserTrackingHistories>[]>([]);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [standardMilestones, setStandardMilestones] = useState<string[]>([]);

    const extractStandardMilestones = () => {
        const milestones: string[] = [];

        awbData?.forEach((awbItem) => {
            if (awbItem.UserTrackingHistories) {
                awbItem.UserTrackingHistories.forEach((history) => {
                    if (history.isStandardMilestone && history.standardMilestone) {
                        milestones.push(history.standardMilestone);
                    }
                });
            }
        });

        // Remove duplicate milestones
        return Array.from(new Set(milestones));
    };
    useEffect(() => {
        if (awbData && awbData.length > 0) {
            // Set the initial value of editedHeader to the first AWB item's tracking status
            setEditedHeader((prevData) => ({
                ...prevData,
                trackingStatus: awbData[0].trackingStatus
            }));
            const milestones = extractStandardMilestones();
            setStandardMilestones(milestones);
        }
    }, [awbData]);

    const handleHeaderInputChange = (fieldName: string, value: string, pattern: RegExp | null) => {
        let parsedValue = value;
        // Replace the star symbol with a space
        parsedValue = parsedValue.replace('[ ]', ' ');

        if (pattern) {
            const fieldErrors: Record<string, string> = {};
            if (parsedValue.trim() !== '') {
                if (!pattern.test(parsedValue)) {
                    if (fieldName === "origin" || fieldName === "destination") {
                        fieldErrors[fieldName] = `Please provide a valid ${fieldName} in the format ABC`;
                    } else if (fieldName === "pieces" || fieldName === "weight") {
                        fieldErrors[fieldName] = `Please provide a valid number`;
                    } else {
                        fieldErrors[fieldName] = `Please provide a valid ${fieldName}.`
                    }
                } else {
                    // Clear the error if the value is valid
                    delete errors[fieldName];
                }
            } else {
                // Clear the error if the value is empty
                delete errors[fieldName];
            }
            setErrors(prevErrors => ({ ...prevErrors, ...fieldErrors }));
        }

        setEditedHeader((prevData) => ({
            ...prevData,
            [fieldName]: parsedValue,
        }));
    };


    const handleFlightInputChange = (fieldName: string, value: string, idx: number, pattern: RegExp | null) => {
        let errorMessage = '';

        // Check if value matches the pattern and update error message accordingly
        if (pattern && value.trim() !== '' && !pattern.test(value)) {

            if (fieldName.includes("flightNumber")) {
                errorMessage = "Please provide a valid flight number format (e.g., AA 123).";
            } else if (fieldName.includes("flightDate")) {
                errorMessage = "Please provide a valid flight date in YYYY-MM-DD format.";
            } else {
                errorMessage = `Please provide a valid ${fieldName}.`;
            }
            setErrors(prevErrors => ({
                ...prevErrors,
                [`${fieldName}-${idx}`]: errorMessage
            }));
        } else {
            // Clear the error if the value is valid
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[`${fieldName}-${idx}`];
                return updatedErrors;
            });
        }

        const updatedFlight: Partial<UserTrackingFlights> = { ...editedFlights[idx], [fieldName]: value };
        setEditedFlights(prevFlights => {
            const updatedFlightsCopy = [...prevFlights];
            updatedFlightsCopy[idx] = updatedFlight;
            return updatedFlightsCopy;
        });
    };

    const handleHistoryInputChange = (fieldName: string, value: string, idx: number, pattern: RegExp | null) => {
        let errorMessage = '';
        // Check if value matches the pattern and update error message accordingly
        if (pattern && value.trim() !== '' && !pattern.test(value)) {
            if (fieldName.includes("flightNo")) {
                errorMessage = "Please provide a valid flight number format (e.g., AA 123).";
            } else if (fieldName.includes("flightDate")) {
                errorMessage = "Please provide a valid flight date in DD-MMM-YYYY format.";
            } else if (fieldName === "origin" || fieldName === "destination" || fieldName === "station") {
                errorMessage = `Please provide a valid ${fieldName} in the format ABC`;
            } else if (fieldName === "pieces" || fieldName === "weight") {
                errorMessage = "Please provide a valid number"
            } else {
                errorMessage = `Please provide a valid ${fieldName}`;
            }

            setErrors(prevErrors => ({
                ...prevErrors,
                [`${fieldName}-${idx}`]: errorMessage
            }));
        } else {
            // Clear the error if the value is valid
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[`${fieldName}-${idx}`];
                return updatedErrors;
            });
        }

        const updatedHistory: Partial<UserTrackingHistories> = { ...editedHistories[idx], [fieldName]: value };
        setEditedHistories(prevHistories => {
            const updatedHistoriesCopy = [...prevHistories];
            updatedHistoriesCopy[idx] = updatedHistory;
            return updatedHistoriesCopy;
        });
    };


    const handleSaveChanges = async (index: number) => {
        if (!hasErrors()) {
            if (awbData) {
                const updatedHeader: Partial<AWBDetails> | null = editedHeader;

                if (editedFlights.length > 0 || editedHistories.length > 0) {
                    const updatedFlights: Partial<UserTrackingFlights>[] = awbData[index].UserTrackingFlights
                        ? awbData[index].UserTrackingFlights.map((flight, idx) => {
                            return { ...flight, ...editedFlights[idx] };
                        })
                        : [];

                    const updatedHistories: Partial<UserTrackingHistories>[] = awbData[index].UserTrackingHistories
                        ? awbData[index].UserTrackingHistories.map((history, idx) => {
                            return { ...history, ...editedHistories[idx] };
                        })
                        : [];

                    const updatedData = {
                        ...awbData[index],
                        ...updatedHeader,
                        UserTrackingFlights: updatedFlights,
                        UserTrackingHistories: updatedHistories, // Update the property name to match the interface
                    };
                    // console.log("Updated Data:", updatedData);
                    // Add logic to save the updated data
                    lupaService.updateAWB(updatedData).then((response) => {
                        if (response.data.result.success === true) {
                            toastTypes.success(response.data.result.message);
                            navigate('/awbDetails')

                        } else {
                            toastTypes.error(response.data.result.message)
                        }
                    })

                } else {
                    const updatedData = { ...awbData[index], ...updatedHeader };
                    // console.log("Updated Data:", updatedData);
                    lupaService.updateAWB(updatedData).then((response) => {
                        if (response.data.result.success === true) {
                            toastTypes.success(response.data.result.message);
                            navigate('/awbDetails')

                        } else {
                            toastTypes.error(response.data.result.message)
                        }
                    })

                }
            } else {
                console.error("awbData is null");
            }
        }
    };
    const handleNext = () => {
        if (!hasErrors()) {
            setSection(section + 1);
        }
    };

    const handlePrevious = () => {
        setSection(section - 1);
    };

    const handleCancel = () => {
        navigate('/awbDetails');
    };
    const hasErrors = () => {
        return Object.keys(errors).length > 0;
    };

    if (!awbData) return null;

    return (
        <div>
            <Container className="py-5">
                {awbData.map((awbItem, awbIndex) => (
                    <Form key={awbIndex}>

                        {section === 1 && (
                            <div>

                                {/* Flight Details Fields */}
                                {awbItem.UserTrackingFlights && awbItem.UserTrackingFlights.map((flight, flightIndex) => (
                                    <div key={flightIndex}>
                                        <Badge bg="primary">Flight {flightIndex + 1} </Badge>
                                        <Row className="my-2">
                                            <Col md={4}>
                                                <Form.Control
                                                    type="text"
                                                    value={editedFlights[flightIndex]?.flightNumber ?? flight.flightNumber}
                                                    onChange={(e) => handleFlightInputChange("flightNumber", e.target.value, flightIndex, /^[A-Za-z]{2}\d+\s[A-Za-z]$/)}
                                                    isInvalid={!!errors[`flightNumber-${flightIndex}`]}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`flightNumber-${flightIndex}`]}
                                                </Form.Control.Feedback>
                                                <Form.Group controlId={`eta-${awbIndex}-${flightIndex}`}>
                                                    <Form.Label>ETA</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={editedFlights[flightIndex]?.eta ?? flight.eta}
                                                        onChange={(e) => handleFlightInputChange("eta", e.target.value, flightIndex, null)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Control
                                                    type="text"
                                                    value={editedFlights[flightIndex]?.flightDate ?? flight.flightDate}
                                                    onChange={(e) => handleFlightInputChange("flightDate", e.target.value, flightIndex, /^\d{4}-\d{2}-\d{2}$/)}
                                                    isInvalid={!!errors[`flightDate-${flightIndex}`]}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`flightDate-${flightIndex}`]}
                                                </Form.Control.Feedback>
                                                <Form.Group controlId={`etd-${awbIndex}-${flightIndex}`}>
                                                    <Form.Label>ETD</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={editedFlights[flightIndex]?.etd ?? flight.etd}
                                                        onChange={(e) => handleFlightInputChange("etd", e.target.value, flightIndex, null)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={handleNext}>
                                            Next
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        )}

                        {section === 2 && (
                            <div>
                                {/* User Tracking Histories Fields */}
                                {awbItem.UserTrackingHistories && awbItem.UserTrackingHistories
                                    .filter(history => history.isStandardMilestone === true)
                                    .map((history, historyIndex) => (
                                        <div key={historyIndex}>
                                            <Badge bg="primary">Event {historyIndex + 1} </Badge>
                                            <Row className="my-2">
                                                <Col md={4}>
                                                    <Form.Group controlId={`standardMilestone-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Milestone</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.standardMilestone ?? history.standardMilestone}
                                                            onChange={(e) => handleHistoryInputChange("standardMilestone", e.target.value, historyIndex, null)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId={`flightDate-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Date</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.flightDate ?? history.flightDate}
                                                            onChange={(e) => handleHistoryInputChange("flightDate", e.target.value, historyIndex, /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/)}
                                                            isInvalid={!!errors[`flightDate-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`flightDate-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId={`origin-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Origin</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.origin ?? history.origin}
                                                            onChange={(e) => handleHistoryInputChange("origin", e.target.value, historyIndex, /^[A-Z]{3}$/)}
                                                            isInvalid={!!errors[`origin-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`origin-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId={`weight-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Weight</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.weight ?? history.weight}
                                                            onChange={(e) => handleHistoryInputChange("weight", e.target.value, historyIndex, /^\d+$/)}
                                                            isInvalid={!!errors[`weight-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`weight-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group controlId={`station-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Station</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.station ?? history.station}
                                                            onChange={(e) => handleHistoryInputChange("station", e.target.value, historyIndex, /^[A-Z]{3}$/)}
                                                            isInvalid={!!errors[`station-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`station-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId={`flightNo-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Flight Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.flightNo ?? history.flightNo}
                                                            onChange={(e) => handleHistoryInputChange("flightNo", e.target.value, historyIndex, /^[A-Za-z]{2}\d+\s[A-Za-z]$/)}
                                                            isInvalid={!!errors[`flightNo-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`flightNo-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId={`destination-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Destination</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.destination ?? history.destination}
                                                            onChange={(e) => handleHistoryInputChange("destination", e.target.value, historyIndex, /^[A-Z]{3}$/)}
                                                            isInvalid={!!errors[`destination-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`destination-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId={`pieces-${awbIndex}-${historyIndex}`}>
                                                        <Form.Label>Pieces</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={editedHistories[historyIndex]?.pieces ?? history.pieces}
                                                            onChange={(e) => handleHistoryInputChange("pieces", e.target.value, historyIndex, /^\d+$/)}
                                                            isInvalid={!!errors[`pieces-${historyIndex}`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`pieces-${historyIndex}`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                <Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={handlePrevious}>
                                            Previous
                                        </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={handleNext}>
                                            Next
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        )}
                        {section === 3 && (
                            <div>

                                <Row className="my-2">
                                    <Col md={6}>
                                        <Form.Group controlId={`awbNumber-${awbIndex}`}>
                                            <Form.Label>AWB Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={awbItem.awbNumber}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`origin-${awbIndex}`}>
                                            <Form.Label>Origin</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={editedHeader?.origin ?? awbItem.origin}
                                                onChange={(e) => handleHeaderInputChange("origin", e.target.value, /^[A-Z]{3}$/)}
                                                isInvalid={!!errors.origin}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.origin}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId={`weight-${awbIndex}`}>
                                            <Form.Label>Weight</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={editedHeader?.weight ?? awbItem.weight}
                                                onChange={(e) => handleHeaderInputChange("weight", e.target.value, /^\d+$/)}
                                                isInvalid={!!errors.weight}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.weight}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {/* Add more header fields here */}
                                    </Col>
                                    <Col md={6}>
                                        {/* Add more header fields here */}
                                        <Form.Group controlId={`trackingStatus-${awbIndex}`}>
                                            <Form.Label>Tracking Status</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={editedHeader?.trackingStatus ?? ''}
                                                onChange={(e) => handleHeaderInputChange("trackingStatus", e.target.value, null)}
                                            >
                                                <option value="">Select Tracking Status</option>
                                                {standardMilestones.map((milestone, index) => (
                                                    <React.Fragment key={index}>
                                                        <option value={milestone}>{milestone}</option>
                                                        <option value={milestone.trim() + " "}>{milestone} [ ]</option>
                                                    </React.Fragment>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId={`destination-${awbIndex}`}>
                                            <Form.Label>Destination</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={editedHeader?.destination ?? awbItem.destination}
                                                onChange={(e) => handleHeaderInputChange("destination", e.target.value, /^[A-Z]{3}$/)}
                                                isInvalid={!!errors.destination}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.destination}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId={`pieces-${awbIndex}`}>
                                            <Form.Label>Pieces</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={editedHeader?.pieces ?? awbItem.pieces}
                                                onChange={(e) => handleHeaderInputChange("pieces", e.target.value, /^\d+$/)}
                                                isInvalid={!!errors.pieces}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.pieces}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={handlePrevious}>
                                            Previous
                                        </Button>
                                    </Col><Col xs="auto">
                                        <Button variant="success" onClick={() => handleSaveChanges(awbIndex)}>
                                            Save Changes
                                        </Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="secondary" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}

                    </Form>
                ))}
            </Container>
        </div>

    );

};

export default EditAWBForm;
