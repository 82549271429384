import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import Select from "react-select";
import { toastTypes } from "../../../utils/toastTypes";

export interface AirlineDetailsProps {
  hideDropDown?: boolean;
}

export const AirlineDetails = ({
  hideDropDown = false,
}: AirlineDetailsProps) => {
  const [history, setHistory] = useState<any>(undefined);
  const [fetchDetailsInProgress, setFetchDetailsInProgress] =
    useState<boolean>(false);
  const [fetchHistoryInProgress, setFetchHistoryInProgress] =
    useState<boolean>(false);
  const [editDataSource, setEditDataSource] = useState<any>();
  const [dataSource, setDataSource] = useState<any>();
  const [name, setName] = useState<string>();
  const [airlineCodeState, setAirlineCodeState] = useState<string>();
  const [carrierCode, setCarrierCode] = useState<string>();
  const [dateFormatTypesId, setdateFormatTypesId] = useState<string>();

  function handleEditDataSource(value: any, dataSource: any) {
    setEditDataSource(value);
    setDataSource(dataSource);
  }

  async function handleUpdateDataSource(value: string) {
    setAirlineCodeState(value);
    let airlineCode = value;
    let dateFormatTypesId = "";
    // await lupaService
    //   .updateAirline({ airlineCode, dataSource, name, carrierCode, dateFormatTypesId })
    //   ?.then((response) => {
    //     response
    //       .json()
    //       .then((data) => {
    //         console.log(data);
    //         if (data.isSuccess) {
    //           setEditDataSource(null);
    //           setDataSource(null);
    //           return toastTypes.success("Updated SuccesFully");
    //         } else {
    //           return toastTypes.error(data.data.message);
    //         }
    //       })
    //       .catch((error) => {
    //         return toastTypes.error(error);
    //       });
    //   });
  }

  function handleCancelDataSource() {
    setEditDataSource(null);
    setDataSource(null);
  }

  function handleDataSourceValue(data: any) {
    setDataSource(data.value);
  }

  const options = [
    { value: "gocomet", label: "GoComet" },
    { value: "scraping", label: "Scrapping" },
    { value: "parcel", label: "Parcel" },
  ];
  const fetchHistory = () => {
    setFetchHistoryInProgress(true);
    lupaService.getAvailableAirlines()?.then((r) => {
      const data = r.data;
      setHistory(data.availableAirlines);
      setFetchHistoryInProgress(false);
    });
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const spinnerDetailsBlock = (
    <Container
      fluid={true}
      className="position-absolute top-50 start-50 translate-middle mx-2 col-sm-6"
    >
      <Row>
        <Col className="col-1">
          <Spinner animation="border" className="text-muted"></Spinner>
        </Col>
        <Col className="col-4">
          <h5 className="text-muted">Fetching Tracking Details...</h5>
        </Col>
      </Row>
    </Container>
  );

  const spinnerHistoryBlock = (
    <Container
      fluid={true}
      className="position-absolute top-50 start-50 translate-middle mx-2 col-sm-6"
    >
      <Row>
        <Col className="col-1">
          <Spinner animation="border" className="text-muted"></Spinner>
        </Col>
        <Col className="col-4">
          <h5 className="text-muted">Fetching Tracking History...</h5>
        </Col>
      </Row>
    </Container>
  );

  const detailsBlock = (
    <Container className="mx-0">
      <Row className="col-12 mx-1 my-1">
        <Col className="col-6">
          <Col className="col-4">
            <h6>AWB</h6>
          </Col>
          <Col className="col-8">{/* <span>{details?.awbNumber}</span> */}</Col>
        </Col>
        <Col className="col-6">
          <Col className="col-4">
            <h6>Weight (Kg)</h6>
          </Col>
          <Col className="col-8">{/* <span>{details?.weight}</span> */}</Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Col className="col-6">
          <Col className="col-4">
            <h6>Flight</h6>
          </Col>
          <Col className="col-8">
            <span>{/* {details?.station} - {details?.destination} */}</span>
          </Col>
        </Col>
        <Col className="col-6">
          <Col className="col-4">
            <h6>No of Pieces</h6>
          </Col>
          <Col className="col-8">{/* <span>{details?.pieces}</span> */}</Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Col className="col-6">
          <Col className="col-4">
            <h6>Last Activity</h6>
          </Col>
          <Col className="col-8"></Col>
        </Col>
      </Row>
    </Container>
  );

  const historyBlock = (
    <Container className="mx-0">
      <Row className="col-12 ms-1 mx-1 my-1">
        <Col className="col-6"></Col>
        <Col className="col-6 pe-0">
          <Col className="col-4"></Col>
          <Col className="col-8 text-end text-muted ms-auto">
            <small>{history?.length} Airlines</small>
          </Col>
        </Col>
      </Row>
      <Row className="col-12 mx-1 my-2">
        <Container className="overflow-auto" style={{ height: 500 }}>
          <Table className="table rounded shadow table-fixed mt-2 border border-2">
            <thead className="text-start rounded border table-header">
              <tr>
                <th scope="col" className="ps-1">
                  Name
                </th>
                <th scope="col" className="ps-1">
                  Airline Code
                </th>
                <th scope="col" className="ps-1">
                  Carrier Code
                </th>
                {!hideDropDown && (
                  <th scope="col" className="ps-1">
                    Data Source
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {history?.map((h: any, index: any) => {
                return (
                  <tr key={index}>
                    <td className="ps-1">{h.name}</td>
                    <td className="ps-1">{h.airlineCode}</td>
                    <td className="ps-1">{h.carrierCode}</td>

                    {!hideDropDown &&
                      (index !== editDataSource ? (
                        <td className="ps-1">
                          {h.dataSource}&nbsp;{" "}
                          <i
                            className="bi bi-pencil-square"
                            onClick={() =>
                              handleEditDataSource(index, h.dataSource)
                            }
                          ></i>
                        </td>
                      ) : (
                        <td className="ps-1">
                          {" "}
                          <Select
                            options={options}
                            className="float-start"
                            placeholder="Choose Data Source"
                            onChange={(event) => handleDataSourceValue(event)}
                          />
                          &nbsp;{" "}
                          <i
                            onClick={() => handleCancelDataSource()}
                            style={{ cursor: "pointer" }}
                            className="bi bi-x-circle-fill text-danger"
                          ></i>
                          &nbsp;{" "}
                          <i
                            onClick={() =>
                              handleUpdateDataSource(h.airlineCode)
                            }
                            style={{ cursor: "pointer" }}
                            className="bi bi-check-square-fill text-success"
                          ></i>
                        </td>
                      ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Row>
    </Container>
  );

  const dialog = (
    <Container className="mx-0">
      {fetchHistoryInProgress && spinnerHistoryBlock}
      {!fetchHistoryInProgress && historyBlock}
    </Container>
  );

  return dialog;
};
