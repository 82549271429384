import React, { useState } from "react";
import AuthDetailsCard from "../../foundation/AuthDetailsCard";
import DecoratedLink from "../../common/DecoratedLink";
import { useNavigate } from "react-router-dom";
import userService from "../../../services/user";
import { validateEmail } from "../../../utils/common";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

function ForgotPassword() {
  const [email, setEmail] = useState<string | undefined>();
  const [submitMessage, setSubmitMessage] = useState<string | undefined>();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleEmail(value: string) {
    setEmail(value);
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (email === undefined) {
      toast.error("Please enter the email");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter the valid email");
      return;
    }
    setIsSubmiting(true);
    userService
      .forgotPassword({ email })
      .then((response) => {
        setSubmitMessage(response.data.message);
        setIsSubmiting(false);
      })
      .catch((error) => {
        setIsSubmiting(false);
        toast.error(error.response.data.message);
      });
  };
  return (
    <AuthDetailsCard title={"Forgot Password"}>
      <div className="form-group mt-3">
        <div>
          Enter your email and we will send a link to reset your password
        </div>
        <br />
        <label>Email address</label>
        <input
          type="email"
          value={email}
          onChange={(event) => handleEmail(event.target.value)}
          className="form-control mt-1"
          placeholder="Enter email"
        />
      </div>
      {submitMessage && (
        <div className="alert alert-success mt-3" role="alert">
          {submitMessage}
        </div>
      )}
      <div className="d-grid gap-2 mt-3">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={(e) => handleSubmit(e)}
          disabled={isSubmiting}
        >
          {isSubmiting ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only"></span>
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
      <div
        className="d-flex gap-2 mt-3 border px-2 py-2 rounded justify-content-center"
        role="button"
        onClick={() => {
          navigate("/login");
        }}
      >
        <i className="bi bi-arrow-left"></i>
        <DecoratedLink href="/login">Back to Login</DecoratedLink>
      </div>
    </AuthDetailsCard>
  );
}

export default ForgotPassword;
