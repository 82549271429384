import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import shipperService from "../../../../services/shipper";
import userService from "../../../../services/user";
import {
  shipperInitialData,
  shipperUserInitialData,
  shipperUserValidationSchema,
} from "../../../../utils/formValidation";
import { errorHandler } from "../../../../utils/error";
import { toastTypes } from "../../../../utils/toastTypes";

function AccountCreate() {
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let isAccountCreateRoute = window.location.pathname.includes('create')
  console.log(isAccountCreateRoute)
  const [defaultValue, setDefaultValue] = useState(isAccountCreateRoute ? {...shipperUserInitialData , password : ''} : shipperUserInitialData)
  if (!token) navigate("/login");
  const [email , setEmail] = useState('')
  useEffect(() => {
    userService.verfiyToken(token ? token : '').then( (response) => {
      const { isValid, isExpired, email } = response
      if(!isValid || isExpired || !email) navigate("/login")
        setEmail(email)
        setDefaultValue((prev) => ({...prev, email : email}))
    })
  },[token])

  // useEffect(() => {
  //   shipperInitialData.email = email
  // },[email])
  const formik = useFormik({
    initialValues: defaultValue,
    enableReinitialize : true,
    onSubmit: async (values, { setSubmitting }) => {
      userService
        .createUser({ ...values, token :(token ? token : '')})
        .then(() => {
          setSubmitting(false);
          toastTypes.success("User has been successfully Added")
          setTimeout(() => navigate("/login"),5000)
        })
        .catch(function(error){
          errorHandler(error)
        });
    },
    validationSchema: shipperUserValidationSchema,
  });
  return (
    <div className="w-50 my-4 py-4 mx-2">
      <h5 className="section-title mx-4">Create Shipper Account</h5>
      <div className="d-flex justify-content-center mx-4 mt-2 mb-4 flex-column">
        <Card className="p-3 col-sm-12">
          <FormikProvider value={formik}>
            <Form className="my-auto">
              <div className="d-flex">
                <div className="form-group my-2 w-100 mx-2">
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    name="firstName"
                    className={
                      formik.touched.firstName && formik.errors.firstName
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="invalid-feedback">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group my-2 w-100">
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    name="lastName"
                    className={
                      formik.touched.lastName && formik.errors.lastName
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="invalid-feedback">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group my-2 w-100 ms-2">
                  <label htmlFor="email">Email Address</label>
                  <Field
                    name="email"
                    disabled
                    // value={email}
                    className={
                      formik.touched.email && formik.errors.email
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="email"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
              {isAccountCreateRoute && <div className="m-2 w-100">
                <label htmlFor="password">Password</label>
                <Field
                  name="password"
                  type="password"
                  className={
                    formik.touched.password && formik.errors.password
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>}
              <div className="w-100 d-flex">
                <div className="w-40 ms-2">
                  <label htmlFor="countryCode">Country Code</label>
                  <Field
                    name="countryCode"
                    type="text"
                    className={
                      formik.touched.countryCode && formik.errors.countryCode
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {formik.touched.countryCode && formik.errors.countryCode ? (
                    <div className="invalid-feedback">
                      {formik.errors.countryCode}
                    </div>
                  ) : null}
                </div>
                <div className="w-60 ms-2">
                  <label htmlFor="phone">Phone Number</label>
                  <Field
                    name="phone"
                    type="text"
                    className={
                      formik.touched.phone && formik.errors.phone
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="invalid-feedback">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="my-2 mx-2 w-100">
                <label htmlFor="baseLocation">Base Location</label>
                <Field
                  name="baseLocation"
                  type="text"
                  className={
                    formik.touched.baseLocation && formik.errors.baseLocation
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {formik.touched.baseLocation && formik.errors.baseLocation ? (
                  <div className="invalid-feedback">
                    {formik.errors.baseLocation}
                  </div>
                ) : null}
              </div>

              <div className="form-group mt-4 ms-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Please wait..." : "Create Account"}
                </button>
              </div>
            </Form>
          </FormikProvider>
        </Card>
      </div>
    </div>
  );
}

export default AccountCreate;
