import { FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  PendingTrackingsAvailableForTheMonth,
  TrackingSummary,
} from "../../../models/TrackingSummary";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { useAuthContext } from "../../../hooks/useAuth";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";
import { AirlineDetails } from "../tracking/AirlineDetails";
import { SearchAwb } from "../tracking/SearchAwb";
import { TrackingDetails } from "../tracking/TrackingDetails";
import ClientNavbar from "../../foundation/Navbar";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);
export const TrackingDashboard: FC<{ socket?: Socket<any, any> }> = (props) => {
  type ShowDetailsState = {
    show: boolean;
    trackingId: string;
    awb: TrackingSummary | null;
  };

  const defaultDetailsState = {
    show: false,
    trackingId: "",
    awb: null,
  };

  const defaultPendingTrackingState = {
    totalLimitForTheMonth: 0,
    trackingsLeft: 0,
    trackingsUsed: 0,
  };

  const [trackings, setTrackings] = useState<TrackingSummary[]>([]);
  const [fetchInProgress, setFetchInProgress] = useState<boolean>(true);
  const [pendingTracking, setPendingTracking] =
    useState<PendingTrackingsAvailableForTheMonth>(defaultPendingTrackingState);
  const [showDetailsState, setShowDetailsState] =
    useState<ShowDetailsState>(defaultDetailsState);
  const [showAirlineModal, setShowAirlineModal] = useState<boolean>(false);
  const [awbList, setAwbList] = useState<string[]>([]);
  const navigate = useNavigate();
  const { logout, role } = useAuthContext();
  const [airlineCode, setAirlineCode] = useState("");
  const [name, setName] = useState("");
  const [dataSource, setDataSource] = useState("");
  const [addForm, setAddForm] = useState(false);
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };
  const options = [
    { value: "gocomet", label: "GoComet" },
    { value: "scrapping", label: "Scrapping" },
    { value: "parcel", label: "Parcel" },
  ];
  const labels = ["February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Api Usage",
        data: [43, 64, 36, 1, 91, 17],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const data1 = {
    labels: ["Used", "Remaining"],
    datasets: [
      {
        label: "# of APi Request",
        data: [3, 4],
        backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderWidth: 1,
      },
    ],
  };

  function handleAddForm() {
    setAddForm(true);
  }

  function handleAirlineName(value: string) {
    setName(value);
  }
  function handleAirlineCode(value: string) {
    setAirlineCode(value);
  }
  function handleDataSource(value: string) {
    setDataSource(value);
  }
  const handleAddAirline = () => {
    let dateFormatTypesId = "";
    // lupaService
    //   .addAirline({ name, airlineCode, dataSource, dateFormatTypesId })
    //   ?.then((response) => {
    //     response.json().then((data) => {
    //       // console.log(data);
    //       setAddForm(false);
    //       return toastTypes.success("New Airline Addedd");
    //     });
    //   });
  };
  const fetchData = (): void => {
    lupaService.getLinkedAwb()?.then((r) => {
      const data = r.data;
      console.log(data);
      setTrackings(data.userTrackings.map((tracking: any) => tracking));
      setFetchInProgress(false);
    });
    checkPendingTracking();
  };

  const addAwbToTracking = async (awbNumber: string) => {
    toastTypes.info("Fetching AWB details", true);
    const { data } = await lupaService.addAwbToTracking({ awbNumber });
    console.log(data.data);
    toast.dismiss();
    if (!data.isSuccess) {
      toastTypes.error(data.message);
      return false;
    }
    toastTypes.success("AWB added to tracking successfully");
    fetchData();
    checkPendingTracking();
    return true;
  };
  console.log({ trackings });
  const checkPendingTracking = () => {
    lupaService.pendingTrackingsAvailableForTheMonth()?.then((r) => {
      const data = r.data;
      console.log(data.data);
      setPendingTracking(data.data);
    });
  };
  const onLogout = () => {
    logout();
  };

  const socketCallback = (data: any) => {
    console.log("new tracking", data, awbList);
    console.log(awbList.some((awb) => awb === data.awbNumber));
    if (data.awbNumber !== undefined && awbList.includes(data.awbNumber)) {
      const oldState = trackings.filter(
        (tracking) => tracking.awbNumber === data.awbNumber
      )[0].trackingStatus;
      console.log({ oldState });
      if (oldState !== data.status) {
        toastTypes.info(`${data.awbNumber} ${data.status}`);
        if ("Notification" in window) {
          // The Notification API is supported
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              new Notification(`${data.awbNumber} ${data.status}`);
            }
          });
        }

      }
      fetchData();
    }
  };
  const handleHomeClick = () => {
    navigate("/dashboard");
  };
  const handleUserList = () => {
    navigate("/userlist");
  };

  useEffect(() => {
    if (role && role[0] === "demoAPIUser") {
      fetchData();
    } else navigate("/dashboard");
  }, [role]);
  // useEffect(() => {
  //   //checkPendingTracking();
  //   Notification.requestPermission();
  //   props?.socket?.on("socketio-message", socketCallback);

  //   return () => {
  //     props?.socket?.off("socketio-message", socketCallback);
  //   };
  // }, [awbList, trackings]);

  const onRefresh = () => {
    fetchData();
    checkPendingTracking();
  };

  const onShowDetails = (trackingId: string, awb: TrackingSummary) => {
    setShowDetailsState({ show: true, trackingId: trackingId, awb: awb });
  };

  const spinnerBlock = (
    <Container
      fluid={true}
      className="position-absolute top-50 start-50 translate-middle mx-2 col-sm-6"
    >
      <Row className="d-flex justify-content-center">
        <Col className="col-1">
          <Spinner animation="border" className="text-muted"></Spinner>
        </Col>
        <Col className="col-4">
          <h5 className="text-muted">Fetching Tracking Summary...</h5>
        </Col>
      </Row>
    </Container>
  );

  const trackingSummaryTable = (
    <Table className="table border border-2 table-hover mt-2">
      <thead className="text-start table-header">
        <tr>
          <th scope="col" style={{ minWidth: "150px" }}>
            AWB <i className="fa fa-fw fa-sort"></i>
          </th>
          <th scope="col">Status</th>
          <th scope="col">Pcs</th>
          <th scope="col">Weight</th>
          <th scope="col">Flight</th>
          <th scope="col">Origin</th>
          <th scope="col">Destination</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {trackings?.length > 0 &&
          trackings?.map((summary) => {
            if (!summary) return null;
            return (
              <tr key={summary.awbNumber}>
                <th scope="row">
                  <a
                    href="#"
                    onClick={(e) =>
                      onShowDetails(summary.userTrackingId, summary)
                    }
                  >
                    {summary.awbNumber}
                  </a>
                </th>
                <td>{summary.trackingStatus}</td>
                <td>{summary.pieces}</td>
                <td>{summary.weight}</td>
                <td>
                  {summary.flightsEtdEta
                    .filter((flight) => flight.flightNumber)
                    .map((flight) => flight.flightNumber)
                    .join(", ")}
                </td>
                <td>{summary.origin}</td>
                <td>{summary.destination}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      lupaService
                        .deleteAwbTracking({ awbNumber: summary.awbNumber })
                        .then((r) => {
                          console.log(r);
                          toastTypes.success("AWB Deleted Successfully");
                          fetchData();
                        });
                    }}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        {trackings?.length <= 0 && (
          <td colSpan={8} className="text-center">
            <h6 className="text-muted">No Trackings Found</h6>
          </td>
        )}
      </tbody>
    </Table>
  );

  const detailsBlock = (
    <Modal
      size="lg"
      centered
      show={showDetailsState.show}
      onHide={() => setShowDetailsState(defaultDetailsState)}
    >
      <Modal.Header closeButton={true} className="mx-2 px-4">
        <h5 className="section-title">
          Tracking Details - {showDetailsState.awb?.awbNumber}
        </h5>
      </Modal.Header>
      <Modal.Body className="px-0">
        <TrackingDetails
          trackingId={showDetailsState.awb?.awbNumber || "000-00000000"}
          awbDetails={showDetailsState.awb}
        />
      </Modal.Body>
    </Modal>
  );

  const airlineData = (
    <Modal
      size="lg"
      centered
      show={showAirlineModal}
      onHide={() => setShowAirlineModal(false)}
    >
      <Modal.Header closeButton={true} className="mx-2 px-4">
        <h5 className="section-title">
          Airline Details {showDetailsState.awb?.awbNumber}
          <span className="text-right"></span>
        </h5>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Container>
          <Row className="col-12 ms-1 mx-1 my-1">
            <Col className="col-6"></Col>
            <Col className="col-6 pe-0">
              <Col className="col-4"></Col>
              {role && role[0] === "apiAdmin" && (
                <Col className="col-8 text-end ms-auto">
                  {!addForm && (
                    <Button className="primary" onClick={handleAddForm}>
                      Add AirLine
                    </Button>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Container>

        {addForm && (
          <Container>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Airline Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Name"
                  value={name}
                  onChange={(event) => handleAirlineName(event.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Airline Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Last Name"
                  value={airlineCode}
                  onChange={(event) => handleAirlineCode(event.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Data Source</Form.Label>
                {/* <Form.Control type="text"  value={dataSource} /> */}
                <Select
                  options={options}
                  placeholder="Choose Data Source"
                  onChange={(event) =>
                    handleDataSource(event ? event.value : "")
                  }
                />
              </Form.Group>
            </Form>
            <Button variant="success" onClick={handleAddAirline}>
              Submit
            </Button>
          </Container>
        )}
        {/* <TrackingDetails trackingId={showDetailsState.awb} /> */}
        {!addForm && <AirlineDetails hideDropDown />}
      </Modal.Body>
    </Modal>
  );

  return (
    <Container fluid className="m-0 p-0">
      <ClientNavbar />

      <Row>
        <SearchAwb addAwbToTracking={addAwbToTracking} />
      </Row>

      <Row
        className="mt-2 mx-2 position-relative"
        style={fetchInProgress ? { minHeight: "300px" } : {}}
      >
        {!fetchInProgress && trackingSummaryTable}
        {fetchInProgress && spinnerBlock}
      </Row>
      {detailsBlock}
      {airlineData}
    </Container>
  );
};
