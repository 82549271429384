import { useEffect, useState } from "react";
import WebhookUiService from "../../../../services/webhook-ui";
import { useParams } from "react-router-dom";
import ClientNavbar from "../../../foundation/Navbar";
import "./ComparisonTable.css";

const DataView: React.FC = () => {
  const { awbNumber } = useParams();
  const [scrapingData, setScrapingData] = useState<any>(null);
  const [parcelData, setParcelData] = useState<any>(null);
  const [gocometData, setGocometData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  async function getDataDetail() {
    try {
      const response = await WebhookUiService.getDataCompareRaw(
        awbNumber || ""
      );
      // if (!response) {
      //   throw new Error("No valid data received");
      // }
      // Ensure the data is a string before parsing
      setScrapingData(
        typeof response.scrapingData === "string"
          ? JSON.parse(response.scrapingData)
          : response.scrapingData
      );
      setParcelData(
        typeof response.parcelData === "string"
          ? JSON.parse(response.parcelData)
          : response.parcelData
      );
      setGocometData(
        typeof response.gocometData === "string"
          ? JSON.parse(response.gocometData)
          : response.gocometData
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataDetail();
  }, [awbNumber]);

  const checkMatch = (dataField: string, key?: string) => {
    const scrapeValue = key
      ? scrapingData?.[dataField]?.[key]
      : scrapingData?.[dataField];
    const parcelValue = key
      ? parcelData?.[dataField]?.[key]
      : parcelData?.[dataField];
    const gocometValue = key
      ? gocometData?.[dataField]?.[key]
      : gocometData?.[dataField];

    const availableValues = [scrapeValue, parcelValue, gocometValue].filter(
      (val) => val !== undefined && val !== null
    );

    // Only compare if at least two sources have data
    if (availableValues.length < 2) {
      return "unknown"; // Not enough data to compare
    }

    // Compare the available values
    const allMatch = availableValues.every((val) => val === availableValues[0]);

    return allMatch ? "match" : "mismatch";
  };

  const renderFlightDetailsComparison = () => {
    if (!scrapingData && !parcelData && !gocometData) return null;

    const maxLength = Math.max(
      scrapingData?.flightsEtdEta?.length || 0,
      parcelData?.flightsEtdEta?.length || 0,
      gocometData?.flightsEtdEta?.length || 0
    );

    return Array.from({ length: maxLength }).map((_, index: number) => {
      const flight = scrapingData?.flightsEtdEta?.[index] || {};
      const parcelFlight = parcelData?.flightsEtdEta?.[index] || {};
      const gocometFlight = gocometData?.flightsEtdEta?.[index] || {};

      const flightNumberMatch =
        ([
          flight?.flightNumber,
          parcelFlight?.flightNumber,
          gocometFlight?.flightNumber,
        ].filter((val) => val !== undefined && val !== null).length > 1 &&
          flight?.flightNumber === parcelFlight?.flightNumber &&
          flight?.flightNumber === gocometFlight?.flightNumber) ||
        (flight?.flightNumber === "" &&
          parcelFlight?.flightNumber === "" &&
          gocometFlight?.flightNumber === "");

      const flightDateMatch =
        ([
          flight?.flightDate,
          parcelFlight?.flightDate,
          gocometFlight?.flightDate,
        ].filter((val) => val !== undefined && val !== null).length > 1 &&
          flight?.flightDate === parcelFlight?.flightDate &&
          flight?.flightDate === gocometFlight?.flightDate) ||
        (flight?.flightDate === "" &&
          parcelFlight?.flightDate === "" &&
          gocometFlight?.flightDate === "");

      const originMatch =
        ([flight?.origin, parcelFlight?.origin, gocometFlight?.origin].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          flight?.origin === parcelFlight?.origin &&
          flight?.origin === gocometFlight?.origin) ||
        (flight?.origin === "" &&
          parcelFlight?.origin === "" &&
          gocometFlight?.origin === "");

      const destinationMatch =
        ([
          flight?.destination,
          parcelFlight?.destination,
          gocometFlight?.destination,
        ].filter((val) => val !== undefined && val !== null).length > 1 &&
          flight?.destination === parcelFlight?.destination &&
          flight?.destination === gocometFlight?.destination) ||
        (flight?.destination === "" &&
          parcelFlight?.destination === "" &&
          gocometFlight?.destination === "");
      return (
        <>
          <tr key={index}>
            <td>Flight {index + 1}</td>
            <td>{flight?.flightNumber || "-"}</td>
            <td>{parcelFlight?.flightNumber || "-"}</td>
            <td>{gocometFlight?.flightNumber || "-"}</td>
            <td className={flightNumberMatch ? "match" : "mismatch"}>
              {flightNumberMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`date-${index}`}>
            <td>Flight Date</td>
            <td>{flight?.flightDate || "-"}</td>
            <td>{parcelFlight?.flightDate || "-"}</td>
            <td>{gocometFlight?.flightDate || "-"}</td>
            <td className={flightDateMatch ? "match" : "mismatch"}>
              {flightDateMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`origin-${index}`}>
            <td>Origin</td>
            <td>{flight?.origin || "-"}</td>
            <td>{parcelFlight?.origin || "-"}</td>
            <td>{gocometFlight?.origin || "-"}</td>
            <td className={originMatch ? "match" : "mismatch"}>
              {originMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`destination-${index}`}>
            <td>Destination</td>
            <td>{flight?.destination || "-"}</td>
            <td>{parcelFlight?.destination || "-"}</td>
            <td>{gocometFlight?.destination || "-"}</td>
            <td className={destinationMatch ? "match" : "mismatch"}>
              {destinationMatch ? "✓" : "✗"}
            </td>
          </tr>
        </>
      );
    });
  };

  const renderEventDetailsComparison = () => {
    if (!scrapingData && !parcelData && !gocometData) return null;

    const maxLength = Math.max(
      scrapingData?.events?.length || 0,
      parcelData?.events?.length || 0,
      gocometData?.events?.length || 0
    );

    return Array.from({ length: maxLength }).map((_, index: number) => {
      const event = scrapingData?.events?.[index] || {};
      const parcelEvent = parcelData?.events?.[index] || {};
      const gocometEvent = gocometData?.events?.[index] || {};

      const milestoneMatch =
        ([
          event?.milestone,
          parcelEvent?.milestone,
          gocometEvent?.milestone,
        ].filter((val) => val !== undefined && val !== null).length > 1 &&
          event?.milestone === parcelEvent?.milestone &&
          event?.milestone === gocometEvent?.milestone) ||
        (event?.milestone === "" &&
          parcelEvent?.milestone === "" &&
          gocometEvent?.milestone === "");

      const dateMatch =
        ([event?.date, parcelEvent?.date, gocometEvent?.date].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          event?.date === parcelEvent?.date &&
          event?.date === gocometEvent?.date) ||
        (event?.date === "" &&
          parcelEvent?.date === "" &&
          gocometEvent?.date === "");

      const originMatch =
        ([event?.origin, parcelEvent?.origin, gocometEvent?.origin].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          event?.origin === parcelEvent?.origin &&
          event?.origin === gocometEvent?.origin) ||
        (event?.origin === "" &&
          parcelEvent?.origin === "" &&
          gocometEvent?.origin === "");

      const stationMatch =
        ([event?.station, parcelEvent?.station, gocometEvent?.station].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          event?.station === parcelEvent?.station &&
          event?.station === gocometEvent?.station) ||
        (event?.station === "" &&
          parcelEvent?.station === "" &&
          gocometEvent?.station === "");

      const destinationMatch =
        ([
          event?.destination,
          parcelEvent?.destination,
          gocometEvent?.destination,
        ].filter((val) => val !== undefined && val !== null).length > 1 &&
          event?.destination === parcelEvent?.destination &&
          event?.destination === gocometEvent?.destination) ||
        (event?.destination === "" &&
          parcelEvent?.destination === "" &&
          gocometEvent?.destination === "");

      const piecesMatch =
        ([event?.pieces, parcelEvent?.pieces, gocometEvent?.pieces].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          event?.pieces == parcelEvent?.pieces &&
          event?.pieces == gocometEvent?.pieces) ||
        (event?.pieces == "" &&
          parcelEvent?.pieces == "" &&
          gocometEvent?.pieces == "");

      const weightMatch =
        ([event?.weight, parcelEvent?.weight, gocometEvent?.weight].filter(
          (val) => val !== undefined && val !== null
        ).length > 1 &&
          event?.weight === parcelEvent?.weight &&
          event?.weight === gocometEvent?.weight) ||
        (event?.weight === "" &&
          parcelEvent?.weight === "" &&
          gocometEvent?.weight === "");

      return (
        <>
          <tr key={index}>
            <td>Event Milestone {index + 1}</td>
            <td>{event?.milestone || "-"}</td>
            <td>{parcelEvent?.milestone || "-"}</td>
            <td>{gocometEvent?.milestone || "-"}</td>
            <td className={milestoneMatch ? "match" : "mismatch"}>
              {milestoneMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`date-${index}`}>
            <td>Date</td>
            <td>{event?.date || "-"}</td>
            <td>{parcelEvent?.date || "-"}</td>
            <td>{gocometEvent?.date || "-"}</td>
            <td className={dateMatch ? "match" : "mismatch"}>
              {dateMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`origin-${index}`}>
            <td>Origin</td>
            <td>{event?.origin || "-"}</td>
            <td>{parcelEvent?.origin || "-"}</td>
            <td>{gocometEvent?.origin || "-"}</td>
            <td className={originMatch ? "match" : "mismatch"}>
              {originMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`station-${index}`}>
            <td>Station</td>
            <td>{event?.station || "-"}</td>
            <td>{parcelEvent?.station || "-"}</td>
            <td>{gocometEvent?.station || "-"}</td>
            <td className={stationMatch ? "match" : "mismatch"}>
              {stationMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`destination-${index}`}>
            <td>Destination</td>
            <td>{event?.destination || "-"}</td>
            <td>{parcelEvent?.destination || "-"}</td>
            <td>{gocometEvent?.destination || "-"}</td>
            <td className={destinationMatch ? "match" : "mismatch"}>
              {destinationMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`pieces-${index}`}>
            <td>Pieces</td>
            <td>{event?.pieces || "-"}</td>
            <td>{parcelEvent?.pieces || "-"}</td>
            <td>{gocometEvent?.pieces || "-"}</td>
            <td className={piecesMatch ? "match" : "mismatch"}>
              {piecesMatch ? "✓" : "✗"}
            </td>
          </tr>
          <tr key={`weight-${index}`}>
            <td>Weight</td>
            <td>{event?.weight || "-"}</td>
            <td>{parcelEvent?.weight || "-"}</td>
            <td>{gocometEvent?.weight || "-"}</td>
            <td className={weightMatch ? "match" : "mismatch"}>
              {weightMatch ? "✓" : "✗"}
            </td>
          </tr>
        </>
      );
    });
  };

  if (loading) {
    return <div>Loading data...</div>;
  }

  return (
    <div>
      <ClientNavbar />
      <div className="center">
        <div className="comparison-table">
          <h3>Header Details</h3>
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Scraping</th>
                <th>Parcel</th>
                <th>Gocomet</th>
                <th>Comparison</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-primary">
                <td>AWB Number</td>
                <td>{scrapingData?.awbNumber || "-"}</td>
                <td>{parcelData?.awbNumber || "-"}</td>
                <td>{gocometData?.awbNumber || "-"}</td>
                <td className={checkMatch("awbNumber")}>
                  {checkMatch("awbNumber") === "match" ? "✓" : "✗"}
                </td>
              </tr>
              <tr>
                <td>Tracking Status</td>
                <td>{scrapingData?.trackingStatus || "-"}</td>
                <td>{parcelData?.trackingStatus || "-"}</td>
                <td>{gocometData?.trackingStatus || "-"}</td>
                <td className={checkMatch("origin")}>
                  {checkMatch("origin") === "match" ? "✓" : "✗"}
                </td>
              </tr>
              <tr>
                <td>Origin</td>
                <td>{scrapingData?.origin || "-"}</td>
                <td>{parcelData?.origin || "-"}</td>
                <td>{gocometData?.origin || "-"}</td>
                <td className={checkMatch("origin")}>
                  {checkMatch("origin") === "match" ? "✓" : "✗"}
                </td>
              </tr>
              <tr>
                <td>Destination</td>
                <td>{scrapingData?.destination || "-"}</td>
                <td>{parcelData?.destination || "-"}</td>
                <td>{gocometData?.destination || "-"}</td>
                <td className={checkMatch("destination")}>
                  {checkMatch("destination") === "match" ? "✓" : "✗"}
                </td>
              </tr>
              <tr>
                <td>Pieces</td>
                <td>{scrapingData?.pieces || "-"}</td>
                <td>{parcelData?.pieces || "-"}</td>
                <td>{gocometData?.pieces || "-"}</td>
                <td className={checkMatch("pieces")}>
                  {checkMatch("pieces") === "match" ? "✓" : "✗"}
                </td>
              </tr>
              <tr>
                <td>Weight (Kg)</td>
                <td>{scrapingData?.weight || "-"}</td>
                <td>{parcelData?.weight || "-"}</td>
                <td>{gocometData?.weight || "-"}</td>
                <td className={checkMatch("weight")}>
                  {checkMatch("weight") === "match" ? "✓" : "✗"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="center">
        <div className="comparison-table">
          <h3>Flight Details</h3>
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Scraping</th>
                <th>Parcel</th>
                <th>Gocomet</th>
                <th>Comparison</th>
              </tr>
            </thead>
            <tbody>{renderFlightDetailsComparison()}</tbody>
          </table>
        </div>
      </div>
      <div className="center">
        <div className="comparison-table">
          <h3>Event Details</h3>
          <table>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Scraping</th>
                <th>Parcel</th>
                <th>Gocomet</th>
                <th>Comparison</th>
              </tr>
            </thead>
            <tbody>{renderEventDetailsComparison()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataView;
