import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
  confirmText: string;
  header: string;
  show: boolean;
  onSubmit: () => void;
  onHide: () => void;
};

function ConfirmActionPopup({
  confirmText,
  header,
  onSubmit,
  onHide,
  show,
}: Props): JSX.Element {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmText}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmActionPopup;
