import axios from "axios";
import { apiEndpoints } from "./Utils";
import { GenericResponse } from "../models/Common";

export const hardDeleteAWBDetail = async (
  awbNumber: string
): Promise<GenericResponse<any>> => {
  try {
    return axios.delete(apiEndpoints.DELETE_AWB_DETAIL + "/" + awbNumber);
  } catch (error) {
    throw new Error("Failed to delete awb detail");
  }
};
