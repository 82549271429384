import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Input from "../../common/Input";
import moment from "moment";
import { toast } from "react-toastify";

const label = {
  paymentDate: "Payment Date",
  amount: "Amount (in Rupees)",
  paymentMethod: "Payment Method",
  isFullPayment: "Full Payment",
};

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmit: (args: any) => void;
};

function AddReceipt({ show, onHide, onSubmit }: Props) {
  const [state, setState] = useState({
    paymentDate: moment().format("YYYY-MM-DD"),
    amount: null,
    paymentMethod: null,
    isFullPayment: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "amount" && isNaN(+e.target.value)) return;

    setState({
      ...state,
      [e.target.name]:
        e.target.name === "amount"
          ? e.target.value
            ? +e.target.value
            : null
          : e.target.value,
    });
  };

  const onSubmitClick = () => {
    if (
      !state ||
      !state.paymentDate ||
      !state.amount ||
      !state.paymentMethod ||
      isNaN(state.amount)
    ) {
      toast.error("Invalid Data");
      return;
    }

    if (state.amount) {
      onSubmit(state);
    }
  };

  const generateProps = (name: string, type = "text") => ({
    label: (label as any)[name],
    type: type,
    value: (state as any)[name],
    name: name,
    onChange: handleChange,
  });

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input {...generateProps("amount", "number")} />
        <Input {...generateProps("paymentDate", "date")} />
        <Input {...generateProps("paymentMethod")} />
        {/* <div className="form-group form-check mt-2">
          <label>Full Payment</label>
          <input
            className="form-check-input"
            type="checkbox"
            name="isFullPayment"
            onChange={(e) => {
              setState({ ...state, isFullPayment: e.target.checked });
            }}
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmitClick}>
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddReceipt;
