import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "./useAuth";
import useRole from "./useRole";

export default function RequireAuth() {
  let { user } = useAuthContext();
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export function RequireAdminAccess() {
  let { user } = useAuthContext();
  let { isAdminUser } = useRole();
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!isAdminUser) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}

export function RequireLupaToken() {
  let { lupaJwtToken } = useAuthContext();
  console.log(lupaJwtToken);
  if (!lupaJwtToken) {
    return <Navigate to="/tracking/login" />;
  }

  return <Outlet />;
}

export function RequireUserApiLupaToken() {
  let { userApiLupaJwtToken } = useAuthContext();
  console.log(userApiLupaJwtToken);
  if (!userApiLupaJwtToken) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}
