import React from "react";

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode;
  error?: string;
  label?: string;
  width?: string | number;
}

function Select({
  label,
  id,
  width,
  children,
  error,
  ...rest
}: Props): JSX.Element {
  const arrayChildren = React.Children.toArray(children);
  return (
    <div className={`form-group mt-3`} style={width ? { width: width } : {}}>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className={`mt-1 form-control w-100 ${error ? "is-invalid" : ""}`}
        aria-label="Default select example"
        id={id}
        {...rest}
      >
        {arrayChildren.map((child) => child)}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export default Select;
