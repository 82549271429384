import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import { BillingCustomer, PaymentStatus } from "../../../models/Customer";
import { getAllCustomers } from "../../../services/customer";
import { ProgressBar } from "react-bootstrap";
import StatusIcon from "../../common/StatusIcon";
import RowAlightCenter from "../../common/RowBetween";
import { useNavigate } from "react-router-dom";
import ClientNavbar from "../../foundation/Navbar";

function AdminBilling() {
  const [data, setData] = useState<BillingCustomer[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [totalAWBCount, setTotalAWBCount] = useState(0);
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(`/billing/${id}`);
  };

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      // const url = new URL(
      //   "/api/data",
      //   process.env.NODE_ENV === "production"
      //     ? "https://www.material-react-table.com"
      //     : "http://localhost:3001"
      // );
      // url.searchParams.set(
      //   "start",
      //   `${pagination.pageIndex * pagination.pageSize}`
      // );
      // url.searchParams.set("size", `${pagination.pageSize}`);
      // url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      // url.searchParams.set("globalFilter", globalFilter ?? "");
      // url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      try {
        setIsLoading(true);
        const response = await getAllCustomers();
        setTotalAWBCount(
          response.data.reduce((acc, curr) => acc + curr.awbCount, 0)
        );
        setData(response.data);
        setRowCount(response.data.length);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      } finally {
        setIsLoading(false);
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo<MRT_ColumnDef<BillingCustomer>[]>(
    () => [
      {
        header: "Name",
        accessorFn: (row) => row.firstName + " " + row.lastName,
      },
      {
        header: "Email",
        accessorFn: (row) => (
          <a href="" onClick={() => handleClick(row.id)}>
            {row.email}
          </a>
        ),
      },
      {
        header: `AWB Usage `,
        enableColumnFilter: false,
        enableSorting: false,
        accessorFn(originalRow) {
          return (
            <ProgressBar
              now={(originalRow.awbCount / originalRow.monthlyClaimLimit) * 100}
              label={`${
                (originalRow.awbCount / originalRow.monthlyClaimLimit) * 100
              }%`}
            />
          );
        },
      },
      {
        accessorKey: "awbCount",
        header: `AWB Count - ${totalAWBCount}`,
        filterVariant: "range",
      },
      {
        accessorKey: "monthlyClaimLimit",
        header: "Monthly Usage Limit",
        filterVariant: "range",
      },
      {
        accessorKey: "paymentStatus",
        header: "Payment Status",
        accessorFn(originalRow) {
          return (
            <RowAlightCenter>
              <StatusIcon
                status={
                  originalRow.paymentStatus === PaymentStatus.ONTIME
                    ? "success"
                    : originalRow.paymentStatus === PaymentStatus.DELAYED
                    ? "warning"
                    : "danger"
                }
              />
              {originalRow.paymentStatus}
            </RowAlightCenter>
          );
        },
        // filterVariant: "select",
        // filterFn: "equals",
        // filterSelectOptions: [
        //   { text: "Paid", value: PaymentStatus.ONTIME },
        //   { text: "Unpaid", value: PaymentStatus.OVERDUE },
        //   { text: "Late", value: PaymentStatus.DELAYED },
        // ],
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [totalAWBCount]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    getRowId: (row) => row.email,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <div>
      <ClientNavbar />
      <div className="mt-3 mx-2">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
}

export default AdminBilling;
