import React from "react";
import useRole from "../../../hooks/useRole";
import AdminProfile from "./AdminProfile";
import UserProfile from "./UserProfile";
function ProfileContainer() {
  const { isAdminUser, isDelhiveryAccountsUser } = useRole();
  console.log({ isAdminUser });
  return isAdminUser || isDelhiveryAccountsUser ? (
    <AdminProfile />
  ) : (
    <UserProfile />
  );
}

export default ProfileContainer;
