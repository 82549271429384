import { useEffect, useState } from "react";
import WebhookUiService from "../../../services/webhook-ui";
import { useParams } from "react-router-dom";
import ClientNavbar from "../../foundation/Navbar";

const DataCheck: React.FC = () => {
  const { awbNumber, id } = useParams(); // Extract the customerId from the URL

  const [AWBDetails, setAWBDetails] = useState("");
  const [kafkaData, setKafkaData] = useState("");
  const [header, setHeader] = useState("");
  const [history, setHistory] = useState<string>("");
  const [webhook, setWebhook] = useState<string>("");
  async function getDataDetail() {
    const response = await WebhookUiService.getScrapingRaw(
      awbNumber || "",
      id || ""
    );
    console.log(response);

    if (!response) {
      throw new Error("No valid data received");
    }
    console.log(response.webhook);
    setKafkaData(response.value);
    setAWBDetails(response.awbDetails);
    setHeader(response.scrapingHeader);
    setHistory(response.scrapingHistory);
    setWebhook(response.webhookData);
  }
  useEffect(() => {
    getDataDetail();
  }, []);
  const parseAwbBodyRaw = (rawString: string) => {
    try {
      return JSON.parse(rawString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };
  return (
    <div>
      <ClientNavbar />

      <div className="row">
        <div className="col-2">
          <div className="column-content scrollable">
            <h4>Kafka Data</h4>
            <pre>
              <code>
                {" "}
                {JSON.stringify(parseAwbBodyRaw(kafkaData), null, 2)}
              </code>
            </pre>
          </div>
        </div>
        <div className="col-4">
          <div className="column-content scrollable">
            <h4>Header</h4>
            <pre>
              <code className="text-danger">
                {" "}
                {JSON.stringify(parseAwbBodyRaw(header), null, 2)}
              </code>
            </pre>
            <h4>History</h4>
            <pre>
              <code className="text-danger">
                {" "}
                {JSON.stringify(parseAwbBodyRaw(history), null, 2)}
              </code>
            </pre>
          </div>
        </div>
        <div className="col-4">
          <div className="column-content scrollable">
            <h4>Awb Details</h4>
            <pre>
              <code className="text-primary">
                {JSON.stringify(parseAwbBodyRaw(AWBDetails), null, 2)}
              </code>
            </pre>
          </div>
        </div>
        <div className="col-2">
          <div className="column-content scrollable">
            <h4>Webhook</h4>
            <pre>
              <code className="text-success">
                {" "}
                {JSON.stringify(parseAwbBodyRaw(webhook), null, 2)}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataCheck;
