import { FC, useEffect, useState } from "react";
import { lupaService } from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import { AxiosResponse } from "axios";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import { LocalDateTime } from "@js-joda/core";

interface UploadHistoryItem {
  id: number;
  fileName: string;
  successCount: number;
  failureCount: number;
  timestamp: LocalDateTime;
  formattedTime: string;
}
interface FailedRecord {
  awbNumber: string;
  message: string;
}

const UploadHistory: FC = () => {
  const [uploadHistoryData, setUploadHistoryData] = useState<
    UploadHistoryItem[]
  >([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [failedRecords, setFailedRecords] = useState<FailedRecord[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    fetchUploadHistory(); // Fetch upload history data when the component mounts
  }, []);

  const fetchUploadHistory = async () => {
    try {
      const response: AxiosResponse<UploadHistoryItem[]> =
        await lupaService.getuploadHistory();
      if (response.data && Array.isArray(response.data)) {
        setUploadHistoryData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  };
  const fetchFailedRecords = async (id: number) => {
    try {
      const response: AxiosResponse<FailedRecord[]> =
        await lupaService.getFailedExcelData(id);
      setFailedRecords(response.data);
      console.log(failedRecords);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching failed records:", error);
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setFailedRecords([]);
  };

  const extractFileName = (fullFileName: string) => {
    const extension = ".xlsx";
    const endIndex = fullFileName.indexOf(extension) + extension.length;
    if (endIndex > extension.length - 1) {
      return fullFileName.slice(0, endIndex);
    }
    return fullFileName;
  };

  return (
    <>
      <ClientNavbar />

      <Container className="overflow-auto mt-4">
        <Card>
          <Table striped hover responsive>
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  S.No
                </th>
                <th scope="col" className="ps-1">
                  File Name
                </th>
                <th scope="col" className="ps-1">
                  Total count
                </th>
                <th scope="col" className="ps-1">
                  Failure Count
                </th>

                <th scope="col" className="ps-1">
                  Date of Entry
                </th>
                <th scope="col" className="ps-1">
                  Failed records
                </th>
              </tr>
            </thead>
            <tbody>
              {uploadHistoryData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{extractFileName(item.fileName)}</td>
                  <td>{item.successCount}</td>
                  <td>{item.failureCount}</td>
                  <td>{item.formattedTime}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => {
                        setSelectedItemId(item.id);
                        fetchFailedRecords(item.id);
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
      <Modal show={showModal} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Failed Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>AWB Number</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {failedRecords.map((record, index) => (
                <tr key={index}>
                  <td>{record.awbNumber}</td>
                  <td>{record.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadHistory;
