import axios from "axios";
import { apiEndpoints } from "./Utils";
import { GenericResponse } from "../models/Common";
export interface Response<T> {
  data: T;
  isSuccess:  boolean;
  message:    string;
  statusCode: number;
}
export const hardDeleteAndAddAWB = async (
  awbNumber: string
): Promise<Response<any>> => {
  try {
    return axios.delete(apiEndpoints.DELETE_AND_ADD_AWB_ON_SCRAP + "/" + awbNumber);
  } catch (error) {
    throw new Error("Failed to delete awb detail");
  }
};
