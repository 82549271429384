import { useMemo } from "react";
import { useAuthContext } from "./useAuth";
import { ROLES } from "../utils/common";

type RoleHookResponse = {
  isAdminUser: boolean;
  isAPIUser: boolean;
  isDemoUser: boolean;
  isDelhiveryApiUser: boolean;
  isDelhiveryAccountsUser: boolean;
  isFrontendUser: boolean;
};

export default function useRole(): RoleHookResponse {
  const { role } = useAuthContext();

  return useMemo(() => {
    const roleObj = {
      isAdminUser: false,
      isAPIUser: false,
      isDemoUser: false,
      isDelhiveryApiUser: false,
      isDelhiveryAccountsUser: false,
      isFrontendUser: false,
    };
    if (!role) return roleObj;
    return role[0] === ROLES.APIUSER
      ? { ...roleObj, isAPIUser: true }
      : role[0] === ROLES.ADMIN
      ? { ...roleObj, isAdminUser: true }
      : role[0] === ROLES.DEMO
      ? { ...roleObj, isDemoUser: true }
      : role[0] === ROLES.DELHIVERYAPIUSER
      ? { ...roleObj, isDelhiveryApiUser: true }
      : role[0] === ROLES.DELHIVERYACCOUNTSUSER
      ? { ...roleObj, isDelhiveryAccountsUser: true }
      : role[0] === ROLES.FRONTENDUSER
      ? { ...roleObj, isFrontendUser: true }
      : roleObj;
  }, [role]);
}
