import React from "react";
import { TrackingHistory } from "../../../models/Customer";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import Select from "../../common/Select";
import { useQuery } from "react-query";
import { getTrackingHistory } from "../../../services/customer";
import { handleExportRows } from "../../../services/Utils";

type Props = {
  userId: number;
};

function TrackingHistoryTable({ userId }: Props) {
  const [trackingHistory, setTrackingHistory] = React.useState<
    TrackingHistory[]
  >([]);
  const currMonth = moment().format("MM/YYYY");
  const [month, setMonth] = React.useState<string>(currMonth);
  const { data, isLoading, error, refetch } = useQuery(
    ["trackingHistory", userId, month],
    () => {
      getTrackingHistory(userId, month).then((res) => {
        setTrackingHistory(res.data);
      });
    }
  );
  const seedLastSixMonths = () => {
    const months = [];
    for (let i = 0; i < 6; i++) {
      months.push({
        label: moment().subtract(i, "months").format("MMMM YYYY"),
        value: moment().subtract(i, "months").format("MM/YYYY"),
      });
    }
    return months;
  };

  const handleDownloadClick = () => {
    if (trackingHistory.length === 0) return;
    const headerRow = ["No.", "AWB Number", "Tracking Date"];
    const rows = trackingHistory.map((history, index) => [
      index + 1,
      history.awbNumber,
      moment(history.createdAt).format("DD-MM-YYYY"),
    ]);
    console.log({ month });
    handleExportRows(
      headerRow,
      rows,
      `Tracking History ${moment(month, "MM/YYYY").format("MMM YYYY")}`
    );
  };

  return (
    <Container fluid className="mx-0 px-0">
      <div className="d-flex justify-content-between aligns-items-center">
        <h3 className="mx-2 my-4 d-flex">Tracking History</h3>
        <label
          htmlFor="month"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#337ab7",
            fontWeight: "bold",
            fontSize: 21,
          }}
        >
          Count:{trackingHistory.length}
        </label>
        <span
          style={{
            width: "250px",
            display: "flex",
            gap: "10px",
            alignItems: "flex-end",
            marginBottom: "15px",
            justifyContent: "flex-end",
          }}
        >
          <Select
            onChange={(e) => {
              setMonth(e.target.value);
              console.log(e.target.value);
            }}
            style={{ marginRight: "10px" }}
          >
            {seedLastSixMonths().map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </Select>

          <Button
            variant="primary"
            onClick={handleDownloadClick}
            disabled={isLoading || trackingHistory.length === 0}
          >
            <i className="bi bi-download"></i>
          </Button>
        </span>
      </div>
      {isLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <div
          style={{ maxHeight: "300px", overflowY: "auto" }}
          className="mx-0 mb-1"
        >
          <Table className="table shadow rounded table-hover my-0 mx-0">
            <thead
              className="text-start table-header"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <tr>
                <th className="px-4">No.</th>
                <th>AWB Number</th>
                <th>Tracking Date</th>
              </tr>
            </thead>
            <tbody className="rounded">
              {trackingHistory.map((history, index) => (
                <tr key={history.awbNumber}>
                  <td className="px-4">{index + 1}</td>
                  <td>{history.awbNumber}</td>
                  <td>{moment(history.createdAt).format("DD-MM-YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
}

export default TrackingHistoryTable;
