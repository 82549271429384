import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Card, Container } from "react-bootstrap";
import envConfig from "../../../../utils/envConfig";
import ClientNavbar from "../../../foundation/Navbar";
const LUPA_BASE_URL = envConfig.url.API_URL;

interface EmailLog {
  id: number;
  awbNumber: string;
  email: string;
}


interface WebhookData {
    email: string;
    id: number;
    awbNumber: string;
    createdAt: string;
    updatedAt: string;
    isActive: boolean;
    isDeleted: boolean;
  }
  
const EmailLogTable: React.FC = () => {
    const [logData, setLogData] = useState<WebhookData[]>([]);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get<WebhookData[]>(
              `${LUPA_BASE_URL}test-webhook`
            );
           
            setLogData(response.data)
          } catch (err) {
            console.error(err)
          } 
        };
    
        fetchData();
      }, []);
  return (
    <div>

      <ClientNavbar/>
    <Container className="overflow-auto mt-4">
      <Card>
        <Table hover bordered responsive>
          <thead className="text-start table-header">
            <tr>
              <th scope="col" className="ps-1">S.No</th>
              <th scope="col" className="ps-1">AWB Number</th>
              <th scope="col" className="ps-1">Email</th>
              <th scope="col" className="ps-1">Mail status</th>
              <th scope="col" className="ps-1">Entry Time</th>
            </tr>
          </thead>
          <tbody>
            {logData.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.awbNumber}</td>
                <td>{item.email}</td>
                <td>
                  <i className="bi bi-check-circle-fill text-success"></i>
                </td>
                <td>{new Date(item.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </Container>
    </div>
  );
};

export default EmailLogTable;