import moment from "moment";
import { useState } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CreateInvoice, Invoice } from "../../../models/Customer";
import {
  createInvoice,
  createReceipt,
  getInvoices,
} from "../../../services/customer";
import ViewInvoice from "./ViewInvoice";
import useRole from "../../../hooks/useRole";
import React from "react";
import { handleExportRows } from "../../../services/Utils";
import AddInvoice from "./AddInvoice";

type Props = {
  userId: number;
};

type ShowViewInvoice = {
  show: boolean;
  invoiceId: string | null;
};

function InvoiceTable({ userId }: Props) {
  const { isAdminUser } = useRole();

  const { data, isLoading, error, refetch } = useQuery(
    ["invoices", userId],
    () => getInvoices({ userId })
  );
  const [showAddInvoice, setShowAddInvoice] = useState(false);
  const [showViewInvoice, setShowViewInvoice] = useState<ShowViewInvoice>({
    show: false,
    invoiceId: null,
  });

  const [showAddReceipt, setShowAddReceipt] = useState<ShowViewInvoice>({
    show: false,
    invoiceId: null,
  });

  const onSubmit = (invoice: Omit<CreateInvoice, "userId">) => {
    if (
      !userId ||
      isNaN(userId) ||
      !invoice ||
      invoice.awbCount === null ||
      !invoice.amount ||
      !invoice.from ||
      !invoice.to ||
      !invoice.dueDate
    ) {
      toast.error("Invalid Invoice Data");
      return;
    }
    createInvoice({ ...invoice, userId })
      .then((res) => {
        if (res.isSuccess) {
          toast.success(res.message);
          refetch();
          setShowAddInvoice(false);
        } else if (res.message === "Invoice already exists") {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleInvoiceExportToPDF = (invoice: Invoice) => {
    const tableHeaders = [
      "Invoice No",
      "From",
      "To",
      "Due Date",
      "AWB Count",
      "Amount",
    ];
    const tableData = [
      [
        invoice.invoiceId,
        moment(invoice.from).format("DD-MM-YYYY"),
        moment(invoice.to).format("DD-MM-YYYY"),
        moment(invoice.dueDate).format("DD-MM-YYYY"),
        invoice.awbCount,
        invoice.amount,
      ],
    ];

    handleExportRows(tableHeaders, tableData, invoice.invoiceId);
  };

  const invoices = data?.data;

  if (isLoading) return <Spinner animation="border" />;
  return (
    <Container fluid className="mx-0 px-0">
      <div className="d-flex justify-content-between align-items-center mx-0 px-0 my-0 py-0">
        <h3 className="mx-2 my-4">Invoices</h3>
        {isAdminUser && (
          <button
            className="btn btn-primary px-2 py-1 mx-2"
            onClick={() => setShowAddInvoice(true)}
          >
            Add Invoice
          </button>
        )}
      </div>
      <div
        style={{ maxHeight: "300px", overflowY: "scroll" }}
        className="mx-0 mb-1"
      >
        <Table className="table shadow rounded table-hover my-0 mx-0">
          <thead
            className="text-start table-header"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <tr>
              <th className="px-4">Invoice No.</th>
              <th>From</th>
              <th>To</th>
              <th>Due Date</th>
              <th>AWB Count </th>
              <th>Amount </th>
              <th>Payment Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="rounded">
            {invoices?.map((invoice, index) => (
              <tr key={invoice.id}>
                <td className="px-4">{invoice.invoiceId}</td>
                <td>{moment(invoice.from).format("DD-MM-YYYY")}</td>
                <td>{moment(invoice.to).format("DD-MM-YYYY")}</td>
                <td>
                  {invoice.dueDate
                    ? moment(invoice.dueDate).format("DD-MM-YYYY")
                    : "Not Set"}
                </td>
                <td>{invoice.awbCount}</td>
                <td>{invoice.amount}</td>
                <td>{invoice.paymentStatus.toUpperCase()}</td>
                {/* <td>
                  <button
                    className="btn btn-primary px-1 py-0"
                    onClick={() => {
                      setShowViewInvoice({ show: true, invoiceId: invoice.id });
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </button>
                  <button
                    className="btn btn-primary px-1 py-0 mx-2"
                    onClick={() => {
                      setShowAddReceipt({
                        show: true,
                        invoiceId: invoice.id,
                      });
                    }}
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </td> */}
                {
                  <td>
                    <button
                      className="btn btn-primary px-1 py-0"
                      onClick={() => handleInvoiceExportToPDF(invoice)}
                    >
                      <i className="bi bi-download"></i>
                    </button>
                  </td>
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showAddInvoice && (
        <AddInvoice
          show={showAddInvoice}
          onHide={() => setShowAddInvoice(false)}
          onSubmit={onSubmit}
        />
      )}
      {showViewInvoice.show && (
        <ViewInvoice
          show={showViewInvoice.show}
          onHide={() => setShowViewInvoice({ show: false, invoiceId: null })}
          receiptId={showViewInvoice.invoiceId}
        />
      )}
    </Container>
  );
}

export default InvoiceTable;
