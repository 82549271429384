import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PendingTrackingsAvailableForTheMonth,
  TrackingSummary,
} from "../../../models/TrackingSummary";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";
import { TrackingDetails } from "../tracking/TrackingDetails";
import ClientNavbar from "../../foundation/Navbar";
import { useAuthContext } from "../../../hooks/useAuth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const defaultPendingTrackingState = {
  totalLimitForTheMonth: 0,
  trackingsLeft: 0,
  trackingsUsed: 0,
};
function ClientDashboard() {
  type ShowDetailsState = {
    show: boolean;
    trackingId: string;
    trackingEvents: TrackingSummary | null;
  };

  const defaultDetailsState = {
    show: false,
    trackingId: "",
    trackingEvents: null,
  };
  const navigate = useNavigate();
  const [trackings, setTrackings] = useState<TrackingSummary[]>([]);

  const [onGoing, setOnGoing] = useState<number>();
  const [delivered, setDelivered] = useState<number>();
  const { role } = useAuthContext();
  const [filterValue, setFilterValue] = useState();
  const [pendingTracking, setPendingTracking] =
    useState<PendingTrackingsAvailableForTheMonth>(defaultPendingTrackingState);
  const [showDetailsState, setShowDetailsState] =
    useState<ShowDetailsState>(defaultDetailsState);

  const checkPendingTracking = () => {
    lupaService.pendingTrackingsAvailableForTheMonth()?.then((r) => {
      const data = r.data;
      setPendingTracking(data.result);
    });
  };
  const onShowDetails = (
    trackingId: string,
    trackingEvents: TrackingSummary
  ) => {
    setShowDetailsState({ show: true, trackingId: trackingId, trackingEvents });
  };

  const fetchData = (): void => {
    lupaService.getLinkedAwb()?.then((r) => {
      const data = r.data;
      console.log({ data });

      const check = data.userTrackings.map(
        (res: any) => res as TrackingSummary
      );
      check.reverse();
      console.log(check);
      setTrackings(check);
      countDetails();
      // });
    });
    checkPendingTracking();
  };

  function countDetails() {
    setOnGoing(
      trackings.reduce((total, item) => {
        if (!item.trackingStatus.includes("Delivered")) {
          return total + 1;
        } else {
          return total;
        }
      }, 0)
    );
    setDelivered(
      trackings.reduce((total, item) => {
        if (item.trackingStatus.includes("Delivered")) {
          return total + 1;
        } else {
          return total;
        }
      }, 0)
    );
  }

  useEffect(() => {
    console.log({ local: localStorage.getItem("userApiLupaJwtToken") });
    if (localStorage.getItem("userApiLupaJwtToken") === null) {
      console.log("here");
      navigate("/login");
    } else {
      fetchData();
      countDetails();
    }
  }, []);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      // title: {
      //   display: true,
      //   text: 'Chart.js Line Chart',
      // },
    },
  };
  // const graphChart = [
  //   { value: 'bar', label: 'Bar Chart' },
  // { value: 'line', label: 'Line Chart' },
  // ];
  // const roundchart = [
  //   { value: 'pie', label: 'Pie Chart' },
  // { value: 'polar', label: 'Polar Chart' },
  // ];

  const labels = ["August", "Septmenber", "October", "November", "December"];
  // const isDemoUser =
  //   localStorage.getItem("userApiemail") === "intemoff.test+apiuser@gmail.com";
  // console.log({ isDemoUser, email: localStorage.getItem("userApiemail") });
  const data = {
    labels,
    datasets: [
      {
        label: "Api Usage",
        data: [pendingTracking.trackingsUsed, 0, 0, 0, 0],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const data1 = {
    labels: ["Used", "Remaining"],
    datasets: [
      {
        label: "# of APi Request",
        data: [pendingTracking.trackingsUsed, pendingTracking.trackingsLeft],
        backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        show={showDetailsState.show}
        onHide={() => setShowDetailsState(defaultDetailsState)}
      >
        <Modal.Header closeButton={true} className="mx-2 px-4">
          <h5 className="section-title">
            Tracking Details - {showDetailsState.trackingId}
          </h5>
        </Modal.Header>
        <Modal.Body className="px-0">
          <TrackingDetails
            trackingId={showDetailsState.trackingId}
            awbDetails={showDetailsState.trackingEvents}
          />
        </Modal.Body>
      </Modal>
      <ClientNavbar />

      <Container>
        {/* {isDemoUser && <SearchAwb addAwbToTracking={addAwbToTracking} />} */}

        <Row className="d-flex justify-content-evenly my-5 ">
          <Card border="secondary" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Used</Card.Title>
              <Card.Text>{pendingTracking.trackingsUsed}</Card.Text>
            </Card.Body>
          </Card>
          <Card border="secondary" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Remaining</Card.Title>
              <Card.Text>{pendingTracking.trackingsLeft}</Card.Text>
            </Card.Body>
          </Card>
          <Card border="secondary" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Total</Card.Title>
              <Card.Text>{pendingTracking.totalLimitForTheMonth}</Card.Text>
            </Card.Body>
          </Card>
          {/* <Card border="secondary" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Ongoing Tracking</Card.Title>
              <Card.Text>{onGoing}</Card.Text>
            </Card.Body>
          </Card> */}
        </Row>
      </Container>
      <Container>
        <Row className="d-flex justify-content-evenly mb-5">
          {trackings ? (
            <>
              {/* <Col md={6}>
                <Card>
                  <Card.Header>Monthly Api Usage</Card.Header>
                  <Bar options={options} data={data} />
                </Card>
              </Col> */}
              <Col md={6}>
                <Card>
                  <Card.Header>Recent AWB Trackings</Card.Header>
                  <Table striped hover responsive>
                    <thead>
                      <tr className="text-center">
                        <th scope="col">Airway Bill</th>
                        <th scope="col">Status</th>

                        <th scope="col">Origin</th>
                        <th scope="col">Destination</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trackings.length > 0 &&
                        trackings.map(
                          (summary, index) =>
                            index < 5 && (
                              <tr className="text-center" key={index}>
                                <td scope="row">
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      onShowDetails(summary.awbNumber, summary)
                                    }
                                  >
                                    {summary.awbNumber}
                                  </a>
                                </td>
                                <td>{summary.trackingStatus}</td>

                                <td>{summary.origin}</td>
                                <td>{summary.destination}</td>
                              </tr>
                            )
                        )}
                      {trackings.length <= 0 && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <h6 className="text-muted">No Trackings Found</h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </Col>
              <Col md={3}>
                <Card style={{ width: "17rem" }}>
                  <Card.Header>Current Month Awb Usage</Card.Header>
                  <Pie data={data1} />
                </Card>
              </Col>
            </>
          ) : (
            <h6 className="text-muted">Loading data</h6>
          )}
        </Row>
      </Container>
      {/* <Container>
        <Card>
          <Card.Header>Recent AWB Trackings</Card.Header>
          <Row>
            <Table striped hover responsive>
              <thead>
                <tr className="text-center">
                  <th scope="col">Airway Bill</th>
                  <th scope="col">Status</th>

                  <th scope="col">Origin</th>
                  <th scope="col">Destination</th>
                </tr>
              </thead>
              <tbody>
                {trackings.length > 0 &&
                  trackings.map((summary, index) => {
                    return (
                      <tr key={summary.awbNumber}>
                        <th scope="row">
                          <a
                            href="#"
                            onClick={(e) =>
                              onShowDetails(summary.awbNumber, summary)
                            }
                          >
                            {summary.awbNumber}
                          </a>
                        </th>
                        <td>{summary.trackingStatus}</td>

                        <td>{summary.station}</td>
                        <td>{summary.destination}</td>
                      </tr>
                    );
                  })}
                {trackings.length <= 0 && (
                  <td colSpan={8} className="text-center">
                    <h6 className="text-muted">No Trackings Found</h6>
                  </td>
                )}
              </tbody>
            </Table>
          </Row>
        </Card>
      </Container> */}
    </>
  );
}
export default ClientDashboard;
