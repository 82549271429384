import React from "react";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

const style = {
  textDecoration: "None",
  color: "primary",
  fontWeight: "bold",
  fontSize: "16px",
  cursor: "pointer",
};
function DecoratedLink({ children, ...rest }: Props): JSX.Element {
  return (
    <a style={style} {...rest}>
      {children}
    </a>
  );
}

export default DecoratedLink;
