import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { WebhookUiService } from "../../../services/webhook-ui";
import UserApiNavbar from "../../foundation/Navbar";
import { FaFilter } from "react-icons/fa";
import { AWBData, Users } from "../../../models/TrackingSummary";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_FilterFn,
  MRT_PaginationState,
  MRT_Row,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Accordion,
  Container,
  Modal,
  Form,
  Spinner,
  Badge,
} from "react-bootstrap";
import { Button as Bootstrapbutton } from "react-bootstrap";
import ClientNavbar from "../../foundation/Navbar";
import * as XLSX from "xlsx";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadDataModal from "./WebhookDownload";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { BASE_URL } from "../../../services/Utils";
import { RawData } from "../../../models/Common";
import { toast } from "react-toastify";
interface OriginalObject {
  awbnumber: string;
  userid: number;
}
interface newMicro {
  id: string;
  email: string;
}

const AwbTable: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRawData, setSelectedRawData] = useState<RawData[]>([]);
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [uncheckedAWBsCount, setUncheckedAWBsCount] = useState<number>(0);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "createdAt", desc: true },
  ]);

  const navigate = useNavigate();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });

  const {
    data: awbdata,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery("all", WebhookUiService.getAWBAll, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const count = data.filter((item: AWBData, index: number) => {
        const date = new Date(data[index].createdAt);
        const mayFirst2024 = new Date("2024-05-01");
        return date >= mayFirst2024 && item.checkForManualWebhook;
      }).length;
      setUncheckedAWBsCount(count);
    },
  });
  // console.log(awbdata)
  const [isRefetching, setIsRefetching] = useState(false);

  const handleViewDataClick = async (awbNumber: string, email: string) => {
    console.log("Clicked Email:", email);
    console.log("AWB Number:", awbNumber);
    setIsLoadingModal(true);

    try {
      const data = await WebhookUiService.getAwbRawNewData(awbNumber, email);
      // console.log('Raw Data:', data);
      setSelectedRawData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching raw data:", error);
    } finally {
      setIsLoadingModal(false);
    }
  };
  const parseAwbBodyRaw = (rawString: string) => {
    try {
      return JSON.parse(rawString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };
  const ModalContent = () => {
    const [showAwbModal, setShowAwbModal] = useState(false);
    const [awbNumber, setAwbNumber] = useState("");
    const [awbError, setAwbError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showMediateModal, setShowMediateModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    // Function to handle showing the AWB number modal
    const handleShowAwbModal = () => {
      setShowAwbModal(true);
      setAwbNumber("");
    };

    // Function to handle closing the AWB number modal
    const handleCloseAwbModal = () => {
      setShowAwbModal(false);
      setAwbNumber("");
      setAwbError("");
    };

    const SuccessModal = () => (
      <Modal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
          setIsModalOpen(false);
        }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#28a745", color: "white" }}
        >
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#28a745" }}>Webhook sent successfully.</p>
        </Modal.Body>
      </Modal>
    );

    const ErrorModal = () => (
      <Modal
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
          setIsModalOpen(false);
        }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#dc3545", color: "white" }}
        >
          <Modal.Title>Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#dc3545" }}>
            Error occurred while sending webhook.
          </p>
        </Modal.Body>
      </Modal>
    );

    const MediateModal = () => (
      <Modal
        show={showMediateModal}
        onHide={() => {
          setShowMediateModal(false);
          setIsModalOpen(false);
        }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#dc3545", color: "white" }}
        >
          <Modal.Title>Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "#dc3545" }}>
            Tracking status matches, so webhook not sent!
          </p>
        </Modal.Body>
      </Modal>
    );

    const handleSubmit = async (id: string) => {
      console.log("calling enter");
      if (/^\d{3}-\d{8}$/.test(id)) {
        try {
          setLoading(true);
          const selectedData = selectedRawData.find(
            (data) => data?.awbNumber === id
          );
          console.log(selectedData);
          if (selectedData) {
            const id = selectedData?.id;
            const email = selectedData?.user?.email;
            // console.log(typeof userId)

            const response = await WebhookUiService.getWebhookDataMicroService(
              id,
              email
            );
            // console.log("response", response)

            if (response === 200) {
              console.log("success");
              setShowSuccessModal(true);
            } else if (response === 300) {
              setShowMediateModal(true);
            } else {
              console.log("fails");
              setShowErrorModal(true);
            }
            setShowAwbModal(false);
          } else {
            console.log("No data found");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
      handleCloseAwbModal();
    };

    const handleAwbNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      const rawAwbNumber = selectedRawData[0]?.awbNumber || ""; // Fetch AWB number from selectedRawData

      if (/^\d{3}-\d{8}$/.test(input)) {
        setAwbNumber(input);
        // Check if the input matches the AWB number from selectedRawData
        if (input === rawAwbNumber) {
          setAwbError("");
        } else {
          setAwbError("Inputted AWB number does not match the current AWB");
        }
      } else {
        setAwbNumber(input);
        setAwbError("Please enter a valid AWB number (e.g., 123-12345678)");
      }
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    return (
      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Details</Modal.Title>
          <>
            <div className="w-100 d-flex justify-content-end">
              <Bootstrapbutton variant="primary" onClick={handleShowAwbModal}>
                Send Webhook
              </Bootstrapbutton>
            </div>

            <Modal show={showAwbModal} onHide={handleCloseAwbModal}>
              <Modal.Header closeButton>
                <Modal.Title>Enter AWB Number</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    handleSubmit(awbNumber);
                  }}
                >
                  <Form.Group controlId="awbNumberInput">
                    <Form.Label>AWB Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter AWB Number (e.g. 123-12345678)"
                      value={awbNumber}
                      onChange={handleAwbNumberChange}
                      isInvalid={!!awbError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {awbError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-center mt-3">
                    {loading ? (
                      <div className="loader"></div>
                    ) : (
                      <Bootstrapbutton
                        variant="primary"
                        type="submit"
                        disabled={!!awbError}
                      >
                        Send
                      </Bootstrapbutton>
                    )}
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            <SuccessModal />
            <ErrorModal />
            <MediateModal />
          </>
        </Modal.Header>
        <Modal.Body>
          {selectedRawData.map((item: any, index: number) => (
            <div key={index}>
              <h6>
                User: {item.user?.firstName} {item.user?.lastName}
              </h6>
              <p>Timestamp: {new Date(item.updatedAt).toLocaleString()}</p>
              <p>
                Manual Webhook Sent:{" "}
                {item?.isManualWebhookSent ? (
                  <i className="bi bi-check-circle-fill text-success"></i>
                ) : (
                  <i className="bi bi-x-circle-fill text-danger"></i>
                )}
              </p>
              <pre>
                <code>
                  {JSON.stringify(parseAwbBodyRaw(item.awbBodyRaw), null, 2)}
                </code>
              </pre>
              {index !== selectedRawData.length - 1 && <hr />}
            </div>
          ))}
        </Modal.Body>
        {isLoading && <Spinner animation="border" />}
      </Modal>
    );
  };

  const webhookSentFilterFn: MRT_FilterFn<AWBData> = (row, id, filterValue) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const filterBoolean = filterValue === "true"; // Convert filter value to boolean
    return row.original.isManualWebhookSent === filterBoolean;
  };

  const checkFiletrFn: MRT_FilterFn<AWBData> = (row, id, filterValue) => {
    if (filterValue === "") {
      return true;
    }
    const filterBoolean = filterValue === "false";
    return row.original.checkForManualWebhook === filterBoolean;
  };
  const getBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "success";
      case "in transit":
        return "info";
      case "pending":
        return "warning";
      case "canceled":
        return "danger";
      default:
        return "secondary";
    }
  };
  const columns = useMemo<MRT_ColumnDef<AWBData>[]>(
    () => [
      {
        header: "Email",
        accessorFn: (row) => {
          const emails = row.users
            .map((user) => user.email)
            .filter((email) => email);
          return emails.join(", ");
        },
        Cell: ({ row }) => {
          const emails = row.original.users
            .map((user) => user.email)
            .filter((email) => email);
          return (
            <div>
              {emails.map((email, index) => (
                <div key={index}>
                  <a
                    href="#"
                    onClick={() => {
                      handleViewDataClick(row.original.awbNumber, email);
                      // setIsModalOpen(true);
                    }}
                  >
                    {email}
                  </a>
                  <br />
                </div>
              ))}
            </div>
          );
        },
      },

      {
        header: "AWB Number",
        filterFn: "includesString",
        accessorFn: (row) => row?.awbNumber || "",
      },

      {
        header: "Tracking Status",
        filterFn: "includesString",
        accessorFn: (row) => {
          const trackingStatus = row.users
            .map((user) => user.trackingStatus)
            .filter((trackingStatus) => trackingStatus);

          return trackingStatus.join(", ");
        },
        Cell: ({ row }) => (
          <div>
            {row.original.users.map((user, index) => (
              <div key={index}>
                <Badge bg={getBadgeColor(user.trackingStatus)}>
                  {user.trackingStatus}
                </Badge>
                <br />
              </div>
            ))}
          </div>
        ),
      },
      {
        header: "Flight Date",
        filterFn: "includesString",
        accessorFn: (row) => {
          const flightDate = row.users
            .map((user) => user.flightDate)
            .filter((flightDate) => flightDate);
          return flightDate.join(", ");
        },
        Cell: ({ row }) => (
          <div>
            {row.original.users.map((user, index) => (
              <div key={index}>
                <span>{user.flightDate}</span>
                <br />
              </div>
            ))}
          </div>
        ),
      },

      {
        header: "Manual Webhook Sent",
        accessorFn: (row) =>
          row.isManualWebhookSent === true ? (
            <i className="bi bi-check-circle-fill text-success"></i>
          ) : (
            <i className="bi bi-x-circle-fill text-danger"></i>
          ),
        filterFn: webhookSentFilterFn,
      },
      {
        header: "Send Manual Webhook",
        accessorFn: (row) =>
          row.checkForManualWebhook === true ? (
            <i className="bi bi-x-circle-fill text-danger"></i>
          ) : (
            <i className="bi bi-check-circle-fill text-success"></i>
          ),
        filterFn: checkFiletrFn,
      },

      {
        header: "Entry Time",
        id: "createdAt",
        accessorKey: "createdAt",
        accessorFn: (row) =>
          row?.createdAt ? new Date(row.createdAt.toLocaleString()) : "",

        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<string>();
          return dateValue ? new Date(dateValue).toLocaleString() : "";
        },
      },
      // {

      //   accessorFn: (row) => new Date(row.createdAt.toLocaleString()), //convert to date for sorting and filtering

      //   id: 'hireDate',

      //   header: 'Hire Date',

      //   filterVariant: 'date-range',

      //   Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(), // convert back to string for display

      // },

      {
        header: "Last Update Time",
        // id:'updateAt',
        accessorKey: "updatedTime",
        accessorFn: (row) => {
          const updatedTime = row.users
            .map((user) => user.updatedTime)
            .filter((updatedTime) => updatedTime);
          return updatedTime.join(", ");
        },
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.users.map((user, index) => (
                <div key={index}>
                  <span>
                    {user.updatedTime
                      ? new Date(user.updatedTime.toString()).toLocaleString()
                      : ""}
                  </span>
                  <br />
                </div>
              ))}
            </div>
          );
        },
        filterVariant: "date-range",
      },
      {
        header: "Is Send",
        accessorKey: "isSent",
        accessorFn: (row) => {
          // Extracting isSent values from each user
          const isSentValues = row.users
            .map((user) => user.isSent)
            .filter(Boolean);
          return isSentValues.join(", ");
        },
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.users.map((user, index) => (
                <div key={index}>
                  <Badge
                    style={{
                      backgroundColor:
                        user.isSent === "Success"
                          ? "green"
                          : user.isSent === "Failure"
                          ? "red"
                          : "gray",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {user.isSent || "N/A"}
                  </Badge>
                </div>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );
  const sendToWebhook = async () => {
    // Prompt for password
    // const password = prompt("Please enter your password:");
    // if (!password) return;

    // Check if password is correct
    // if (password !== "password") {
    //   alert("Incorrect password!");
    //   return;
    // }
    const originalObjects: newMicro[] = [];
    table.getSelectedRowModel().rows?.forEach((row) => {
      const awbnumber = row?.original?.awbNumber;
      row?.original?.users?.forEach((users) => {
        originalObjects.push({ id: users.webhookId, email: users.email });
      });
    });
    console.log(originalObjects);
    if (originalObjects.length === 0) {
      toast.error("Please select the AWB's to send");
      return;
    }
    if (originalObjects.length > 15) {
      alert("Please choose only 15 AWBs at a time.");
      return;
    }
    const confirmation = window.confirm("Are you sure you want to proceed?");
    if (!confirmation) return; // If user cancels, exit the function

    try {
      // Call your webhook function here with originalObjects
      await WebhookUiService.sendBatchWebhookDataMicroService(originalObjects);
      // console.log("Data sent to webhook:", originalObjects);
      toast.success("Request sent sucessfully!");
      refetch();
    } catch (error) {
      console.error("Error sending data to webhook:", error);
    }
  };

  const handleExportToExcel = (rows: MRT_Row<AWBData>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Webhook_Data");

    XLSX.writeFile(workbook, "Webhook_Data.xlsx");
  };
  const [isModalOpenData, setIsModalOpenData] = useState(false);

  const handleOptionClick = () => {
    setIsModalOpenData(true);
  };

  const closeModal = () => {
    setIsModalOpenData(false);
  };
  const filterWebhookView = async () => {
    const date = "2024-05-01T00:00:00.000Z";
    const test = new Date(date.toLocaleString());
    // console.log(test)

    setColumnFilters([
      {
        id: "Send Manual Webhook",
        value: "false",
      },
      {
        id: "createdAt",
        value: [test, null],
      },
    ]);
  };

  const table = useMaterialReactTable({
    columns,
    data: awbdata ?? [],
    enableRowSelection: true,
    initialState: {
      showColumnFilters: true,
      density: "compact",
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>

        {/* <Bootstrapbutton onClick={handleOptionClick}>Download Data</Bootstrapbutton> */}
        {/* <Modal show={isModalOpenData} onHide={closeModal}> */}
        <DownloadDataModal isOpen={isModalOpenData} onClose={closeModal} />

        <Bootstrapbutton
          className="ml-2"
          onClick={filterWebhookView}
          disabled={uncheckedAWBsCount === 0}
        >
          {" "}
          View Bulk AWB ({uncheckedAWBsCount})
        </Bootstrapbutton>
        <Bootstrapbutton
          className="ml-2"
          onClick={sendToWebhook}
          disabled={uncheckedAWBsCount === 0}
        >
          {" "}
          Send Bulk Webhook
        </Bootstrapbutton>

        <Bootstrapbutton
          variant="primary"
          className="me-2"
          as={Link as any}
          to="/manual-webhook-log"
        >
          Manual Webhook Log
        </Bootstrapbutton>

        {/* </Modal> */}
      </Box>
    ),
  });

  const filteredRows = table.getPrePaginationRowModel().rows;

  const originalObjects = filteredRows.map((row) => row.original);
  console.log(originalObjects);

  return (
    <Container fluid className="m-0 p-0">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ClientNavbar />
        <MaterialReactTable table={table} />
        <ModalContent />
      </LocalizationProvider>
    </Container>
  );
};

export default AwbTable;
