import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import { ROLES } from "../../../utils/common";
import { toastTypes } from "../../../utils/toastTypes";
import Input from "../../common/Input";
import Select from "../../common/Select";
import { createUserValidationSchema } from "./validationSchema";
import { UserListdetail } from "../../../models/User";
import { updateUser } from "../../../services/user";
type Props = {
  show: boolean;
  handleClose: () => void;
  user: UserListdetail | undefined;
  refetch: () => void;
};

const LABEL = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  company: "Organization Name",
  monthlyVolume: "AWB Monthly Limit",
  role: "Role",
};

const ROLESLABEL: any = {
  [ROLES.ADMIN]: "Admin",
  [ROLES.APIUSER]: "Client",
  [ROLES.DEMO]: "Demo User",
  [ROLES.DELHIVERYACCOUNTSUSER]: "Delhivery Accounts User",
  [ROLES.DELHIVERYAPIUSER]: "Delhivery API User",
  [ROLES.FRONTENDUSER]: "Frontend User",
};

function UpdateUser({ show, handleClose, user, refetch }: Props): JSX.Element {
  const initialValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    company: user?.orgType || "",
    monthlyVolume: user?.UserClaims[0]?.monthlyClaimLimit || "",
    role: user?.role[0] || "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      updateUser(user?.id!, values)
        .then((data) => {
          console.log(data);
          if (data.isSuccess) {
            toastTypes.success("User updated successfully");
            formik.resetForm();
            handleModalClose();
            refetch();
          } else {
            toastTypes.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
          toastTypes.error(error.message);
        });
    },
    validationSchema: createUserValidationSchema,
    validateOnBlur: false,
    validateOnChange: true,
  });
  console.log({ formik });
  function handleModalClose() {
    handleClose();
    formik.resetForm();
  }

  const getErrorMessage = (field: string) => {
    return (formik.touched as any)[field] && (formik.errors as any)[field]
      ? (formik.errors as any)[field]
      : null;
  };

  const getInputProps = (field: string, type = "text") => {
    const label = (LABEL as any)[field];
    return {
      id: field,
      name: field,
      label,
      type,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: (formik.values as any)[field],
      placeholder: `Enter ${label}`,
      error: getErrorMessage(field),
    };
  };

  return (
    <Modal show={show} centered onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Input {...getInputProps("firstName")}></Input>
          <Input {...getInputProps("lastName")}></Input>
          <Input {...getInputProps("email", "email")} disabled></Input>
          <Input {...getInputProps("company")}></Input>
          <div className="d-flex gap-2 flex-col aligh-items-start">
            <Input
              {...getInputProps("monthlyVolume", "number")}
              width={"50%"}
            ></Input>
            <Select {...getInputProps("role")} width={"50%"}>
              <option value="" disabled>
                Select Role
              </option>
              {Object.values(ROLES).map((role) => (
                <option key={role} value={role}>
                  {ROLESLABEL[role]}
                </option>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Update
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default UpdateUser;
