import axios from "axios";
import { apiEndpoints } from "./Utils";
import {
  AWBData,
  ScrapingTable,
  Request,
  Response,
  AWBDetails,
  ScrapingTracking,
} from "../models/TrackingSummary";

const backendService = axios.create({
  baseURL: apiEndpoints.BASE_URL,
});
const backendServiceMircro = axios.create({
  baseURL: apiEndpoints.MICRO_URL,
  headers: {
    secretKey: "+Ey+Z9BLDLLC+ZqpCF7j2Fud6DCUv4djol3n7LuRD5o=",
  },
});
export const WebhookUiService = {
  getAllAwbData: async (
    page: number,
    pageSize: number,
    filters: any[],
    globalFilter: string,
    sorting: any[]
  ): Promise<AWBData[]> => {
    try {
      const response = await backendService.get("/webhookui", {
        params: {
          page,
          pageSize,
          filters,
          globalFilter,
          sorting,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  updateEtdEta: async (id: string): Promise<any> => {
    try {
      const response = await backendService.get(
        `/webhookui/${id}/updateEtdEta/`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAWBAll: async (): Promise<any> => {
    try {
      const response = await backendService.get("/webhookui/all");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },

  getAwbRawData: async (id: string): Promise<any> => {
    try {
      const response = await backendService.get(`/webhookui/${id}/raw`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch raw data");
    }
  },
  getAwbRawNewData: async (id: string, email: string): Promise<any> => {
    try {
      const encodedEmail = encodeURIComponent(email);
      const response = await backendService.get(
        `/webhookui/${id}/raw-new?email=${encodedEmail}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch raw data");
    }
  },
  getAwbPopupData: async (id: string): Promise<any> => {
    try {
      const response = await backendService.get(`/webhookui/${id}/details`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },

  getLiveData: async (id: string): Promise<any> => {
    try {
      const response = await backendService.get(`/webhookui/${id}/livedata`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },
  getEtdEtaRequest: async (): Promise<Request[]> => {
    try {
      const response = await backendService.get("/webhookui/request");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getEtdEtaResponse: async (): Promise<Response[]> => {
    try {
      const response = await backendService.get("/webhookui/response");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getScraping: async (): Promise<ScrapingTable[]> => {
    try {
      const response = await backendService.get("/webhookui/scraping");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getScrapingTracking: async (): Promise<ScrapingTracking[]> => {
    try {
      const response = await backendService.get("/webhookui/scrapingTracking");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getScrapingRaw: async (awbNumber: string, id: string): Promise<any> => {
    try {
      const response = await backendService.get(
        "/webhookui/scraping/" + awbNumber + "/" + id
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getDataCompareRaw: async (awbNumber: string): Promise<any> => {
    try {
      const response = await backendService.get("dataCompare/get/" + awbNumber);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getWebhookData: async (id: string, userId: string): Promise<any> => {
    try {
      const userIdNumber = parseInt(userId, 10);
      // console.log(typeof userIdNumber)
      const response = await backendService.get(
        `/webhookui/${id}/webhook-manual/${userIdNumber}`
      );
      // console.log("Return response", response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },
  sendBatchWebhookData: async (
    originalObjects: { awbnumber: string; userid: number }[]
  ): Promise<any> => {
    try {
      const response = await backendService.post(
        "webhookui/batch",
        originalObjects
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to send batch webhook data");
    }
  },
  getWebhookDataMicroService: async (
    id: string,
    email: string
  ): Promise<any> => {
    try {
      // const userIdNumber = parseInt(userId, 10);
      // console.log(typeof userIdNumber)
      const response = await backendServiceMircro.get(
        `air/${id}/webhook-manual/${email}`
      );
      // console.log("Return response", response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },
  sendBatchWebhookDataMicroService: async (
    originalObjects: { id: string; email: string }[]
  ): Promise<any> => {
    try {
      const response = await backendServiceMircro.post(
        "air/webhookui/batch",
        originalObjects
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to send batch webhook data");
    }
  },
  getAwbRawBetweenTime: async (from: string, to: string): Promise<any> => {
    try {
      const response = await backendService.get("/webhookui/between-time", {
        params: {
          from,
          to,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },
  getAWBDetails: async (): Promise<AWBDetails[]> => {
    try {
      const response = await backendService.get("/webhookui/awbDetails");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getAWBBasedonId: async (id: string): Promise<any> => {
    try {
      const response = await backendService.get(`/webhookui/${id}/awbDetails`);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  },
  getscrapingHealth: async (): Promise<any> => {
    try {
      const response = await backendService.get("/webhookui/scrapingHealth");
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch AWB data");
    }
  },
  getDataCompare: async (): Promise<any> => {
    try {
      const response = await backendService.get(`dataCompare/get/all`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default WebhookUiService;
