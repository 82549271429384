import React from "react";
import Image from "../../assets/intemo.png";

type Props = {
  title: string;
  children: React.ReactNode;
};

const AuthDetailsCard: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="Auth-form-container">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <img src={Image} style={{ marginLeft: "52px" }} />
          <h3 className="Auth-form-title">{title}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthDetailsCard;
