import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { AppRoutes } from "./AppRoutes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-bootstrap-typeahead/css/Typeahead.css";
import "../node_modules/react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AppNavbar } from "./components/foundation/AppNavbar";
import { AuthContext } from "./hooks/useAuth";
import authService from "./services/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorHandler } from "./utils/error";
import { QueryClient, QueryClientProvider } from "react-query";
// import 'bs-stepper/dist/css/bs-stepper.min.css';

function App() {
  console.log(process.env.REACT_APP_ENV);

  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [role, setRole] = useState<string[] | null>(null);
  const [lupaJwtToken, setLupaJwtToken] = useState<string | null>(() => {
    const token = localStorage.getItem("lupaJwtToken");
    if (token) {
      return token;
    } else return null;
  });
  const [userApiLupaJwtToken, setUserApiLupaJwtToken] = useState<string | null>(
    () => {
      const token = localStorage.getItem("userApiLupaJwtToken");
      if (token) {
        return token;
      } else return null;
    }
  );
  const [user, setUser] = useState(() => {
    const userProfile = localStorage.getItem("userProfile");
    if (userProfile) {
      console.log(userProfile);
      const userLocal = JSON.parse(userProfile);
    
      console.log(userLocal);
      setRole(userLocal.roles);
      return userLocal;
    }
  });
  const navigate = useNavigate();
  const queryClient = new QueryClient();

  const login = async (payload: { email: string; password: string }) => {
    authService
      .login(payload)
      .then((response) => {
        localStorage.setItem("userProfile", JSON.stringify(response.data));
        const user = response.data;
        setUser(user);
        localStorage.setItem("userApiLupaJwtToken", response.data.apiKey);
        localStorage.setItem("userApiemail", payload.email);
        localStorage.setItem("lupaJwtToken", response.data.apiKey);
        setUserApiLupaJwtToken(response.data.apiKey);
        setRole(user.roles);
        setIsAuth(true);
        if (
          user.roles?.includes("delhiveryApiUser") ||
          user.roles?.includes("delhiveryAccountsUser") ||
          user.roles?.includes("frontendUser")
        ) {
          console.log("delhivery");
          navigate("/profile");
        } else navigate("/dashboard");
      })
      .catch((error) => {
        errorHandler(error);
      });
    // .catch(function (error) {
    //   console.log(error.response);
    //   alert(error.response.data.message);
    // });
  };

  const logout = async () => {
    authService.logout().then(() => {
      localStorage.removeItem("userProfile");
      localStorage.removeItem("userApiLupaJwtToken");
      localStorage.removeItem("lupaJwtToken");
      setUserApiLupaJwtToken(null);
      setUser(null);
      navigate("/login");
      setRole(null);
      setIsAuth(false);
    });
  };
  // const [isAuth , setIsAuth] = useState<boolean>(false)
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider
          value={{
            isAuth,
            role,
            user,
            setIsAuth,
            setRole,
            login,
            logout,
            lupaJwtToken,
            setLupaJwtToken,
            userApiLupaJwtToken,
            setUserApiLupaJwtToken,
          }}
        >
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* {!!user && <AppNavbar></AppNavbar>} */}
          <AppRoutes />
        </AuthContext.Provider>
      </QueryClientProvider>
    </div>
  );
}
export default App;
