import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import { ROLES } from "../../../utils/common";
import { toastTypes } from "../../../utils/toastTypes";
import Input from "../../common/Input";
import Select from "../../common/Select";
import { createUserValidationSchema, initialValues } from "./validationSchema";
type Props = {
  show: boolean;
  handleClose: () => void;
  refetch: () => void;
};

const LABEL = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  company: "Organization Name",
  monthlyVolume: "AWB Monthly Limit",
  role: "Role",
};

const ROLESLABEL: any = {
  [ROLES.ADMIN]: "Admin",
  [ROLES.APIUSER]: "Client",
  [ROLES.DEMO]: "Demo User",
  [ROLES.DELHIVERYAPIUSER]: "Delhivery API user",
  [ROLES.DELHIVERYACCOUNTSUSER]: "Delhivery Accounts user",
  [ROLES.FRONTENDUSER]: "Frontend user",
};

function CreateUser({ show, handleClose, refetch }: Props): JSX.Element {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log("values", values)
      lupaService
        .addUser(values)
        .then((response) => {
          response
            .json()
            .then((data) => {
              console.log(data);
              if (data.isSuccess) {
                if (values.role === "frontendUser") {
                  const email = values.email;
                  lupaService.getUserId(email)
                    .then(userId => {
                      toastTypes.success("User created successfully");
                      formik.resetForm();
                      refetch();
                      handleModalClose();
                    })
                } else {
                  toastTypes.success("User created successfully");
                  formik.resetForm();
                  refetch();
                  handleModalClose();
                }
              } else {
                toastTypes.error(data.message);
              }
            })
            .catch((error) => {
              toastTypes.error(error.message);
            });
        })
        .catch(function (error) {
          console.log(error);
          toastTypes.error(error.message);
        });
    },
    validationSchema: createUserValidationSchema,
    validateOnBlur: false,
    validateOnChange: true,
  });
  console.log({ formik });
  function handleModalClose() {
    handleClose();
    formik.resetForm();
  }

  const getErrorMessage = (field: string) => {
    return (formik.touched as any)[field] && (formik.errors as any)[field]
      ? (formik.errors as any)[field]
      : null;
  };

  const getInputProps = (field: string, type = "text") => {
    const label = (LABEL as any)[field];
    return {
      id: field,
      name: field,
      label,
      type,
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
      value: (formik.values as any)[field],
      placeholder: `Enter ${label}`,
      error: getErrorMessage(field),
    };
  };
  return (
    <Modal show={show} centered onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Input {...getInputProps("firstName")}></Input>
          <Input {...getInputProps("lastName")}></Input>
          <Input {...getInputProps("email", "email")}></Input>
          <Input {...getInputProps("company")}></Input>
          <div className="d-flex gap-2 flex-col aligh-items-start">
            <Input
              {...getInputProps("monthlyVolume", "number")}
              width={"50%"}
            ></Input>
            <Select {...getInputProps("role")} width={"50%"}>
              <option value="" disabled>
                Select Role
              </option>
              {Object.values(ROLES).map((role) => (
                <option key={role} value={role}>
                  {ROLESLABEL[role]}
                </option>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CreateUser;
