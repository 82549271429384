import moment from "moment";
import { useState } from "react";
import { Container, Row, Spinner, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  createReceipt,
  getAllReceipts,
  updateReceipt,
} from "../../../services/customer";
import ViewInvoice from "./ViewInvoice";
import RowAlightCenter from "../../common/RowBetween";
import LinkInvoice from "./LinkInvoice";
import ViewReceipt from "./ViewReceipt";
import { Receipt } from "../../../models/Customer";
import useRole from "../../../hooks/useRole";
import React from "react";
import { handleExportRows } from "../../../services/Utils";
import AddReceipt from "./AddReceipt";

type Props = {
  userId: number;
};

type ShowViewReceipt = {
  show: boolean;
  receiptId: string | null;
};

function ReceiptTable({ userId }: Props) {
  const { data, isLoading, error, refetch } = useQuery(
    ["receipts", userId],
    () => getAllReceipts(userId)
  );
  const [showViewReceipt, setShowViewReceipt] = useState<{
    show: boolean;
    receipt: Receipt | null;
  }>({
    show: false,
    receipt: null,
  });

  const [showAddReceipt, setShowAddReceipt] = useState<ShowViewReceipt>({
    show: false,
    receiptId: null,
  });

  const [showLinkInvoice, setShowLinkInvoice] = useState<ShowViewReceipt>({
    show: false,
    receiptId: null,
  });

  const { isAdminUser } = useRole();
  const onReceiptSubmit = (receipt: {
    paymentDate: Date;
    amount: number;
    paymentMethod: string;
    isFullPayment: boolean;
  }) => {
    createReceipt({ ...receipt, userId }).then((res) => {
      if (res.isSuccess) {
        toast.success(res.message);
        refetch();
        setShowAddReceipt({ show: false, receiptId: null });
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const onLinkInvoiceSubmit = (receiptIndex: string, invoiceIndex: string) => {
    updateReceipt(receiptIndex, { invoiceId: invoiceIndex }).then((res) => {
      if (res.isSuccess) {
        toast.success(res.message);
        refetch();
        setShowLinkInvoice({
          show: false,
          receiptId: null,
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleReceiptExportToPDF = (receipt: Receipt) => {
    const tableHeaders = [
      "Receipt No.",
      "Payment Date",
      "Payment Amount",
      "Linked Invoice(s)",
    ];

    const tableData = [
      [
        receipt.receiptId,
        moment(receipt.createdAt).format("DD-MM-YYYY"),
        receipt.amount,
        receipt.invoice?.map((invoice) => invoice.invoiceId).join(", "),
      ],
    ];

    handleExportRows(tableHeaders, tableData, receipt.receiptId);
  };

  const receipts = data?.data;

  if (isLoading) return <Spinner animation="border" />;
  return (
    <Container fluid className="mx-0 px-0 mb-3">
      {" "}
      <RowAlightCenter justifyContent="space-between">
        <h3 className="mx-2 my-4">Receipts</h3>
        {isAdminUser && (
          <button
            className="btn btn-primary px-2 py-1 mx-2"
            onClick={() => setShowAddReceipt({ show: true, receiptId: null })}
          >
            Add Receipt
          </button>
        )}
      </RowAlightCenter>
      <div
        style={{ maxHeight: "300px", overflowY: "scroll" }}
        className="mx-0 mb-1"
      >
        <Table className="table shadow rounded table-hover my-0 mx-0">
          <thead
            className="text-start table-header"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <tr>
              <th className="px-4">Receipt No.</th>
              <th>Payment Date</th>
              <th>Payment Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="rounded">
            {receipts?.map((receipt, index) => (
              <tr key={receipt.id}>
                <td className="px-4">{receipt.receiptId}</td>
                <td>{moment(receipt.createdAt).format("DD-MM-YYYY")}</td>
                <td>{receipt.amount}</td>
                <td>
                  <button
                    className="btn btn-primary px-1 py-0"
                    onClick={() => {
                      setShowViewReceipt({ show: true, receipt: receipt });
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </button>
                  {/* <button className="btn btn-primary px-1 py-0 mx-2">
                    <i className="bi bi-download"></i>
                  </button> */}
                  {isAdminUser && (
                    <button
                      className="btn btn-primary px-1 py-0 ms-2"
                      onClick={() => {
                        setShowLinkInvoice({
                          show: true,
                          receiptId: receipt.id,
                        });
                      }}
                    >
                      <i className="bi bi-plus"></i>
                    </button>
                  )}
                  <button
                    className="btn btn-primary px-1 py-0 ms-2"
                    onClick={() => handleReceiptExportToPDF(receipt)}
                  >
                    <i className="bi bi-download"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {showAddReceipt.show && (
        <AddReceipt
          show={showAddReceipt.show}
          onHide={() => setShowAddReceipt({ show: false, receiptId: null })}
          onSubmit={onReceiptSubmit}
        />
      )}
      {showViewReceipt.show && (
        <ViewReceipt
          show={showViewReceipt.show}
          onHide={() => setShowViewReceipt({ show: false, receipt: null })}
          receipt={showViewReceipt.receipt}
        />
      )}
      {showLinkInvoice.show && (
        <LinkInvoice
          show={showLinkInvoice.show}
          onHide={() => setShowLinkInvoice({ show: false, receiptId: null })}
          userId={userId}
          onSubmit={onLinkInvoiceSubmit}
          receiptId={showLinkInvoice.receiptId}
        />
      )}
    </Container>
  );
}

export default ReceiptTable;
