import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";

import UserApiNavbar from "../../foundation/Navbar";
import { useEffect, useState } from "react";
import userService from "../../../services/user";
import { useAuthContext } from "../../../hooks/useAuth";
import { WebhookUiService } from "../../../services/webhook-ui";
import useRole from "../../../hooks/useRole";

function ClientProfile() {
  var apiKey = localStorage.getItem("userApiLupaJwtToken");
  const { setLupaJwtToken, setUserApiLupaJwtToken } = useAuthContext();
  const email: any = localStorage.getItem("userApiemail");
  const [webhookUrl, setWebHookUrl] = useState("");
  const [hmacSecret, setHmacSecret] = useState("");
  const [emptyWebhookUrl, setEmptyWebHookUrl] = useState(false);
  const [show, setShow] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [verify, setVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [updateButton, setUpdateButton] = useState(false);
  const [keygenerated, setKeyGenerated] = useState("");
  const { isDemoUser, isDelhiveryApiUser } = useRole();
  const { isFrontendUser } = useRole();
  const handleClose = () => setShow(true);
  const handleShow = () => setShow(false);

  function handleUrl(value: any) {
    setWebHookUrl(value);
  }
  function handleOldPassword(value: any) {
    setOldPassword(value);
  }
  function handleNewPassword(value: any) {
    setNewPassword(value);
  }
  function handleRePassword(value: any) {
    setRePassword(value);
  }
  function handleVerify() {
    setUpdateButton(true);
  }
  function handleCancel() {
    setUpdateButton(false);
    getHook();
    toastTypes.info("WebHook URL Update Proccess Cancelled");
  }
  async function handleWebhook() {
    if (webhookUrl) {
      lupaService
        .addWebHook({ webhookUrl, hmacSecret })
        ?.then((response) => {
          response.json().then((data) => {
            console.log(data.result);

            if (data.result.isWebhookVerified) {
              setVerify(true);
              setUpdateButton(false);
              setEmptyWebHookUrl(false);
              setWebHookUrl(webhookUrl);

              toastTypes.success(data.message);
            } else {
              setVerify(false);
              setWebHookUrl(webhookUrl);
              setUpdateButton(false);
              setEmptyWebHookUrl(false);
              toastTypes.error("Invalid URL Please Check");
            }
          });
        })
        .catch(function (error) {
          setVerify(false);
        });
    } else {
      return toastTypes.error("Please enter the WebHook URL");
    }
  }
  async function generateKey() {
    await lupaService
      .generateApiKey()
      ?.then((response) => {
        response.json().then((data) => {
          console.log(data);
          localStorage.setItem("userApiLupaJwtToken", data.apiKey);
          localStorage.setItem("lupaJwtToken", data.apiKey);
          setLupaJwtToken(data.apiKey);
          setUserApiLupaJwtToken(data.apiKey);
          setKeyGenerated(data.apiKey);
          return toastTypes.success(data.message);
        });
      })
      .catch((error) => console.log(error));
  }
  function handleCopy(key: any) {
    navigator.clipboard.writeText(key);
    return toastTypes.info("Copied");
  }
  async function handlePasswordUpdate() {
    if (oldPassword && newPassword && rePassword) {
      let token: any = apiKey;
      await userService
        .changePassword({ email, oldPassword, newPassword, token })
        ?.then((response) => {
          if (response.isSuccess) {
            setNewPassword("");
            setOldPassword("");
            setRePassword("");
            return toastTypes.success(response.message);
          } else {
            return toastTypes.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (newPassword !== rePassword) {
      return toastTypes.error(
        "Please Check New password And Confirm Password Are Same"
      );
    } else if (newPassword === oldPassword) {
      return toastTypes.error("Old And new Password Are same");
    } else {
      return toastTypes.error("Please fill the required field");
    }
  }
  async function getHook() {
    setIsLoading(true);
    await lupaService
      .getWebhook()
      ?.then((response) => {
        console.log(response.data.result.webhookUrl);
        if (response.data.result.isWebhookVerified) {
          setVerify(true);
          setWebHookUrl(response.data.result.webhookUrl);
          setEmptyWebHookUrl(false);
          setUpdateButton(false);
          setHmacSecret(response.data.result.hmacSecret);
        } else {
          setVerify(false);
          setWebHookUrl(response.data.result.webhookUrl);
          setHmacSecret(response.data.result.hmacSecret);
          setEmptyWebHookUrl(false);
          setUpdateButton(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setEmptyWebHookUrl(true);
        setUpdateButton(true);
        setIsLoading(false);
      });
  }
  const [showRawDataModal, setShowRawDataModal] = useState(false);
  const [awbNumberInput, setAwbNumberInput] = useState("");
  const [rawData, setRawData] = useState<RawData[]>([]);
  const [showAwbModal, setShowAwbModal] = useState(false);
  const handleCloseRawDataModal = () => setShowRawDataModal(false);
  const [isValidAwbFormat, setIsValidAwbFormat] = useState(true);
  interface RawData {
    awbNumber: string;
    awbBodyRaw: string;
  }

  const handleShowAwbModal = () => {
    setShowAwbModal(true);
  };

  function isValidAwbNumber(awb: string): boolean {
    const awbRegex = /^\d{3}-\d{8}$/;
    return awbRegex.test(awb);
  }
  const handleSubmitAwbNumber = async () => {
    if (isValidAwbNumber(awbNumberInput)) {
      try {
        const data = await WebhookUiService.getAwbRawData(awbNumberInput);
        setRawData(data);
        setShowAwbModal(false); // Close the AWB input modal after submission
        setShowRawDataModal(true);
        // console.log('Raw AWB Data:', data);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Please enter a valid AWB number in the format XXX-XXXXXXXX");
    }
  };
  const parseAwbBodyRaw = (rawString: string) => {
    try {
      return JSON.parse(rawString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  useEffect(() => {
    if (!isFrontendUser) {
      getHook();
    }
  }, [keygenerated, isFrontendUser]);
  return (
    <>
      <UserApiNavbar />
      <Container>
        <Card className="my-3">
          <Card.Header>Profile</Card.Header>
          <Card.Body>
            <Row className="justify-content-evenly">
              <Col>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput4"
                  >
                    <Form.Label>Email</Form.Label>
                    <p>{email}</p>
                  </Form.Group>
                </Form>
              </Col>
              {!isFrontendUser && (
                <Col>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput4"
                    >

                      <Form.Label>API KEY</Form.Label>
                      <p>
                        {!show ? (
                          <span> XXXXXXXXXXXXXXXXX </span>
                        ) : (
                          <span>{apiKey} </span>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ cursor: "pointer" }}>
                          <i
                            className="bi bi-clipboard"
                            onClick={() => handleCopy(apiKey)}
                          ></i>
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        {!show ? (
                          <span
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={handleClose}
                          >
                            Show
                          </span>
                        ) : (
                          <span
                            onClick={handleShow}
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                          >
                            Hide
                          </span>
                        )}
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <span
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          onClick={generateKey}
                        >
                          Regenerate
                        </span>
                      </p>
                    </Form.Group>
                  </Form>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        {!isFrontendUser && (
          <Card className="mb-3">
            <Card.Header>
              Add Your WebHook Url{" "}
              {!updateButton && !emptyWebhookUrl && (
                <>
                  {verify ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="bi bi-check-circle-fill text-success">
                          &nbsp;
                        </i>
                        <small className="text-success">Verified</small>
                      </div>
                      <div>
                        {isDemoUser && (
                          <Button variant="primary" onClick={handleShowAwbModal}>
                            Test Webhook Data
                          </Button>
                        )}
                        <Modal
                          show={showAwbModal}
                          onHide={() => setShowAwbModal(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Enter AWB Number</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form.Control
                              type="text"
                              placeholder="Enter AWB Number eg. 123-12345678"
                              value={awbNumberInput}
                              onChange={(e) => {
                                const input = e.target.value;
                                const awbFormat = /^\d{3}-\d{8}$/;
                                setIsValidAwbFormat(
                                  awbFormat.test(input) || input === ""
                                );
                                setAwbNumberInput(input);
                              }}
                              isInvalid={!isValidAwbFormat}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please enter the AWB number in the format
                              XXX-XXXXXXXX.
                            </Form.Control.Feedback>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="primary"
                              onClick={handleSubmitAwbNumber}
                            >
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <Modal
                          show={showRawDataModal}
                          onHide={handleCloseRawDataModal}
                          dialogClassName="modal-xl"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Webhook AWB Data</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div>
                              {/* Display raw data content here */}
                              {rawData.map((item: RawData, index: number) => (
                                <div key={index}>
                                  {/* <h3>Awb Number: {item.awbNumber}</h3> */}
                                  <pre>
                                    <code>
                                      {JSON.stringify(
                                        parseAwbBodyRaw(item.awbBodyRaw),
                                        null,
                                        2
                                      )}
                                    </code>
                                  </pre>
                                  {index !== rawData.length - 1 && <hr />}
                                </div>
                              ))}
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  ) : (
                    !isLoading && (
                      <h5 className="float-end">
                        <i className="bi bi-x-circle-fill text-danger">&nbsp;</i>
                        <small>Not Verified</small>
                      </h5>
                    )
                  )}
                </>
              )}
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  {updateButton ? (
                    <>
                      <Form.Label>Enter Webhook Url</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter the webhook link"
                        value={webhookUrl}
                        onChange={(event) => handleUrl(event.target.value)}
                      />
                      {isDelhiveryApiUser && (
                        <>
                          <Form.Label>Enter HMAC Secret </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter the HMAC Secret"
                            value={hmacSecret}
                            onChange={(event) =>
                              setHmacSecret(event.target.value)
                            }
                          />
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {!updateButton && (
                    <>
                      <Row>
                        <Col>
                          <p className="float-start">
                            Webhook URL : {webhookUrl}
                          </p>
                        </Col>
                        {isDelhiveryApiUser && hmacSecret && (
                          <p className="float-end">HMAC : Secret {hmacSecret}</p>
                        )}
                      </Row>
                      <Button
                        className="float-end"
                        variant="secondary"
                        onClick={handleVerify}
                      >
                        Update
                      </Button>
                    </>
                  )}
                </Form.Group>
              </Form>
              {updateButton ? (
                <>
                  <Button variant="success" onClick={handleWebhook}>
                    Submit
                  </Button>{" "}
                  {!emptyWebhookUrl && (
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        )}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Change Password</Accordion.Header>
            <Accordion.Body>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput6"
                    >
                      <Form.Label>Old Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showOldPassword ? "text" : "password"}
                          aria-describedby="basic-addon1"
                          placeholder="Old Password"
                          value={oldPassword}
                          onChange={(event) =>
                            handleOldPassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon1"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                        >
                          {showOldPassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput7"
                    >
                      <Form.Label>New Password</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showNewPassword ? "text" : "password"}
                          aria-describedby="basic-addon2"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(event) =>
                            handleNewPassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon2"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput8"
                    >
                      <Form.Label>Confirm Password </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showRePassword ? "text" : "password"}
                          aria-describedby="basic-addon3"
                          placeholder="Confirm Password"
                          value={rePassword}
                          onChange={(event) =>
                            handleRePassword(event.target.value)
                          }
                        />
                        <InputGroup.Text
                          style={{ cursor: "pointer" }}
                          id="basic-addon3"
                          onClick={() => setShowRePassword(!showRePassword)}
                        >
                          {showRePassword ? (
                            <i className="bi bi-eye-fill"></i>
                          ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Button
                className="text-right"
                variant="success"
                onClick={handlePasswordUpdate}
              >
                Update
              </Button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
}
export default ClientProfile;
