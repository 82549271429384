import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Image from "../../../assets/intemo.png";
import { lupaService } from "../../../services/lupa";
import { toastTypes } from "../../../utils/toastTypes";
import { useAuthContext } from "../../../hooks/useAuth";
import authService from "../../../services/auth";
import AuthDetailsCard from "../../foundation/AuthDetailsCard";

const ClientLogin: React.FC = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { userApiLupaJwtToken, login, role } = useAuthContext();
  const location = useLocation();

  function handleEmail(value: any) {
    setEmail(value);
  }
  function handlePassword(value: any) {
    setPassword(value);
  }
  function handleForgotPassword() {
    console.log("forgot password");
    navigate("/forgot-password");
  }
  let navigate = useNavigate();
  useEffect(() => {
    if (userApiLupaJwtToken) {
      if (
        role?.includes("delhiveryApiUser") ||
        role?.includes("delhiveryAccountsUser") ||
        role?.includes("frontendUser")
      ) {
        console.log("delhivery");
        navigate("/profile");
      } else navigate("/dashboard");
    }
    if ('Notification' in window) {
      // The Notification API is supported
      Notification.requestPermission().then(permission => {
          if (permission === "granted") {
             new Notification("Welcome!");
          }
      });
    }
   // Notification.requestPermission();

  }, [userApiLupaJwtToken, role]);
  async function handleDashboard(event: any) {
    event.preventDefault();

    if (!email || !password)
      return toastTypes.error("Please enter the Credentials");
    login({ email, password });
  }
  return (
    <AuthDetailsCard title={"Sign In"}>
      <form>
        <div className="form-group mt-3">
          <label>Email address</label>
          <input
            type="email"
            value={email}
            onChange={(event) => handleEmail(event.target.value)}
            className="form-control mt-1"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group mt-3">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(event) => handlePassword(event.target.value)}
            className="form-control mt-1"
            placeholder="Enter password"
          />
        </div>
        <div className="d-grid gap-2 mt-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleDashboard}
          >
            Submit
          </button>
        </div>
        <div
          className="forgot-password text-right mt-3"
          role="button"
          onClick={handleForgotPassword}
        >
          Forgot password?
        </div>
      </form>
    </AuthDetailsCard>
  );
};

export default ClientLogin;
