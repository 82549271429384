// Generated by https://quicktype.io

export interface BillingCustomer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  awbCount: number;
  monthlyClaimLimit: number;
  paymentStatus: PaymentStatus;
}

export enum PaymentStatus {
  ONTIME = "Paid",
  DELAYED = "Late Payment",
  OVERDUE = "Overdue",
}

export interface UserBillingDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  orgType: string | null;
  createdAt: Date;
  awbCount: number;
  monthlyClaimLimit: number;
  monthlyClaimUsed: number;
  trackingHistory: TrackingHistory[];
}

export interface TrackingHistory {
  awbNumber: string;
  createdAt: Date;
}

export interface Invoice {
  id: string;
  from: Date;
  to: Date;
  dueDate: Date;
  amount: number;
  awbCount: number;
  paymentStatus: string;
  createdAt: string;
  invoiceId: string;
  receipt?: Receipt[];
}

export interface Receipt {
  id: string;
  paymentDate: string;
  paymentMethod: string;
  amount: number;
  createdAt: string;
  receiptId: string;
  invoice: Invoice[];
}

export interface InvoiceQuery {
  month?: number;
  year?: number;
  userId?: number;
}

export interface CreateInvoice extends Pick<Invoice, "amount" | "awbCount"> {
  userId: number;
  from: Date;
  to: Date;
  dueDate: Date;
}

export interface CreateReceipt {
  amount: number;
  paymentMethod: string;
  paymentDate: Date;
  invoiceId?: string;
  userId: number;
}
