import { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Nav,
  Navbar,
  Row,
  Table,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import Logo from "../../assets/intemo.png";
import { useAuthContext } from "../../hooks/useAuth";
import { lupaService } from "../../services/lupa";
import authService from "../../services/auth";
import { ROLES } from "../../utils/common";
import { TrackingSummary } from "../../models/TrackingSummary";
import { AirlineDetails } from "../pages/tracking/AirlineDetails";
import { toastTypes } from "../../utils/toastTypes";
import useRole from "../../hooks/useRole";

type ShowDetailsState = {
  show: boolean;
  trackingId: string;
  awb: TrackingSummary | null;
};

const defaultDetailsState = {
  show: false,
  trackingId: "",
  awb: null,
};

const defaultPendingTrackingState = {
  totalLimitForTheMonth: 0,
  trackingsLeft: 0,
  trackingsUsed: 0,
};

const options = [
  { value: "gocomet", label: "GoComet" },
  { value: "scrapping", label: "Scrapping" },
];

function ClientNavbar() {
  const { logout } = useAuthContext();
  const [showAirlineModal, setShowAirlineModal] = useState<boolean>(false);
  const { role } = useAuthContext();
  const isDemoUser = role ? role[0] === ROLES.DEMO : false;
  const isAdminUser = role ? role[0] === ROLES.ADMIN : false;
  const { isDelhiveryAccountsUser, isDelhiveryApiUser } = useRole();
  const isFrontendUser = role ? role[0] === ROLES.FRONTENDUSER : false;
  const navigate = useNavigate();
  const [history, setHistory] = useState<any>(undefined);
  const [addForm, setAddForm] = useState(false);
  const [name, setName] = useState("");
  const [airlineCode, setAirlineCode] = useState("");
  const [dataSource, setDataSource] = useState("");

  const [showDetailsState, setShowDetailsState] =
    useState<ShowDetailsState>(defaultDetailsState);
  console.log({ isDelhiveryApiUser });
  const fetchHistory = () => {
    lupaService.getAvailableAirlines()?.then((r) => {
      const data = r.data;
      // console.log(data);
      setHistory(data.availableAirlines);
    });
  };
  function handleModal() {
    fetchHistory();
    setShowAirlineModal(true);
  }

  function handleDataSource(value: string) {
    setDataSource(value);
  }

  const onLogout = () => {
    logout();
  };

  function handleAddForm() {
    setAddForm(true);
  }

  function handleAirlineName(value: string) {
    setName(value);
  }

  function handleAirlineCode(value: string) {
    setAirlineCode(value);
  }

  const handleAddAirline = () => {
    let dateFormatTypesId = "";
    // lupaService
    //   .addAirline({ name, airlineCode, dataSource, dateFormatTypesId })
    //   ?.then((response) => {
    //     response.json().then((data) => {
    //       // console.log(data);
    //       setAddForm(false);
    //       return toastTypes.success("New Airline Addedd");
    //     });
    //   });
  };

  const forwardRoute =
    isDelhiveryAccountsUser || isDelhiveryApiUser || isFrontendUser
      ? "/profile"
      : "/dashboard";
  console.log({ forwardRoute });
  const airlineUser = (
    <Modal
      size="lg"
      centered
      show={showAirlineModal}
      onHide={() => setShowAirlineModal(false)}
    >
      <Modal.Header closeButton={true} className="mx-2 px-4">
        <h5 className="section-title">
          Available Airlines
          <span className="text-right"></span>
        </h5>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Container className="mx-0">
          <Row className="col-12 ms-1 mx-1 my-1">
            <Col className="col-6"></Col>
            <Col className="col-6 pe-0">
              <Col className="col-4"></Col>
              <Col className="col-8 text-end text-muted ms-auto">
                <small>{history?.length} Airlines</small>
              </Col>
            </Col>
          </Row>
          <Row className="col-12 mx-1 my-2">
            <Container className="overflow-auto" style={{ height: 500 }}>
              <Table className="table table-fixed mt-2 border border-2">
                <thead className="text-start table-header">
                  <tr>
                    <th scope="col" className="ps-1">
                      Name
                    </th>
                    <th scope="col" className="ps-1">
                      Airline Code
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {history?.map((h: any) => {
                    return (
                      <tr key={h.airlineCode}>
                        <td className="ps-1">{h.name}</td>
                        <td className="ps-1">{h.airlineCode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Container>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );

  const airlineAdmin = (
    <Modal
      size="lg"
      centered
      show={showAirlineModal}
      onHide={() => setShowAirlineModal(false)}
    >
      <Modal.Header closeButton={true} className="mx-2 px-4">
        <h5 className="section-title">
          Airline Details {showDetailsState.awb?.awbNumber}
          <span className="text-right"></span>
        </h5>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Container>
          <Row className="col-12 ms-1 mx-1 my-1">
            <Col className="col-6"></Col>
            <Col className="col-6 pe-0">
              <Col className="col-4"></Col>
              {role && role[0] === "apiAdmin" && (
                <Col className="col-8 text-end ms-auto">
                  {!addForm && (
                    <Button className="primary" onClick={handleAddForm}>
                      Add AirLine
                    </Button>
                  )}
                </Col>
              )}
            </Col>
          </Row>
        </Container>

        {addForm && (
          <Container>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Airline Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Name"
                  value={name}
                  onChange={(event) => handleAirlineName(event.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Airline Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter the Last Name"
                  value={airlineCode}
                  onChange={(event) => handleAirlineCode(event.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Data Source</Form.Label>
                {/* <Form.Control type="text"  value={dataSource} /> */}
                <Select
                  options={options}
                  placeholder="Choose Data Source"
                  onChange={(event) =>
                    handleDataSource(event ? event.value : "")
                  }
                />
              </Form.Group>
            </Form>
            <Button variant="success" onClick={handleAddAirline}>
              Submit
            </Button>
          </Container>
        )}
        {/* <TrackingDetails trackingId={showDetailsState.awb} /> */}
        {!addForm && <AirlineDetails hideDropDown={false} />}
      </Modal.Body>
    </Modal>
  );
  const environment = process.env.REACT_APP_ENV || "development"; // default to development if not set
  let backgroundColor = "light";
  
  if (environment === "development") {
    // console.log("In Development Environment");
    backgroundColor = "light";
  } else if (environment === "staging") {
    // console.log("In Staging Environment");
    backgroundColor = "light";
  } else if (environment === "main") {
    // console.log("In Production Environment");
    backgroundColor = "light";
  }
  

  return (
    <>
      <Navbar
        expand="lg"
        className="bg-body-tertiary shadow my-0"
        // bg="light"
        style={{ backgroundColor }} // corrected to use backgroundColor directly
        data-bs-theme="light"
      >
        <Container fluid>
          <Navbar.Brand href={forwardRoute}>
            <img src={Logo} className="d-inline-block align-top" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {!isDelhiveryApiUser &&
                !isDelhiveryAccountsUser &&
                !isFrontendUser && (
                  <Nav.Link href={"/dashboard"}>Dashboard</Nav.Link>
                )}
              {isAdminUser && <Nav.Link href="/awb-usage">AWB Usage</Nav.Link>}
              {!isDelhiveryApiUser && !isFrontendUser && (
                <Nav.Link href="/billing">Billing</Nav.Link>
              )}
              <Nav.Link href="/profile">Profile</Nav.Link>
              {isDemoUser && <Nav.Link href="/demo">Demo</Nav.Link>}
              {isAdminUser ? (
                <Nav.Link href="/airlineinfo">Airline</Nav.Link>
              ) : (
                !isDelhiveryAccountsUser && (
                  <Nav.Link href="/availableairline">Available Airline</Nav.Link>
                )
              )}
              {isAdminUser && <Nav.Link href="/userlist">User list</Nav.Link>}
              {isAdminUser && (
                <Nav.Link href="/webhook-data">Webhook Data</Nav.Link>
              )}
              {/* {isAdminUser && <Nav.Link href="/log-view">Logs</Nav.Link>} */}
              {isAdminUser && <Nav.Link href="/etd-eta">ETD ETA</Nav.Link>}
              {isAdminUser && <Nav.Link href="/scraping">Scraping</Nav.Link>}
              {/* {isAdminUser && <Nav.Link href="/awbDetails">AWB Edit</Nav.Link>} */}
              {/* {isAdminUser && (
                <Nav.Link href="/scrapingHealth">Scraping Health</Nav.Link>
              )} */}
              {/* {isAdminUser && (
                <Nav.Link href="/scrapingTracking">Scraping Track</Nav.Link>
              )} */}
              {/* {isAdminUser && <Nav.Link href="/deleteAdd">Delete Add</Nav.Link>} */}
              {/* {isAdminUser && (
                <Nav.Link href="/data-compare">Data compare</Nav.Link>
              )} */}

              {/* {isDemoUser && (<Nav.Link href="/frontend-user">Tracking</Nav.Link>)} */}
              {isFrontendUser && (
                <Nav.Link href="/frontend-user">Tracking</Nav.Link>
              )}
            </Nav>
            {isAdminUser &&
            <div style={{marginRight:"1rem"}}>
            <DropdownButton  title="Admin Actions">
              <Dropdown.Item href="/log-view">   {isAdminUser && <Nav.Link href="/log-view">Logs</Nav.Link>}  </Dropdown.Item>
              <Dropdown.Item href="/awbDetails">  {isAdminUser && <Nav.Link href="/awbDetails">AWB Edit</Nav.Link>}  </Dropdown.Item>
              <Dropdown.Item href="/scrapingHealth">    {isAdminUser && (<Nav.Link href="/scrapingHealth">Scraping Health</Nav.Link>)}</Dropdown.Item>
              <Dropdown.Item href="/scrapingTracking">   {isAdminUser && (<Nav.Link href="/scrapingTracking">Scraping Track</Nav.Link>)}</Dropdown.Item>
              <Dropdown.Item href="/deleteAdd">  {isAdminUser && <Nav.Link href="/deleteAdd">Delete Add</Nav.Link>}</Dropdown.Item>
              <Dropdown.Item href="/data-compare">   {isAdminUser && (<Nav.Link href="/data-compare">Data compare</Nav.Link>)}</Dropdown.Item>
              <Dropdown.Item href="/webhook-data/test-webhook">   {isAdminUser && (<Nav.Link href="/webhook-data/test-webhook">Customer Test API settings</Nav.Link>)}</Dropdown.Item>
              <Dropdown.Item href="/webhook-data/view-webhook">   {isAdminUser && (<Nav.Link href="/webhook-data/view-webhook">View Received Webhook</Nav.Link>)}</Dropdown.Item>
            </DropdownButton>
            </div>
              }

              <br/>
            <div className="d-flex ">
              {isAdminUser && (
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    navigate("/scrap-settings");
                  }}
                  className="d-flex align-items-center me-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-gear me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.115 2.692l.319.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.115l-.094.319c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.38 4.475l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                  </svg>
                </Button>
              )}

              <Button variant="outline-primary" onClick={onLogout}>
                Logout
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isAdminUser ? airlineAdmin : airlineUser}
    </>
  );
}
export default ClientNavbar;
