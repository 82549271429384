import React, { FC, useEffect, useMemo, useState } from "react";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ClientNavbar from "../../foundation/Navbar";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import { lupaService } from "../../../services/lupa";

const DataSourceChangeTable: FC = () => {
  const [tableData, setTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [showAwbModal, setShowAwbModal] = useState(false);
  const [awbNumber, setAwbNumber] = useState("");
  const [awbError, setAwbError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        lupaService.changeDataSourceLogs().then((data) => {
          setTableData(data);
          setIsLoading(false);
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "AWB Number",
        accessor: "awbNumber",
        accessorFn: (row) => row.awbNumber || "",
      },
      {
        header: "Previous Source",
        accessor: "previousSource",
        accessorFn: (row) => row.previousSource || "",
      },
      {
        header: "New Source",
        accessor: "newSource",
        accessorFn: (row) => row.newSource || "",
      },
      {
        header: "Last Tracking Status",
        accessor: "lastTrackingStatus",
        accessorFn: (row) => row.lastTrackingStatus || "",
      },
      {
        header: "Status",
        accessor: "status",
        accessorFn: (row) => row.status || "",
      },
      {
        header: "Message",
        accessor: "message",
        accessorFn: (row) => row.message || "",
      },
      {
        header: "Created Time",
        accessor: "createdAt",
        accessorFn: (row) =>
          row.createdAt ? new Date(row.createdAt).toLocaleString() : "",
      },
      {
        header: "Updated Time",
        accessor: "updatedAt",
        accessorFn: (row) =>
          row.updatedAt ? new Date(row.updatedAt).toLocaleString() : "",
      },
      {
        header: "Logs",
        accessor: "logs",
        Cell: ({ row }) => (
          <Button
            variant="primary"
            onClick={() => handleRowClick(row.original)}
          >
            View Details
          </Button>
        ),
      },
    ],
    []
  );

  const handleRowClick = (row: any) => {
    setSelectedRowData(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };

  const handleShowAwbModal = () => setShowAwbModal(true);
  const handleCloseAwbModal = () => setShowAwbModal(false);

  const handleAwbNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAwbNumber(e.target.value);
    setAwbError(e.target.value ? "" : "AWB Number is required");
  };

  const handleSubmit = (awbNumber: string) => {
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      console.log("AWB Number submitted:", awbNumber);
      setLoading(false);
      setShowAwbModal(false);
    }, 2000);
  };

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: {
      showColumnFilters: true,
    },
    getRowId: (row) => row.id,
  });

  const parseAwbBodyRaw = (rawString: string) => {
    try {
      return JSON.parse(rawString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  return (
    <>
      <ClientNavbar />
      <MaterialReactTable table={table} />

      <Modal show={isModalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowData && (
            <>
              <h6>AWB Number: {selectedRowData.awbNumber}</h6>

              <pre>
                Awb Table Last Update:{" "}
                <code>{JSON.stringify(selectedRowData.Response, null, 2)}</code>
              </pre>
              <pre>
                Last Webhook sended by previous Datasource :{" "}
                <code>
                  {JSON.stringify(selectedRowData.lastWebhook, null, 2)}
                </code>
              </pre>
              <pre>
                New Source Added Log:{" "}
                <code>
                  {JSON.stringify(selectedRowData.dataSourceResponses, null, 2)}
                </code>
              </pre>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showAwbModal} onHide={handleCloseAwbModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enter AWB Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              handleSubmit(awbNumber);
            }}
          >
            <Form.Group controlId="awbNumberInput">
              <Form.Label>AWB Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter AWB Number (e.g. 123-12345678)"
                value={awbNumber}
                onChange={handleAwbNumberChange}
                isInvalid={!!awbError}
              />
              <Form.Control.Feedback type="invalid">
                {awbError}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="text-center mt-3">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Button variant="primary" type="submit" disabled={!!awbError}>
                  Send
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataSourceChangeTable;
