import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "material-react-table";
import { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { TransactionLog } from "../../../models/log";
import { getLogs } from "../../../services/log";
import Base from "../../common/Base";
// @ts-ignore
import ReactJsonViewCompare from "react-json-view-compare";
import moment from "moment";

const actionMap: { [key: string]: string } = {
  create: "Created",
  update: "Updated",
  delete: "Deleted",
};

type LogDetailModalState = {
  show: boolean;
  log: TransactionLog | null;
};

function getHeader(action: string | undefined, table: string) {
  if (action === "create") {
    return "Created " + table;
  } else if (action === "update") {
    return "Updated " + table;
  } else if (action === "delete") {
    return "Deleted " + table;
  }
}

function Log() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [totalAWBCount, setTotalAWBCount] = useState(0);
  const [logDetailModal, setLogDetailModal] = useState<LogDetailModalState>({
    show: false,
    log: null,
  });
  const navigate = useNavigate();

  const { data } = useQuery("logs", getLogs);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo<MRT_ColumnDef<TransactionLog>[]>(
    () => [
      {
        header: "Date",
        accessorFn: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
      },
      {
        header: "Changed by",
        accessorFn: (row) =>
          row.createdOrUpdatedBy.firstName +
          " " +
          row.createdOrUpdatedBy.lastName,
      },
      {
        header: "Action",
        accessorFn: (row) => actionMap[row.action] + " " + row.tableName,
      },
      {
        header: "Modified User",
        accessorFn: (row) =>
          row.createdOrUpdatedUser
            ? row.createdOrUpdatedUser?.firstName +
              " " +
              row.createdOrUpdatedUser?.lastName
            : "NA",
      },
      {
        header: "Change Details",
        enableColumnFilter: false,
        accessorFn: (row) => (
          <Button
            variant="primary"
            onClick={() => {
              setLogDetailModal({
                show: true,
                log: row,
              });
            }}
          >
            View
          </Button>
        ),
      },
    ],
    [totalAWBCount]
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.data || [],
    enableRowSelection: false,
    getRowId: (row) => row.id + "",
    initialState: {
      showColumnFilters: true,
      density: "compact",
      showGlobalFilter: false,
    },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: data?.data.length || 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });
  return (
    <Base>
      <div className="mt-3 mx-2">
        <MaterialReactTable table={table} />
      </div>
      {logDetailModal.show && (
        <Modal
          show={logDetailModal.show}
          onHide={() => {
            setLogDetailModal({ show: false, log: null });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {getHeader(
                logDetailModal.log?.action!,
                logDetailModal.log?.tableName!
              )}{" "}
              {logDetailModal.log?.rowId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {logDetailModal.log?.action === "create" && (
              <div>
                <p>
                  <b>Created By:</b>{" "}
                  {logDetailModal.log.createdOrUpdatedBy.firstName +
                    " " +
                    logDetailModal.log.createdOrUpdatedBy.lastName +
                    `(${logDetailModal.log.createdOrUpdatedBy.email})`}
                </p>
                <p>
                  <b>Created At:</b>{" "}
                  {new Date(logDetailModal.log.createdAt).toLocaleDateString()}
                </p>
                {logDetailModal.log.createdOrUpdatedUser && (
                  <p>
                    <b>Created User:</b>{" "}
                    {logDetailModal.log.createdOrUpdatedUser?.firstName +
                      " " +
                      logDetailModal.log.createdOrUpdatedUser?.lastName}
                  </p>
                )}
              </div>
            )}
            <ReactJsonViewCompare
              oldData={logDetailModal.log?.prevData}
              newData={logDetailModal.log?.newData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary">Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Base>
  );
}

export default Log;
