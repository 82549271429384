import { useParams } from "react-router-dom";
import Base from "../../common/Base";
import BodyContainer from "../../foundation/BodyContainer";
import { useQuery } from "react-query";
import { Col, Container, Row } from "react-bootstrap";

import { getCustomerById } from "../../../services/customer";
import RowAlightCenter from "../../common/RowBetween";
import { theme } from "../../../utils/common";
import moment from "moment";
import StatsCard from "../../common/StatsCard";
import TrackingHistoryTable from "./TrackingHistoryTable";
import InvoiceTable from "./InvoiceTable";
import ReceiptTable from "./ReceiptTable";
import { useAuthContext } from "../../../hooks/useAuth";
import useRole from "../../../hooks/useRole";

function UserBilling() {
  const { id: userIdParam } = useParams();
  const {
    user: { id },
  } = useAuthContext();
  const { isDelhiveryAccountsUser } = useRole();
  let userId = userIdParam;
  if (!userId) userId = id;

  const { data, isLoading, isError, error } = useQuery(
    ["customer", userId],
    () => getCustomerById(+userId!)
  );
  if (!userId || isNaN(+userId))
    return (
      <Base>
        <BodyContainer>
          <h2>Invalid User Id</h2>
        </BodyContainer>
      </Base>
    );

  const user = data?.data;
  return (
    <Base loading={isLoading}>
      <Container fluid>
        <Row className="mx-2 my-4 px-2 py-2 rounded-3 shadow">
          <Col>
            <div className="mx-2 my-2" style={{ color: theme.primary }}>
              <h2 style={{ marginBottom: "20px" }}>
                {user?.firstName + "" + user?.lastName}
              </h2>
              <h5 style={{ color: theme.gray }}>{user?.email}</h5>
            </div>
          </Col>
          <Col>
            <RowAlightCenter justifyContent="end">
              <h4 style={{ color: theme.success }}>
                {user?.orgType ?? "Organization Name"}
              </h4>
            </RowAlightCenter>
          </Col>
        </Row>
        {!isDelhiveryAccountsUser && (
          <Row className="mx-2 my-4" style={{ gap: "20px" }}>
            <StatsCard
              topText={moment(user?.createdAt).format("MMMM yy")}
              bottomText="User Since"
            />
            <StatsCard
              topText={user?.monthlyClaimUsed ?? 0}
              bottomText="AWB Count MTD"
            />
            <StatsCard
              topText={user?.monthlyClaimLimit ?? 0}
              bottomText="Monthly AWB Limit"
            />
            {/* <StatsCard topText={985} bottomText="Total AWB Tracked" />
          <StatsCard topText={"Emirates"} bottomText="Most Tracked Airline" /> */}
          </Row>
        )}
        <Row className="m-0" style={{ gap: "20px" }}>
          {!isDelhiveryAccountsUser && (
            <Col className="mx-2 mb-4">
              <TrackingHistoryTable userId={+userId!} />
            </Col>
          )}
          <Col>
            <ReceiptTable userId={+userId!} />
          </Col>
        </Row>
        <Row className="mx-2 mb-4">
          <InvoiceTable userId={+userId!} />
        </Row>
      </Container>
    </Base>
  );
}

export default UserBilling;
