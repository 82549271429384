import { FC, useEffect, useState } from "react";
import { lupaService } from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import { AxiosResponse } from "axios";
import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import { LocalDateTime } from "@js-joda/core";
import { StringLiteral } from "typescript";

export interface ScrapDeleteAddLog {
  awbNumber: string;
  isSuccess: boolean;
  message: string;
  user: User;
  addToTrackingApiResponse: AddToTrackingAPIResponse | null;
  deleteApiResponse: DeleteAPIResponse;
  loginApiResponse: LoginAPIResponse;
  statusCode: number;
  createdAt: string;
  updatedAt: string;
  formattedTime: string;
}

export interface AddToTrackingAPIResponse {
  data: AddToTrackingAPIResponseData;
  errors: string[];
  message: string;
  isSuccess: boolean;
  statusCode: number;
}

export interface AddToTrackingAPIResponseData {
  doNo: string;
  uldNo: string;
  hawbNo: string;
  origin: string;
  pieces: string;
  weight: string;
  flights: string;
  issuedTo: string;
  awbNumber: string;
  isDeleted: boolean;
  isModified: boolean;
  modifiedBy: string;
  retryCount: number;
  destination: string;
  downloadLink: string;
  flightDetails: null;
  trackingStatus: string;
  userTrackingId: string;
  createdDateTime: Date;
  processingStatus: string;
  nextCheckSchedule: Date;
  lastModifiedDateTime: Date;
  lastActivityDescription: string;
  lastPushNotificationTime: Date;
}

export interface DeleteAPIResponse {
  data: boolean;
  errors: any[];
  message: string;
  isSuccess: boolean;
  statusCode: number;
}

export interface LoginAPIResponse {
  data: LoginAPIResponseData;
  errors: any[];
  message: string;
  isSuccess: boolean;
  statusCode: number;
}

export interface LoginAPIResponseData {
  id: string;
  roles: string[];
  jwToken: string;
  fullName: string;
  isVerified: boolean;
  phoneNumber: null;
  expieryDateTime: Date;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
}

const ScrapDeleteAddLog: FC = () => {
  const [uploadHistoryData, setUploadHistoryData] = useState<
    ScrapDeleteAddLog[]
  >([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    fetchUploadHistory(); // Fetch upload history data when the component mounts
  }, []);

  const fetchUploadHistory = async () => {
    try {
      const response: AxiosResponse<ScrapDeleteAddLog[]> =
        await lupaService.getScrapDeleteAdd();
      if (response.data && Array.isArray(response.data)) {
        setUploadHistoryData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ClientNavbar />

      <Container className="overflow-auto mt-4">
        <Card>
          <Table striped hover responsive>
            <thead className="text-start table-header">
              <tr>
                <th scope="col" className="ps-1">
                  S.No
                </th>
                <th scope="col" className="ps-1">
                  User
                </th>
                <th scope="col" className="ps-1">
                  AWB Number
                </th>
                <th scope="col" className="ps-1">
                  Status
                </th>
                <th scope="col" className="ps-1">
                  Response Message
                </th>
                <th scope="col" className="ps-1">
                  Entry Time
                </th>
              </tr>
            </thead>
            <tbody>
              {uploadHistoryData.map((item, index) => (
                <tr key={index}>
                  {" "}
                  {/* Add a unique key for each row */}
                  <td>{index + 1}</td>
                  <td>{item.user.email}</td>
                  <td>{item.awbNumber}</td>
                  <td>{item.isSuccess ? "Success" : "Fail"}</td>
                  <td>
                    {item.message ===
                    "AWB deleted successfully and added to trackings."
                      ? "Done"
                      : item.message}
                  </td>
                  <td>{item.formattedTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default ScrapDeleteAddLog;
