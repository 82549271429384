import { toast } from "react-toastify";

export const toastTypes = {
  success: (message: string) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      
    });
  },
  info: (message: string, loading: boolean = false) => {
    toast.info(`${message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      isLoading: loading,
    });
  },
    error: (message: string) => {
    toast.error(`${message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,

    });
    },
};
